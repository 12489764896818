import { Alert, SxProps, Theme } from '@mui/material';

interface IErrorAlertProps {
  showError: boolean;
  message?: string;
  noMargin?: boolean;
  sx?: SxProps<Theme>;
}

export const ErrorAlert: React.FC<IErrorAlertProps> = ({ showError, message, noMargin, sx }) => {
  if (showError) {
    return (
      <Alert sx={{ width: '100%', marginBottom: noMargin ? 0 : 2, ...sx }} severity="error">
        {message ? message : 'Oops! Something went wrong!'}
      </Alert>
    );
  }

  return null;
};
