import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';

import { GET_LEAGUE_MEMBERS } from '../../api/query/getLeagueMembers';
import { IAPILeagueMember } from '../../api/types/league-member';
import { RoutePath } from '../../constants/routes';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { ErrorAlert } from '../error-alert';
import { LoadingSpinner } from '../loading-spinner';
import { ProfilePicture } from '../profile-picture';
import { RouterLink } from '../router-link';
import { SubmitButton } from '../submit-button';

export const LeagueRankings = ({ leagueId }: { leagueId: string }) => {
  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const {
    data: membersData,
    loading: membersLoading,
    error: membersError,
    fetchMore,
  } = useQuery(GET_LEAGUE_MEMBERS, {
    variables: {
      leagueId,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    fetchPolicy: 'network-only',
  });

  if (membersLoading) {
    return (
      <LoadingSpinner
        sx={{
          color: 'white',
          margin: 'auto',
          justifyContent: 'center',
          width: '100%',
          p: 2,
          pb: 0,
        }}
        label={'Loading rankings...'}
      />
    );
  }

  if (membersError?.message) {
    return <ErrorAlert showError={Boolean(membersError?.message)} />;
  }

  return (
    <>
      {(membersData?.getLeagueMembers?.data || []).map(
        (leagueMember: IAPILeagueMember, idx: number) => (
          <Box
            key={leagueMember.id}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: 2,
              p: 2,
              border: `2px solid white`,
              color: 'white',
              fontWeight: 600,
              cursor: 'pointer',
              bgcolor: 'rgb(49, 0, 74, 0.8)',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{ height: 25, width: 25, mr: 1, flexShrink: 0 }}>
                <ProfilePicture userId={leagueMember.user.id} navigable />
              </Box>
              <Box sx={{ overflow: 'hidden' }}>
                <Typography variant="caption">{`Rank ${idx + 1} - ${leagueMember.leagueRanking}`}</Typography>
                <RouterLink href={`${RoutePath.PROFILE}/${leagueMember.user.id}`}>
                  <Typography sx={cardTitleFontStyles}>
                    {getNameFromUser(leagueMember.user)}
                  </Typography>
                </RouterLink>
                <Typography
                  variant="caption"
                  sx={{ color: 'white' }}
                >{`Member since ${format(parseInt(leagueMember.date), 'dd/MM/yyyy')}`}</Typography>
              </Box>
            </Box>
          </Box>
        ),
      )}

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {Boolean(
          membersData?.getLeagueMembers?.data?.length > 0 &&
            membersData?.getLeagueMembers?.data?.length < membersData?.getLeagueMembers?.count,
        ) && (
          <SubmitButton
            variant="contained"
            sx={{ mr: 2 }}
            label={'Load more'}
            loading={membersLoading}
            onClick={() =>
              fetchMore({
                variables: {
                  offset: membersData?.getLeagueMembers?.data.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return {
                    getLeagueMembers: {
                      data: [
                        ...previousResult.getLeagueMembers.data,
                        ...fetchMoreResult.getLeagueMembers.data,
                      ],
                      count: fetchMoreResult.getLeagueMembers.count,
                    },
                  };
                },
              })
            }
          />
        )}
      </Box>
    </>
  );
};
