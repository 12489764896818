import { useState } from 'react';

import { useQuery } from '@apollo/client';
import ReplayIcon from '@mui/icons-material/ReplayRounded';
import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';

import { GET_GAMES_BY_LEAGUE_LIST } from '../../api/query/getGamesByLeagueList';
import { IAPIGame } from '../../api/types/game';
import { ErrorAlert } from '../../component-library/error-alert';
import { LoadingSpinner } from '../../component-library/loading-spinner';
import { SubmitButton } from '../../component-library/submit-button';
import { colours } from '../../constants/colours';
import { RoutePath } from '../../constants/routes';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useAppCookies } from '../../utils/use-cookies';

export const LeagueGamesList = () => {
  const { cookies } = useAppCookies();
  const navigator = useNavigate();
  const { id } = useParams();

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_GAMES_BY_LEAGUE_LIST, {
    variables: {
      leagueId: id,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 2,
          borderRadius: 2,
          p: 1,
          pl: 2,
          border: `2px solid white`,
          color: 'white',
          fontWeight: 600,
          cursor: 'pointer',
          bgcolor: colours.darkBackground,
        }}
      >
        <Typography variant="h6">Games ({data?.getGamesByLeagueList?.count ?? 0})</Typography>
        <Button
          sx={{ minWidth: 'auto' }}
          size="small"
          variant="contained"
          onClick={() =>
            refetch({
              userId: cookies.signedInUser,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
            })
          }
        >
          <ReplayIcon />
        </Button>
      </Box>
      {loading && (
        <LoadingSpinner
          sx={{
            margin: 'auto',
            justifyContent: 'center',
            width: '100%',
            p: 2,
            pb: 0,
          }}
          label={'Loading games...'}
        />
      )}
      <ErrorAlert showError={Boolean(error?.message)} />
      {Boolean(data?.getGamesByLeagueList?.data?.length === 0) && (
        <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
          This league doesn't have any games recorded.
        </Alert>
      )}
      {data?.getGamesByLeagueList?.data?.map((game: IAPIGame) => {
        const getWinnersName = () => {
          if (game.attackerPoints > game.defenderPoints) {
            return getNameFromUser(game.attacker);
          } else if (game.attackerPoints < game.defenderPoints) {
            return getNameFromUser(game.defender);
          } else {
            return 'Draw';
          }
        };
        return (
          <Grid
            key={game.id}
            container
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: 2,
              p: 2,
              border: `2px solid white`,
              color: 'white',
              fontWeight: 600,
              cursor: 'pointer',
              bgcolor: colours.darkBackground,
            }}
            onClick={() => navigator(`${RoutePath.GAMES}/${game.id}`)}
          >
            <Grid item xs={12} pb={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="caption">{format(parseInt(game.date), 'dd/MM/yyyy')}</Typography>
              <Typography variant="caption" sx={{ color: '#479147' }}>
                {getWinnersName()}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                color: `${game.attackerPoints < game.defenderPoints ? '#df6060' : '#479147'}`,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {getNameFromUser(game.attacker)}
              </Typography>
              <Typography variant="caption">{game.attackerArmy}</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: `${game.attackerPoints < game.defenderPoints ? '#df6060' : '#479147'}`,
                }}
              >
                {game.attackerPoints}
              </Typography>
            </Grid>
            <Grid item xs={12} pt={1} pb={1}>
              <Divider color="white" sx={{ opacity: 0.1 }} />
            </Grid>
            <Grid
              xs={6}
              item
              sx={{
                display: 'flex',
                flexDirection: 'column',
                fontWeight: 'bold',
                color: `${game.attackerPoints > game.defenderPoints ? '#df6060' : '#479147'}`,
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                {getNameFromUser(game.defender) || 'Guest'}
              </Typography>
              <Typography variant="caption">{game.defenderArmy}</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: `${game.attackerPoints > game.defenderPoints ? '#df6060' : '#479147'}`,
                }}
              >
                {game.defenderPoints}
              </Typography>
            </Grid>
          </Grid>
        );
      })}

      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {Boolean(
          data?.getGamesByLeagueList?.data?.length > 0 &&
            data?.getGamesByLeagueList?.data?.length < data?.getGamesByLeagueList?.count,
        ) && (
          <SubmitButton
            variant="contained"
            label={'Load more'}
            loading={loading}
            onClick={() =>
              fetchMore({
                variables: {
                  offset: data?.getGamesByLeagueList?.data.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return {
                    getGamesByLeagueList: {
                      data: [
                        ...previousResult.getGamesByLeagueList.data,
                        ...fetchMoreResult.getGamesByLeagueList.data,
                      ],
                      count: fetchMoreResult.getGamesByLeagueList.count,
                    },
                  };
                },
              })
            }
          />
        )}
      </Box>
    </Box>
  );
};
