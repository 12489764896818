import { gql } from '@apollo/client';

export const SEARCH_USERS = gql`
  query SearchUsers($email: String!, $firstname: String!, $lastname: String!, $username: String!) {
    searchUsers(email: $email, firstname: $firstname, lastname: $lastname, username: $username) {
      id
      email
      username
      firstName
      lastName
    }
  }
`;
