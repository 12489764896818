import { useCookies } from 'react-cookie';

/**
 * The cookie names we want to use throughout the website.
 *
 * signedInEmail - is used to determine whether or not the user has signed in,
 * and can be used to gether the users information on return to the website.
 *  */
export enum CookieNames {
  SIGNED_IN_USER = 'signedInUser',
}

export const useAppCookies = () => {
  const [cookies, setCookie, removeCookie] = useCookies([CookieNames.SIGNED_IN_USER]);

  return { cookies, setCookie, removeCookie };
};
