import { gql } from '@apollo/client';

export const GET_LEAGUE_MEMBERS = gql`
  query GetLeagueMembers($leagueId: String!, $limit: Int!, $offset: Int!) {
    getLeagueMembers(leagueId: $leagueId, limit: $limit, offset: $offset) {
      data {
        id
        date
        leagueRanking
        user {
          id
          firstName
          lastName
          username
        }
      }
      count
    }
  }
`;
