import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Alert, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { GET_FRIEND_LIST } from '../../api/query/getFriendList';
import { GET_USER } from '../../api/query/getUser';
import { IAPIFriend } from '../../api/types/friend';
import { ErrorAlert } from '../../component-library/error-alert';
import { LoadingSpinner } from '../../component-library/loading-spinner';
import { SubmitButton } from '../../component-library/submit-button';
import { armies } from '../../constants/armies';
import { secondaries } from '../../constants/secondaries';
import { IGamePlayerInfo, IGameSecondaryScoringType } from '../../types/player';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useAppCookies } from '../../utils/use-cookies';
import { useUrlParams } from '../../utils/useUrlParams';

export const Stage0: React.FC<{
  attacker: IGamePlayerInfo;
  defender: IGamePlayerInfo;
  setAttacker: React.Dispatch<React.SetStateAction<IGamePlayerInfo>>;
  setDefender: React.Dispatch<React.SetStateAction<IGamePlayerInfo>>;

  attackerArmyDetail: string;
  defenderArmyDetail: string;
  setAttackerArmyDetail: React.Dispatch<React.SetStateAction<string>>;
  setDefenderArmyDetail: React.Dispatch<React.SetStateAction<string>>;

  tacticalPoints: string;
  setTacticalPoints: React.Dispatch<React.SetStateAction<string>>;
  fixedPoints: string;
  setFixedPoints: React.Dispatch<React.SetStateAction<string>>;

  guestUser?: boolean;
}> = ({
  attacker,
  defender,
  setAttacker,
  setDefender,
  guestUser,
  attackerArmyDetail,
  defenderArmyDetail,
  setAttackerArmyDetail,
  setDefenderArmyDetail,
}) => {
  const { cookies } = useAppCookies();

  const playerId = useUrlParams('playerId');
  const playerName = useUrlParams('playerName');

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const { data: userData } = useQuery(GET_USER, {
    variables: {
      id: cookies.signedInUser,
    },
    fetchPolicy: 'network-only',
    skip: guestUser,
  });

  const {
    data: friendsListData,
    loading: friendsListLoading,
    error: friendsListError,
    fetchMore: friendsListFetchMore,
  } = useQuery(GET_FRIEND_LIST, {
    variables: {
      userId: cookies.signedInUser,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    nextFetchPolicy: 'network-only',
    skip: guestUser,
  });

  const getNameOfPlayer = (id?: string) => {
    if ((guestUser && id === 'me') || id === cookies.signedInUser) {
      return 'Me';
    }

    const player = friendsListData?.getFriendList?.data.find((f: IAPIFriend) => f.friend.id === id);

    if (player) {
      return getNameFromUser(player.friend);
    }

    return 'Guest';
  };

  const getFavouriteArmyOfPlayer = (id?: string) => {
    if ((guestUser && id === 'me') || id === cookies.signedInUser) {
      return userData?.getUser?.favouriteArmy ?? '';
    }

    const player = friendsListData?.getFriendList?.data.find((f: IAPIFriend) => f.friend.id === id);

    if (player) {
      return player.friend.favouriteArmy;
    }

    return '';
  };

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="attacker">Attacker</InputLabel>
        <Select
          labelId="attacker"
          sx={{ bgcolor: '#ffcccc' }}
          id="demo-simple-select-autowidth"
          value={attacker.id}
          onChange={(e) =>
            setAttacker({
              id: e.target.value,
              name: getNameOfPlayer(e.target.value),
              army: getFavouriteArmyOfPlayer(e.target.value),
              secondaryScoring: 'Tactical',
            })
          }
          autoWidth
          label="Attacker"
        >
          <MenuItem value={guestUser ? 'me' : cookies.signedInUser}>Me</MenuItem>
          {playerId && <MenuItem value={playerId}>{playerName}</MenuItem>}
          <MenuItem value="guest">Guest</MenuItem>
          {friendsListLoading && (
            <LoadingSpinner
              sx={{
                margin: 'auto',
                justifyContent: 'center',
                width: '100%',
                p: 2,
                pb: 0,
              }}
              label={'Loading friends...'}
            />
          )}
          {friendsListData?.getFriendList?.data
            ?.filter(
              (f: IAPIFriend) => f.friend.id !== cookies.signedInUser && playerId !== f.friend.id,
            )
            .map((friend: IAPIFriend) => (
              <MenuItem value={friend.friend.id}>{getNameFromUser(friend.friend)}</MenuItem>
            ))}
          <ErrorAlert showError={Boolean(friendsListError?.message)} />
          {Boolean(
            friendsListData?.getFriendList?.data?.length > 0 &&
              friendsListData?.getFriendList?.data?.length < friendsListData?.getFriendList?.count,
          ) && (
            <SubmitButton
              variant="contained"
              sx={{ mr: 2 }}
              label={'Load more'}
              loading={friendsListLoading}
              onClick={() =>
                friendsListFetchMore({
                  variables: {
                    offset: friendsListData?.getFriendList?.data.length,
                  },
                  updateQuery: (previousResult, { fetchMoreResult }) => {
                    return {
                      getFriendList: {
                        data: [
                          ...previousResult.getFriendList.data,
                          ...fetchMoreResult.getFriendList.data,
                        ],
                        count: fetchMoreResult.getFriendList.count,
                      },
                    };
                  },
                })
              }
            />
          )}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="attacker">Attacker's Army</InputLabel>
        <Select
          labelId="attacker"
          sx={{ bgcolor: '#ffcccc' }}
          id="attackers-army"
          value={attacker.army}
          onChange={(e) =>
            setAttacker({
              ...attacker,
              army: e.target.value,
            })
          }
          autoWidth
          label="Attacker's Army"
        >
          {armies.map((army) => (
            <MenuItem key={`attacker-${army}`} value={army}>
              {army}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="attacker">Attacker's secondary scoring</InputLabel>
        <Select
          labelId="attacker"
          sx={{ bgcolor: '#ffcccc' }}
          id="attackers-army"
          value={attacker.secondaryScoring}
          onChange={(e) =>
            setAttacker({
              ...attacker,
              secondaryScoring: e.target.value as IGameSecondaryScoringType,
            })
          }
          autoWidth
          label="Attacker's secondary scoring"
        >
          {secondaries.map((secondary) => (
            <MenuItem
              key={`attacker-${secondary}`}
              sx={{ textTransform: 'capitalize' }}
              value={secondary}
            >
              {secondary}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="defender">Defender</InputLabel>
        <Select
          labelId="defender"
          sx={{ bgcolor: '#d9ead3' }}
          id="demo-simple-select-autowidth"
          value={defender.id}
          onChange={(e) =>
            setDefender({
              id: e.target.value,
              name: getNameOfPlayer(e.target.value),
              army: getFavouriteArmyOfPlayer(e.target.value),
              secondaryScoring: 'Tactical',
            })
          }
          autoWidth
          label="Defender"
        >
          <MenuItem value={cookies.signedInUser}>Me</MenuItem>
          {playerId && <MenuItem value={playerId}>{playerName}</MenuItem>}
          <MenuItem value="guest">Guest</MenuItem>
          {friendsListLoading && (
            <LoadingSpinner
              sx={{
                margin: 'auto',
                justifyContent: 'center',
                height: `calc(${window.innerHeight}px - 170px - 56px)`,
                width: '100%',
                p: 2,
                pb: 0,
              }}
              label={'Loading friends...'}
            />
          )}
          {friendsListData?.getFriendList?.data
            ?.filter(
              (f: IAPIFriend) => f.friend.id !== cookies.signedInUser && playerId !== f.friend.id,
            )
            .map((friend: IAPIFriend) => (
              <MenuItem key={friend.friend.id} value={friend.friend.id}>
                {getNameFromUser(friend.friend)}
              </MenuItem>
            ))}
          <ErrorAlert showError={Boolean(friendsListError?.message)} />
          {Boolean(
            friendsListData?.getFriendList?.data?.length > 0 &&
              friendsListData?.getFriendList?.data?.length < friendsListData?.getFriendList?.count,
          ) && (
            <SubmitButton
              variant="outlined"
              sx={{ mr: 2 }}
              label={'Load more'}
              loading={friendsListLoading}
              onClick={() =>
                friendsListFetchMore({
                  variables: {
                    offset: friendsListData?.getFriendList?.data.length,
                  },
                  updateQuery: (previousResult, { fetchMoreResult }) => {
                    return {
                      getFriendList: {
                        data: [
                          ...previousResult.getFriendList.data,
                          ...fetchMoreResult.getFriendList.data,
                        ],
                        count: fetchMoreResult.getFriendList.count,
                      },
                    };
                  },
                })
              }
            />
          )}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="defender">Defender's Army</InputLabel>
        <Select
          labelId="defender"
          sx={{ bgcolor: '#d9ead3' }}
          id="defenders-army"
          value={defender.army}
          onChange={(e) => setDefender({ ...defender, army: e.target.value })}
          autoWidth
          label="Defender's Army"
        >
          {armies.map((army) => (
            <MenuItem key={`defender-${army}`} value={army}>
              {army}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="defender">Defender's secondary scoring</InputLabel>
        <Select
          labelId="defender"
          sx={{ bgcolor: '#d9ead3' }}
          id="defenders-secondaryScoring"
          value={defender.secondaryScoring}
          onChange={(e) =>
            setDefender({
              ...defender,
              secondaryScoring: e.target.value as IGameSecondaryScoringType,
            })
          }
          autoWidth
          label="Defender's secondary scoring"
        >
          {secondaries.map((secondary) => (
            <MenuItem
              key={`defenders-${secondary}`}
              sx={{ textTransform: 'capitalize' }}
              value={secondary}
            >
              {secondary}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        multiline
        value={attackerArmyDetail}
        onChange={(e) => setAttackerArmyDetail(e.target.value)}
        sx={{ m: 1, bgcolor: 'rgb(255,255,255, 0.5)' }}
        label={'Attackers army details'}
        minRows={2}
        maxRows={4}
      />
      <TextField
        multiline
        value={defenderArmyDetail}
        onChange={(e) => setDefenderArmyDetail(e.target.value)}
        sx={{ m: 1, bgcolor: 'rgb(255,255,255, 0.5)' }}
        label={'Defenders army details'}
        minRows={2}
        maxRows={4}
      />
      <Alert severity="info" sx={{ fontSize: '0.7em', m: 1 }}>
        Tip: If you use the WH app you can export your army in the Battle Forge and paste it here.
      </Alert>
    </>
  );
};
