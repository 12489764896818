import { Box, CircularProgress, SxProps, Theme, Typography } from '@mui/material';

import { colours } from '../../constants/colours';

interface ILoadingSpinnerProps {
  label?: string;
  size?: number;
  sx?: SxProps<Theme>;
}

export const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({ label, size = 40, sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        ...sx,
      }}
    >
      <CircularProgress
        sx={{ color: (sx as any)?.color ?? undefined }}
        size={size}
        thickness={5}
        data-testid="loading-splinner"
      />
      {Boolean(label) && (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            color: (sx as any)?.color ?? colours.defaultTextColour,
            mt: 2,
          }}
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};
