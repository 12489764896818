import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Alert, Grid, Tab, Tabs } from '@mui/material';

import { GET_LEAGUE_LIST } from '../../api/query/getLeagueList';
import { IAPILeagueMember } from '../../api/types/league-member';
import { useAppCookies } from '../../utils/use-cookies';
import { GlobalRankings } from './global';
import { LeagueRankings } from './leagueRankings';

export const RankingsTabs = () => {
  const { cookies } = useAppCookies();
  const [selectedLeague, setSelectedLeague] = useState('global');

  const { data: leaguesData } = useQuery(GET_LEAGUE_LIST, {
    variables: {
      userId: cookies.signedInUser,
      limit: 10,
      offset: 0,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        color: 'white',
        fontWeight: 600,
        cursor: 'pointer',
        maxWidth: 600,
        mt: 2,
        pl: 2,
        pr: 2,
      }}
    >
      <Grid item xs={12}>
        <Alert severity="info" sx={{ mb: 1 }}>
          Every game affects your global ranking, but each individual league is only affected by
          games played in that league.
        </Alert>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="secondary"
          textColor="inherit"
          value={selectedLeague}
          sx={{
            display: 'flex',
            borderRadius: 2,
            p: 1,
            border: `2px solid white`,
            color: 'white',
            fontWeight: 600,
            cursor: 'pointer',
            bgcolor: 'rgb(49, 0, 74, 0.8)',
            mb: 1,
          }}
        >
          <Tab
            label="Global"
            value={'global'}
            sx={{
              border: !selectedLeague ? `2px solid white` : undefined,
              borderRadius: 2,
            }}
            onClick={() => setSelectedLeague('global')}
          />
          {leaguesData?.getLeagueList?.data?.map((league: IAPILeagueMember) => (
            <Tab
              key={league.league.id}
              sx={{
                border: selectedLeague === league.league.id ? `2px solid white` : undefined,
                borderRadius: 2,
              }}
              label={league.league.name}
              value={league.league.id}
              onClick={() => setSelectedLeague(league.league.id)}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 4 }}>
        {selectedLeague === 'global' && <GlobalRankings />}
        {selectedLeague !== 'global' && <LeagueRankings leagueId={selectedLeague} />}
      </Grid>
    </Grid>
  );
};
