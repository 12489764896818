import React, { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { RESET_PASSWORD } from '../../api/mutation/resetPassword';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';
import { ResetPasswordFormSchema } from '../../validation/schemas/reset-password-form';
import { ResetPasswordFormInputs } from '../../validation/schemas/reset-password-form/types';
import { ErrorAlert } from '../error-alert';
import { ModalHeader } from '../modal-header';
import { SubmitButton } from '../submit-button';

interface IAddFriendModal {
  isOpen: boolean;
  onClose: () => void;
}

export const ResetPasswordModal: React.FC<IAddFriendModal> = ({ isOpen, onClose }) => {
  const [resetPassword, { loading: sendLoading, error: sendError, data, reset }] =
    useMutation(RESET_PASSWORD);

  const {
    watch,
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<ResetPasswordFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(ResetPasswordFormSchema),
  });
  const email = watch('email');

  useEffect(() => {
    reset();
  }, [email, reset]);

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const onSubmit: SubmitHandler<ResetPasswordFormInputs> = (data) => {
    if (Boolean(!data.email)) {
      return;
    }

    resetPassword({
      variables: {
        email: data.email ?? '',
      },
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <ModalHeader onClose={handleClose} title={'Reset password'} />
      <DialogContent sx={{ padding: 2, maxWidth: '100%', width: COMPONENT_WIDTHS.BODY }} dividers>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Typography mb={2}>
            Enter the email you registered with and we'll send you a new password.
          </Typography>
          <TextField
            fullWidth
            sx={{ mb: 1 }}
            error={Boolean(errors.email?.message)}
            helperText={errors.email?.message}
            id="email"
            label="Email"
            variant="outlined"
            {...register('email')}
          />

          <SubmitButton
            sx={{ mb: 1 }}
            loading={sendLoading}
            disabled={data?.resetPassword}
            label={data?.resetPassword ? 'Email sent, check your inbox!' : 'Send email'}
          />
        </form>
        <ErrorAlert showError={Boolean(sendError?.message)} />
      </DialogContent>
    </Dialog>
  );
};
