import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayIcon from '@mui/icons-material/ReplayRounded';
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { GET_FRIENDS_GAMES_LIST } from '../../api/query/getFriendsGamesList';
import { GET_GAMES_LIST } from '../../api/query/getGamesList';
import { IAPIGame } from '../../api/types/game';
import { ErrorAlert } from '../../component-library/error-alert';
import { LoadingSpinner } from '../../component-library/loading-spinner';
import { SubmitButton } from '../../component-library/submit-button';
import { colours } from '../../constants/colours';
import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { RoutePath } from '../../constants/routes';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useAppCookies } from '../../utils/use-cookies';
import { useUrlParams } from '../../utils/useUrlParams';

/**
 * This page will be the first thing the user sees when they load up the website.
 */
export const GamesPage: React.FC = () => {
  const navigation = useNavigate();
  const { cookies } = useAppCookies();

  const handshake = useUrlParams('handshake');

  const [isGamesModalOpen, setIsGamesModalOpen] = useState<boolean>(false);
  const [view, setView] = useState<'games' | 'handshake' | 'friends'>(
    handshake === 'true' ? 'handshake' : 'games',
  );

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_GAMES_LIST, {
    variables: {
      userId: cookies.signedInUser,
      handshakes: view === 'handshake',
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    skip: view === 'friends',
    fetchPolicy: 'network-only',
  });

  const {
    data: friendsData,
    loading: friendsLoading,
    error: friendsError,
    fetchMore: friendsFetchMore,
    refetch: friendsRefetch,
  } = useQuery(GET_FRIENDS_GAMES_LIST, {
    variables: {
      userId: cookies.signedInUser,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    skip: view !== 'friends',
    fetchPolicy: 'network-only',
  });

  return (
    <Stack
      sx={{
        p: 2,
        pb: 6,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2} sx={{ maxWidth: 700, pt: 2, pb: 3 }}>
        <Grid item xs={12}>
          <Tabs
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            sx={{
              display: 'flex',
              borderRadius: 2,
              p: 1,
              border: `2px solid white`,
              color: 'white',
              fontWeight: 600,
              cursor: 'pointer',
              bgcolor: 'rgb(49, 0, 74, 0.8)',
              m: 2,
            }}
          >
            <Tab
              label={'Confirmed'}
              value={''}
              sx={{
                border: view === 'games' ? `2px solid white` : undefined,
                borderRadius: 2,
              }}
              onClick={() => setView('games')}
            />
            <Tab
              label={'Handshake'}
              value={''}
              sx={{
                border: view === 'handshake' ? `2px solid white` : undefined,
                borderRadius: 2,
              }}
              onClick={() => setView('handshake')}
            />
            <Tab
              label={'Friends'}
              value={''}
              sx={{
                border: view === 'friends' ? `2px solid white` : undefined,
                borderRadius: 2,
              }}
              onClick={() => {
                setView('friends');
              }}
            />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              p: 2,
              pt: 0,
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                alignItems: 'center',
              }}
            >
              <Button
                sx={{ minWidth: 'auto' }}
                size="small"
                variant="contained"
                onClick={() => navigation(RoutePath.NEW_GAME)}
              >
                New game
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 2,
                }}
              >
                <Button
                  sx={{ minWidth: 'auto' }}
                  size="small"
                  variant="contained"
                  onClick={() =>
                    view === 'friends'
                      ? friendsRefetch({
                          userId: cookies.signedInUser,
                          limit: paginationValues.limit,
                          offset: paginationValues.offset,
                        })
                      : refetch({
                          userId: cookies.signedInUser,
                          limit: paginationValues.limit,
                          offset: paginationValues.offset,
                        })
                  }
                >
                  <ReplayIcon />
                </Button>
                <Button
                  sx={{ minWidth: 'auto' }}
                  size="small"
                  variant="contained"
                  onClick={() => setIsGamesModalOpen(true)}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Box>
            {(loading || friendsLoading) && (
              <LoadingSpinner
                sx={{
                  color: 'white',
                  margin: 'auto',
                  justifyContent: 'center',
                  width: '100%',
                  p: 2,
                  pb: 0,
                }}
                label={'Loading games...'}
              />
            )}
            <ErrorAlert showError={Boolean(error?.message || friendsError?.message)} />
            {Boolean(
              (view === 'friends'
                ? friendsData?.getFriendsGamesList?.data
                : data?.getGamesList?.data
              )?.length === 0,
            ) && (
              <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
                Your friends don't have any games recorded.
              </Alert>
            )}
            {(view === 'friends'
              ? friendsData?.getFriendsGamesList?.data
              : data?.getGamesList?.data
            )?.map((game: IAPIGame) => (
              <Grid
                key={game.id}
                container
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: 2,
                  p: 2,
                  border: `2px solid white`,
                  color: 'white',
                  fontWeight: 600,
                  cursor: 'pointer',
                  bgcolor: colours.darkBackground,
                }}
                onClick={() => navigation(`${RoutePath.GAMES}/${game.id}`)}
              >
                {(!game.attackerHandshake || !game.defenderHandshake) && (
                  <>
                    <Grid
                      xs={4}
                      item
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Chip
                        color={game.attackerHandshake ? 'success' : 'error'}
                        size="small"
                        icon={game.attackerHandshake ? <CheckCircleIcon /> : <CancelIcon />}
                        label="Handshake"
                      />
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Chip
                        color={game.defenderHandshake ? 'success' : 'error'}
                        size="small"
                        icon={game.defenderHandshake ? <CheckCircleIcon /> : <CancelIcon />}
                        label="Handshake"
                      />
                    </Grid>
                  </>
                )}
                <Grid
                  xs={4}
                  container
                  sx={{
                    wordBreak: 'break-word',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-around',
                    color: `${game.attackerPoints === game.defenderPoints ? '#e5c777' : game.attackerPoints < game.defenderPoints ? '#df6060' : '#479147'}`,
                  }}
                >
                  <Grid
                    item
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {getNameFromUser(game.attacker)}
                    </Typography>
                    <Typography variant="caption">{game.attackerArmy}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  xs={4}
                  container
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Grid
                      container
                      gap={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="caption">
                          {format(parseInt(game.date), 'dd/MM/yyyy')}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            color: `${game.attackerPoints === game.defenderPoints ? '#e5c777' : game.attackerPoints < game.defenderPoints ? '#df6060' : '#479147'}`,
                          }}
                        >
                          {game.attackerPoints}
                        </Typography>
                        <Typography>{' - '}</Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            color: `${game.attackerPoints === game.defenderPoints ? '#e5c777' : game.attackerPoints > game.defenderPoints ? '#df6060' : '#479147'}`,
                          }}
                        >
                          {game.defenderPoints}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={4}
                  container
                  sx={{
                    wordBreak: 'break-word',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      color: `${game.attackerPoints === game.defenderPoints ? '#e5c777' : game.attackerPoints > game.defenderPoints ? '#df6060' : '#479147'}`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {getNameFromUser(game.defender) || 'Guest'}
                    </Typography>
                    <Typography variant="caption">{game.defenderArmy}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {view === 'friends' ? (
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                {Boolean(
                  friendsData?.getFriendsGamesList?.data?.length > 0 &&
                    friendsData?.getFriendsGamesList?.data?.length <
                      friendsData?.getFriendsGamesList?.count,
                ) && (
                  <SubmitButton
                    variant="contained"
                    sx={{ mr: 2 }}
                    label={'Load more'}
                    loading={loading}
                    onClick={() =>
                      friendsFetchMore({
                        variables: {
                          offset: friendsData?.getFriendsGamesList?.data.length,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                          return {
                            getFriendsGamesList: {
                              data: [
                                ...previousResult.getFriendsGamesList.data,
                                ...fetchMoreResult.getFriendsGamesList.data,
                              ],
                              count: fetchMoreResult.getFriendsGamesList.count,
                            },
                          };
                        },
                      })
                    }
                  />
                )}
              </Box>
            ) : (
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                {Boolean(
                  data?.getGamesList?.data?.length > 0 &&
                    data?.getGamesList?.data?.length < data?.getGamesList?.count,
                ) && (
                  <SubmitButton
                    variant="contained"
                    sx={{ mr: 2 }}
                    label={'Load more'}
                    loading={loading}
                    onClick={() =>
                      fetchMore({
                        variables: {
                          offset: data?.getGamesList?.data.length,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                          return {
                            getGamesList: {
                              data: [
                                ...previousResult.getGamesList.data,
                                ...fetchMoreResult.getGamesList.data,
                              ],
                              count: fetchMoreResult.getGamesList.count,
                            },
                          };
                        },
                      })
                    }
                  />
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <AddGameDialog
        open={isGamesModalOpen}
        onClose={(value) => {
          if (value === 'new-game') {
            navigation(RoutePath.NEW_GAME);
          }
          if (value === 'add-result') {
            // ??
          }
          setIsGamesModalOpen(false);
        }}
        selectedValue=""
      />
    </Stack>
  );
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function AddGameDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <List sx={{ minWidth: 300 }}>
        <ListItem disableGutters>
          <ListItemButton autoFocus onClick={() => handleListItemClick('new-game')}>
            <ListItemText primary="Start new game" />
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters>
          <ListItemButton autoFocus onClick={() => handleListItemClick('add-result')}>
            <ListItemText primary="Add game result" />
          </ListItemButton>
        </ListItem>
      </List>
    </Dialog>
  );
}
