/**
 * All the routes for the website belong in here.
 * There should be no 'hard coded' routes in the project, and we should only use and add values in this enum.
 *  */
export enum RoutePath {
  HOMEPAGE = '/',
  HUB = '/hub',
  PROFILE = '/profile',
  COMPARISON = '/comparison',
  PLAYER_PROFILE = '/profile/:id',
  NEW_GAME = '/new-game',
  NEW_GUEST_GAME = '/new-guest-game',
  GAMES = '/games',
  LEAGUES = '/leagues',
  COMMUNITIES = '/communities',
  SEASONS = '/seasons',
  FRIENDS = '/friends',
  RANKINGS = '/rankings',
  SETTINGS = '/settings',
  DAMAGE_PROBABILITY = '/damage-probability',
  UNKNOWN = '*',
}
