export const armies = [
  `Adepta Sororitas`,
  `Adeptus Custodes`,
  `Adeptus Mechanicus`,
  `Aeldari`,
  `Astra Militarum`,
  `Black Templars`,
  `Blood Angels`,
  `Chaos Daemons`,
  `Chaos Knights`,
  `Chaos Space Marines`,
  `Chaos Titan Legions`,
  `Dark Angels`,
  `Death Guard`,
  `Deathwatch`,
  `Drukhari`,
  `Genestealer Cults`,
  `Grey Knights`,
  `Imperial Agents`,
  `Imperial Knights`,
  `Leagues of Votann`,
  `Necrons`,
  `Orks`,
  `Space Marines`,
  `Space Wolves`,
  `Thousand Sons`,
  `Titan Legions`,
  `Tyranids`,
  `T'au Empire`,
  `World Eaters`,
];
