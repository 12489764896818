import React from 'react';

import { GlobalStyles, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { PageLayout } from './component-library/page-layout';
import { colours } from './constants/colours';
import { COMPONENT_HEIGHTS } from './constants/component-dimentions';
import { RoutePath } from './constants/routes';
import { CommunitiesPage } from './pages/communities';
import { CommunityPage } from './pages/community';
import { ComparisonPage } from './pages/comparison/comparison';
import { DamageProbabilityPage } from './pages/damage-probability';
import { FriendsPage } from './pages/friends';
import { GamePage } from './pages/game';
import { GamesPage } from './pages/games';
import { HomePage } from './pages/homepage';
import { HubPage } from './pages/hub';
import { LeaguePage } from './pages/league';
import { LeaguesPage } from './pages/leagues';
import { NewGamePage } from './pages/new-game';
import { NewGuestGamePage } from './pages/new-game/guest-game';
import { ProfilePage } from './pages/profile';
import { RankingsPage } from './pages/rankings';
import { SeasonsPage } from './pages/seasons';
import { SettingsPage } from './pages/settings';
import { useAppCookies } from './utils/use-cookies';

/**
 * The app should be wrapped by all things needed on every page in the application.
 * Routing is required on every page so should be placed here.
 */
export const App: React.FC = () => {
  const { cookies } = useAppCookies();

  const theme = createTheme({
    palette: {
      primary: {
        main: colours.primaryThemeColour,
        contrastText: '#fff',
      },
      secondary: {
        main: colours.elevatedComponentColour,
        contrastText: colours.primaryThemeColour,
      },
    },
  });

  const signedInOnlyPage = (element: JSX.Element): JSX.Element => {
    if (cookies.signedInUser) {
      return element;
    }

    return <HomePage />;
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: colours.pageBackgroundColour,
            backgroundPositionY: COMPONENT_HEIGHTS.HEADER,
          },
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route path={RoutePath.HOMEPAGE} element={<PageLayout />}>
            <Route index element={<HomePage />} />
            <Route path={RoutePath.HUB} element={signedInOnlyPage(<HubPage />)} />
            <Route path={`${RoutePath.PROFILE}/:id`} element={<ProfilePage />} />
            <Route
              path={`${RoutePath.COMPARISON}/:id`}
              element={signedInOnlyPage(<ComparisonPage />)}
            />
            <Route path={RoutePath.GAMES} element={signedInOnlyPage(<GamesPage />)} />
            <Route path={`${RoutePath.GAMES}/:id`} element={<GamePage />} />
            <Route path={RoutePath.LEAGUES} element={signedInOnlyPage(<LeaguesPage />)}></Route>
            <Route path={RoutePath.COMMUNITIES} element={signedInOnlyPage(<CommunitiesPage />)} />
            <Route
              path={`${RoutePath.COMMUNITIES}/:id`}
              element={signedInOnlyPage(<CommunityPage />)}
            />
            <Route path={RoutePath.SEASONS} element={signedInOnlyPage(<SeasonsPage />)}></Route>
            <Route path={`${RoutePath.LEAGUES}/:id`} element={signedInOnlyPage(<LeaguePage />)} />
            <Route path={RoutePath.FRIENDS} element={signedInOnlyPage(<FriendsPage />)} />
            <Route path={RoutePath.NEW_GAME} element={signedInOnlyPage(<NewGamePage />)} />
            <Route path={RoutePath.RANKINGS} element={signedInOnlyPage(<RankingsPage />)} />
            <Route path={RoutePath.SETTINGS} element={signedInOnlyPage(<SettingsPage />)} />
            <Route path={RoutePath.NEW_GUEST_GAME} element={<NewGuestGamePage />} />
            <Route path={RoutePath.DAMAGE_PROBABILITY} element={<DamageProbabilityPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};
