import { gql } from '@apollo/client';

export const GET_FRIEND_LIST = gql`
  query GetFriendList($userId: String!, $limit: Int!, $offset: Int!) {
    getFriendList(userId: $userId, limit: $limit, offset: $offset) {
      data {
        id
        date
        friend {
          id
          email
          firstName
          username
          lastName
          favouriteArmy
          globalRanking
        }
      }
      count
    }
  }
`;
