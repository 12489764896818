import { gql } from '@apollo/client';

export const GET_LEAGUE_LIST = gql`
  query GetLeagueList($userId: String!, $limit: Int!, $offset: Int!) {
    getLeagueList(userId: $userId, limit: $limit, offset: $offset) {
      data {
        id
        date
        league {
          id
          date
          name
          owner {
            id
            firstName
            lastName
            username
          }
          private
        }
      }
      count
    }
  }
`;
