import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/ReplayRounded';
import { Alert, Box, Button, Divider, IconButton, List, Typography } from '@mui/material';

import { REMOVE_FRIEND } from '../../api/mutation/removeFriend';
import { GET_FRIEND_LIST } from '../../api/query/getFriendList';
import { IAPIFriend } from '../../api/types/friend';
import { colours } from '../../constants/colours';
import { RoutePath } from '../../constants/routes';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useAppCookies } from '../../utils/use-cookies';
import { DeleteFriendConfirmationModal } from '../delete-friend-confirmation-modal';
import { ErrorAlert } from '../error-alert';
import { LoadingSpinner } from '../loading-spinner';
import { ProfilePicture } from '../profile-picture';
import { RouterLink } from '../router-link';
import { SubmitButton } from '../submit-button';

export const FriendsList: React.FC = () => {
  const { cookies } = useAppCookies();
  const [deleteFriend, setDeleteFriend] = useState<IAPIFriend | undefined>(undefined);

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_FRIEND_LIST, {
    variables: {
      userId: cookies.signedInUser,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    fetchPolicy: 'network-only',
  });

  const [removeFriend, { loading: removeLoading, error: removeError }] = useMutation(
    REMOVE_FRIEND,
    {
      update: (cache) => {
        cache.updateQuery(
          {
            query: GET_FRIEND_LIST,
            variables: {
              userId: cookies.signedInUser,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
            },
          },
          (data) => {
            return {
              getFriendList: {
                data: [
                  ...(data?.getFriendList?.data || []).filter(
                    (f: any) => f.id !== deleteFriend?.id,
                  ),
                ],
                count: data?.getFriendList?.count - 1,
              },
            };
          },
        );
      },
    },
  );

  const handleRemoveFriend = () => {
    removeFriend({ variables: { id: deleteFriend?.id } }).then(() => {
      setDeleteFriend(undefined);
    });
  };

  return (
    <List
      sx={{ paddingBottom: 0, p: 2, borderRadius: 2, bgcolor: 'rgb(255,255,255, 0.8)' }}
      component="nav"
    >
      <Box
        sx={{
          mb: 2,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ ...cardTitleFontStyles, display: 'flex', alignItems: 'center' }}>
          Friends
        </Typography>
        <Button
          size="small"
          sx={{ mr: 1, minWidth: 0, color: colours.primaryInverseColour }}
          variant="contained"
          color="primary"
          onClick={() =>
            refetch({
              userId: cookies.signedInUser,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
            })
          }
        >
          <ReplayIcon />
        </Button>
      </Box>
      {loading && (
        <LoadingSpinner
          sx={{
            margin: 'auto',
            justifyContent: 'center',
            width: '100%',
            p: 2,
            pb: 0,
          }}
          label={'Loading friends...'}
        />
      )}
      {Boolean(data?.getFriendList?.data?.length === 0) && (
        <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
          There is currently nobody in your friends list.
          <br />
          Try searching for someone you know and adding them!
        </Alert>
      )}
      <DeleteFriendConfirmationModal
        deleteFriend={deleteFriend}
        loading={removeLoading}
        error={removeError?.message}
        handleClickCancel={() => setDeleteFriend(undefined)}
        handleClickConfirm={handleRemoveFriend}
      />
      <ErrorAlert showError={Boolean(error?.message || removeError?.message)} />
      {data?.getFriendList?.data?.map((friend: IAPIFriend) => (
        <React.Fragment key={friend.id}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, pt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ height: 25, width: 25, mr: 2, flexShrink: 0 }}>
                <ProfilePicture userId={friend.friend.id} navigable />
              </Box>
              <Box sx={{ overflow: 'hidden' }}>
                <RouterLink href={`${RoutePath.PROFILE}/${friend.friend.id}`}>
                  <Typography sx={cardTitleFontStyles}>
                    {getNameFromUser(friend.friend, true)}
                  </Typography>
                </RouterLink>
                <Typography sx={cardSubtitleFontStyles()}>{`${friend.friend.username}`}</Typography>
              </Box>
            </Box>
            <Box>
              <IconButton onClick={() => setDeleteFriend(friend)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />
        </React.Fragment>
      ))}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {Boolean(
          data?.getFriendList?.data?.length > 0 &&
            data?.getFriendList?.data?.length < data?.getFriendList?.count,
        ) && (
          <SubmitButton
            variant="outlined"
            sx={{ mr: 2 }}
            label={'Load more'}
            loading={loading}
            onClick={() =>
              fetchMore({
                variables: {
                  offset: data?.getFriendList?.data.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return {
                    getFriendList: {
                      data: [
                        ...previousResult.getFriendList.data,
                        ...fetchMoreResult.getFriendList.data,
                      ],
                      count: fetchMoreResult.getFriendList.count,
                    },
                  };
                },
              })
            }
          />
        )}
      </Box>
    </List>
  );
};
