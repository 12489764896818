import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
  query GetProfile($id: String!) {
    getProfile(id: $id) {
      user {
        id
        username
        firstName
        lastName
        winStreak
        maxWinStreak
        globalRanking
      }
      globalRanking
      games {
        totalGames {
          totalGames
          totalWins
          totalLosses
          totalDraws
          totalGamesAsFixed
          totalGamesAsTactical
        }
        attacker {
          totalGamesAsAttacker
          totalWinsAsAttacker
          totalLossesAsAttacker
          totalDrawsAsAttacker
        }
        defender {
          totalGamesAsDefender
          totalWinsAsDefender
          totalLossesAsDefender
          totalDrawsAsDefender
        }
      }
      vp {
        averageVPOnAll
        averageSecondaryVPOnTactical
        averageSecondaryVPOnFixed
        averageVPOnAllAsAttacker
        averageVPOnAllAsDefender

        averageVPOnWins
        averageVPOnLosses
        averageVPOnWinsAsAttacker
        averageVPOnWinsAsDefender
        averageVPOnLossesAsAttacker
        averageVPOnLossesAsDefender
        averageVPOnDraws
        averageVPOnDrawsAsAttacker
        averageVPOnDrawsAsDefender
      }
    }
  }
`;
