export interface IColours {
  primaryThemeColour: string;
  primaryInverseColour: string;

  pageBackgroundColour: string;
  elevatedComponentColour: string;
  elevatedComponentInverseColour: string;

  highPrimaryBackground: string;
  midPrimaryBackground: string;
  lightPrimaryBackground: string;
  lighterPrimaryBackground: string;

  subtextColour: string;
  defaultTextColour: string;

  drawText: string;
  lossText: string;
  winText: string;

  darkBackground: string;
  lightBackground: string;
}

export const colours: IColours = {
  primaryThemeColour: '#31004a',
  primaryInverseColour: '#f1d5ff',

  pageBackgroundColour: '#0b0014',
  elevatedComponentColour: '#fcfcfc',
  elevatedComponentInverseColour: '#242424',

  highPrimaryBackground: '#f39766e3',
  midPrimaryBackground: '#f397669c',
  lightPrimaryBackground: '#f397666b',
  lighterPrimaryBackground: '#f397663d',

  subtextColour: 'rgba(0,0,0,0.6)',
  defaultTextColour: 'black',

  drawText: '#e5c777',
  lossText: '#df6060',
  winText: '#479147',

  darkBackground: 'rgb(145, 145, 145, 0.2)',
  lightBackground: 'rgba(255, 255, 255, 0.8)',
};
