import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { GET_PROFILE } from '../../api/query/getProfile';
import { ErrorAlert } from '../../component-library/error-alert';
import { LoadingSpinner } from '../../component-library/loading-spinner';
import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { PlayerGames } from './playerGames';

/**
 * This page will be the first thing the user sees when they load up the website.
 */
export const ProfilePage: React.FC = () => {
  const { id } = useParams();

  const [shouldShowGames, setShouldShowGames] = useState<boolean>(false);

  const { data, loading, error } = useQuery(GET_PROFILE, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const getPercentage = (partial: number, total: number) => {
    const result = (partial / total) * 100;
    return isNaN(result) ? '0%' : `${result.toFixed(1).replace(/[.,]0$/, '')}%`;
  };

  const getToFixedValue = (value: number) => {
    return `${value.toFixed(1).replace(/[.,]0$/, '')}`;
  };

  return (
    <Stack
      sx={{
        p: 2,
        pb: 6,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2} pt={2} sx={{ maxWidth: 600, mb: 3 }}>
        {loading && (
          <LoadingSpinner
            sx={{
              color: 'white',
              margin: 'auto',
              justifyContent: 'center',
              width: '100%',
              p: 2,
              pb: 0,
            }}
            label={'Loading profile...'}
          />
        )}
        <ErrorAlert showError={Boolean(error?.message)} />
        {data?.getProfile.user && (
          <>
            <Grid item xs={12} sm={8}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                  p: 1,
                  borderRadius: 1,
                  height: '100%',
                  bgcolor: 'rgb(49, 0, 74, 0.9)',
                  border: '2px solid white',
                  color: 'white',
                }}
              >
                <Box>{getNameFromUser(data.getProfile.user)}</Box>
                <Box>
                  <Typography variant="caption">
                    Global rank #{data.getProfile.globalRanking}
                    {' - '}
                    {data.getProfile.user.globalRanking}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Grid item xs={12} mb={2}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    p: 1,
                    borderRadius: 1,
                    width: '100%',
                    bgcolor: 'rgb(49, 0, 74, 0.8)',
                    border: '2px solid white',
                    color: 'white',
                  }}
                >
                  <Box>
                    <Typography variant="body2">
                      Win streak - {data.getProfile.user.winStreak}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      Longest win streak - {data.getProfile.user.maxWinStreak}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    p: 1,
                    borderRadius: 1,
                    height: '100%',
                    bgcolor: 'rgb(49, 0, 74, 0.8)',
                    border: '2px solid white',
                    color: 'white',
                  }}
                >
                  <Box>
                    <Typography variant="body2">
                      Win rate -{' '}
                      {getPercentage(
                        data.getProfile.games.totalGames.totalWins,
                        data.getProfile.games.totalGames.totalGames,
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      As attacker -{' '}
                      {getPercentage(
                        data.getProfile.games.attacker.totalWinsAsAttacker,
                        data.getProfile.games.attacker.totalGamesAsAttacker,
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      As defender -{' '}
                      {getPercentage(
                        data.getProfile.games.defender.totalWinsAsDefender,
                        data.getProfile.games.defender.totalGamesAsDefender,
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      Draws -{' '}
                      {getPercentage(
                        data.getProfile.games.totalGames.totalDraws,
                        data.getProfile.games.totalGames.totalGames,
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                  p: 1,
                  borderRadius: 1,
                  height: '100%',
                  bgcolor: 'rgb(49, 0, 74, 0.9)',
                  border: '2px solid white',
                  color: 'white',
                }}
              >
                <Typography>Total</Typography>
                <Box width={'100%'}>
                  <Divider color="white" variant="fullWidth" />
                </Box>
                <Typography variant="body2">
                  Games - {data.getProfile.games.totalGames.totalGames}
                </Typography>
                <Typography variant="body2">
                  Wins - {data.getProfile.games.totalGames.totalWins}
                </Typography>
                <Typography variant="body2">
                  Losses - {data.getProfile.games.totalGames.totalLosses}
                </Typography>
                <Typography variant="body2">
                  Draws - {data.getProfile.games.totalGames.totalDraws}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                  p: 1,
                  borderRadius: 1,
                  height: '100%',
                  bgcolor: 'rgb(49, 0, 74, 0.9)',
                  border: '2px solid white',
                  color: 'white',
                }}
              >
                <Typography variant="body1">Attacker</Typography>
                <Box width={'100%'}>
                  <Divider color="white" variant="fullWidth" />
                </Box>
                <Typography variant="body2">
                  Games - {data.getProfile.games.attacker.totalGamesAsAttacker}
                </Typography>
                <Typography variant="body2">
                  Wins - {data.getProfile.games.attacker.totalWinsAsAttacker}
                </Typography>
                <Typography variant="body2">
                  Losses - {data.getProfile.games.attacker.totalLossesAsAttacker}
                </Typography>
                <Typography variant="body2">
                  Draws - {data.getProfile.games.attacker.totalDrawsAsAttacker}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                  p: 1,
                  borderRadius: 1,
                  height: '100%',
                  bgcolor: 'rgb(49, 0, 74, 0.9)',
                  border: '2px solid white',
                  color: 'white',
                }}
              >
                <Typography variant="body1">Defender</Typography>
                <Box width={'100%'}>
                  <Divider color="white" variant="fullWidth" />
                </Box>
                <Typography variant="body2">
                  Games - {data.getProfile.games.defender.totalGamesAsDefender}
                </Typography>
                <Typography variant="body2">
                  Wins - {data.getProfile.games.defender.totalWinsAsDefender}
                </Typography>
                <Typography variant="body2">
                  Losses - {data.getProfile.games.defender.totalLossesAsDefender}
                </Typography>
                <Typography variant="body2">
                  Draws - {data.getProfile.games.defender.totalDrawsAsDefender}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                  p: 1,
                  borderRadius: 1,
                  height: '100%',
                  bgcolor: 'rgb(49, 0, 74, 0.9)',
                  border: '2px solid white',
                  color: 'white',
                }}
              >
                <Typography variant="body2">Avg VP Totals</Typography>
                <Box width={'100%'}>
                  <Divider color="white" variant="fullWidth" />
                </Box>
                <Typography variant="body2">
                  All - {getToFixedValue(data.getProfile.vp.averageVPOnAll)}
                </Typography>
                <Typography variant="body2">
                  Wins - {getToFixedValue(data.getProfile.vp.averageVPOnWins)}
                </Typography>
                <Typography variant="body2">
                  Losses - {getToFixedValue(data.getProfile.vp.averageVPOnLosses)}
                </Typography>
                <Typography variant="body2">
                  Draws - {getToFixedValue(data.getProfile.vp.averageVPOnDraws)}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                  p: 1,
                  borderRadius: 1,
                  height: '100%',
                  bgcolor: 'rgb(49, 0, 74, 0.9)',
                  border: '2px solid white',
                  color: 'white',
                }}
              >
                <Typography variant="body2">Avg Attacker VP</Typography>

                <Box width={'100%'}>
                  <Divider color="white" variant="fullWidth" />
                </Box>
                <Typography variant="body2">
                  All - {getToFixedValue(data.getProfile.vp.averageVPOnAllAsAttacker)}
                </Typography>
                <Typography variant="body2">
                  Wins - {getToFixedValue(data.getProfile.vp.averageVPOnWinsAsAttacker)}
                </Typography>
                <Typography variant="body2">
                  Losses - {getToFixedValue(data.getProfile.vp.averageVPOnLossesAsAttacker)}
                </Typography>
                <Typography variant="body2">
                  Draws - {getToFixedValue(data.getProfile.vp.averageVPOnDrawsAsAttacker)}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                  p: 1,
                  borderRadius: 1,
                  height: '100%',
                  bgcolor: 'rgb(49, 0, 74, 0.9)',
                  border: '2px solid white',
                  color: 'white',
                }}
              >
                <Box>
                  <Typography variant="body2">Avg Defender VP</Typography>
                </Box>
                <Box width={'100%'}>
                  <Divider color="white" variant="fullWidth" />
                </Box>
                <Typography variant="body2">
                  All - {getToFixedValue(data.getProfile.vp.averageVPOnAllAsDefender)}
                </Typography>
                <Typography variant="body2">
                  Wins - {getToFixedValue(data.getProfile.vp.averageVPOnWinsAsDefender)}
                </Typography>
                <Typography variant="body2">
                  Losses - {getToFixedValue(data.getProfile.vp.averageVPOnLossesAsDefender)}
                </Typography>
                <Typography variant="body2">
                  Draws - {getToFixedValue(data.getProfile.vp.averageVPOnDrawsAsDefender)}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                  p: 1,
                  borderRadius: 1,
                  height: '100%',
                  bgcolor: 'rgb(49, 0, 74, 0.9)',
                  border: '2px solid white',
                  color: 'white',
                }}
              >
                <Typography variant="body2">Fixed Secondaries</Typography>
                <Box width={'100%'}>
                  <Divider color="white" variant="fullWidth" />
                </Box>
                <Typography variant="body2">
                  Games played -{' '}
                  {getToFixedValue(data.getProfile.games.totalGames.totalGamesAsFixed)}
                </Typography>
                <Typography variant="body2">
                  Avg points - {getToFixedValue(data.getProfile.vp.averageSecondaryVPOnFixed)}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                  p: 1,
                  borderRadius: 1,
                  height: '100%',
                  bgcolor: 'rgb(49, 0, 74, 0.9)',
                  border: '2px solid white',
                  color: 'white',
                }}
              >
                <Typography variant="body2">Tactical Secondaries</Typography>
                <Box width={'100%'}>
                  <Divider color="white" variant="fullWidth" />
                </Box>
                <Typography variant="body2">
                  Games played -{' '}
                  {getToFixedValue(data.getProfile.games.totalGames.totalGamesAsTactical)}
                </Typography>
                <Typography variant="body2">
                  Avg points - {getToFixedValue(data.getProfile.vp.averageSecondaryVPOnTactical)}
                </Typography>
              </Box>
            </Grid>
          </>
        )}
        {!shouldShowGames ? (
          <Grid item xs={12}>
            <Button fullWidth variant="contained" onClick={() => setShouldShowGames(true)}>
              Show games
            </Button>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <PlayerGames id={id} />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};
