import React, { useState } from 'react';

import { Alert, Box, Button, Grid, Stack } from '@mui/material';

import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { IGamePlayerInfo } from '../../types/player';
import { GameSwtich } from './switch';

/**
 * This page will be the first thing the user sees when they load up the website.
 */

export interface IGameRoundScoring {
  attackersTurn: string;
  defendersTurn: string;
  secondary: string;
}

export const NewGuestGamePage: React.FC = () => {
  const [attackerArmyDetail, setAttackerArmyDetail] = useState('');
  const [defenderArmyDetail, setDefenderArmyDetail] = useState('');

  const [tacticalPoints, setTacticalPoints] = useState('');
  const [fixedPoints, setFixedPoints] = useState('');

  const [round1Notes, setRound1Notes] = useState('');
  const [round2Notes, setRound2Notes] = useState('');
  const [round3Notes, setRound3Notes] = useState('');
  const [round4Notes, setRound4Notes] = useState('');
  const [round5Notes, setRound5Notes] = useState('');

  const [attacker, setAttacker] = useState<IGamePlayerInfo>({
    id: '',
    name: '',
    army: '',
    secondaryScoring: 'Tactical',
  });
  const [defender, setDefender] = useState<IGamePlayerInfo>({
    id: '',
    name: '',
    army: '',
    secondaryScoring: 'Tactical',
  });

  const [attRound1, setAttRound1] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [defRound1, setDefRound1] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [attRound2, setAttRound2] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [defRound2, setDefRound2] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [attRound3, setAttRound3] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [defRound3, setDefRound3] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [attRound4, setAttRound4] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [defRound4, setDefRound4] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [attRound5, setAttRound5] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [defRound5, setDefRound5] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [stage, setStage] = useState(0);

  const noPlayersSelected = !attacker.id || !defender.id || !attacker.army || !defender.army;

  const getAttackerTotalPoints = () => {
    return (
      (!isNaN(parseInt(attRound1.attackersTurn)) ? parseInt(attRound1.attackersTurn) : 0) +
      (!isNaN(parseInt(attRound1.defendersTurn)) ? parseInt(attRound1.defendersTurn) : 0) +
      (!isNaN(parseInt(attRound1.secondary)) ? parseInt(attRound1.secondary) : 0) +
      (!isNaN(parseInt(attRound2.attackersTurn)) ? parseInt(attRound2.attackersTurn) : 0) +
      (!isNaN(parseInt(attRound2.defendersTurn)) ? parseInt(attRound2.defendersTurn) : 0) +
      (!isNaN(parseInt(attRound2.secondary)) ? parseInt(attRound2.secondary) : 0) +
      (!isNaN(parseInt(attRound3.attackersTurn)) ? parseInt(attRound3.attackersTurn) : 0) +
      (!isNaN(parseInt(attRound3.defendersTurn)) ? parseInt(attRound3.defendersTurn) : 0) +
      (!isNaN(parseInt(attRound3.secondary)) ? parseInt(attRound3.secondary) : 0) +
      (!isNaN(parseInt(attRound4.attackersTurn)) ? parseInt(attRound4.attackersTurn) : 0) +
      (!isNaN(parseInt(attRound4.defendersTurn)) ? parseInt(attRound4.defendersTurn) : 0) +
      (!isNaN(parseInt(attRound4.secondary)) ? parseInt(attRound4.secondary) : 0) +
      (!isNaN(parseInt(attRound5.attackersTurn)) ? parseInt(attRound5.attackersTurn) : 0) +
      (!isNaN(parseInt(attRound5.defendersTurn)) ? parseInt(attRound5.defendersTurn) : 0) +
      (!isNaN(parseInt(attRound5.secondary)) ? parseInt(attRound5.secondary) : 0)
    );
  };

  const getDefenderTotalPoints = () => {
    return (
      (!isNaN(parseInt(defRound1.attackersTurn)) ? parseInt(defRound1.attackersTurn) : 0) +
      (!isNaN(parseInt(defRound1.defendersTurn)) ? parseInt(defRound1.defendersTurn) : 0) +
      (!isNaN(parseInt(defRound1.secondary)) ? parseInt(defRound1.secondary) : 0) +
      (!isNaN(parseInt(defRound2.attackersTurn)) ? parseInt(defRound2.attackersTurn) : 0) +
      (!isNaN(parseInt(defRound2.defendersTurn)) ? parseInt(defRound2.defendersTurn) : 0) +
      (!isNaN(parseInt(defRound2.secondary)) ? parseInt(defRound2.secondary) : 0) +
      (!isNaN(parseInt(defRound3.attackersTurn)) ? parseInt(defRound3.attackersTurn) : 0) +
      (!isNaN(parseInt(defRound3.defendersTurn)) ? parseInt(defRound3.defendersTurn) : 0) +
      (!isNaN(parseInt(defRound3.secondary)) ? parseInt(defRound3.secondary) : 0) +
      (!isNaN(parseInt(defRound4.attackersTurn)) ? parseInt(defRound4.attackersTurn) : 0) +
      (!isNaN(parseInt(defRound4.defendersTurn)) ? parseInt(defRound4.defendersTurn) : 0) +
      (!isNaN(parseInt(defRound4.secondary)) ? parseInt(defRound4.secondary) : 0) +
      (!isNaN(parseInt(defRound5.attackersTurn)) ? parseInt(defRound5.attackersTurn) : 0) +
      (!isNaN(parseInt(defRound5.defendersTurn)) ? parseInt(defRound5.defendersTurn) : 0) +
      (!isNaN(parseInt(defRound5.secondary)) ? parseInt(defRound5.secondary) : 0)
    );
  };

  return (
    <Stack
      sx={{
        p: 2,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2} pt={2} pb={3} maxWidth={600}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
              bgcolor: stage < 6 ? 'rgb(255,255,255, 0.8)' : undefined,
            }}
          >
            <GameSwtich
              guestUser
              attackerArmyDetail={attackerArmyDetail}
              defenderArmyDetail={defenderArmyDetail}
              stage={stage}
              tacticalPoints={tacticalPoints}
              setTacticalPoints={setTacticalPoints}
              fixedPoints={fixedPoints}
              setFixedPoints={setFixedPoints}
              round1Notes={round1Notes}
              round2Notes={round2Notes}
              round3Notes={round3Notes}
              round4Notes={round4Notes}
              round5Notes={round5Notes}
              setRound1Notes={setRound1Notes}
              setRound2Notes={setRound2Notes}
              setRound3Notes={setRound3Notes}
              setRound4Notes={setRound4Notes}
              setRound5Notes={setRound5Notes}
              setAttackerArmyDetail={setAttackerArmyDetail}
              setDefenderArmyDetail={setDefenderArmyDetail}
              attackerTotal={getAttackerTotalPoints()}
              defenderTotal={getDefenderTotalPoints()}
              attacker={attacker}
              defender={defender}
              setAttacker={setAttacker}
              setDefender={setDefender}
              attRound1={attRound1}
              defRound1={defRound1}
              setAttRound1={setAttRound1}
              setDefRound1={setDefRound1}
              attRound2={attRound2}
              defRound2={defRound2}
              setAttRound2={setAttRound2}
              setDefRound2={setDefRound2}
              attRound3={attRound3}
              defRound3={defRound3}
              setAttRound3={setAttRound3}
              setDefRound3={setDefRound3}
              attRound4={attRound4}
              defRound4={defRound4}
              setAttRound4={setAttRound4}
              setDefRound4={setDefRound4}
              attRound5={attRound5}
              defRound5={defRound5}
              setAttRound5={setAttRound5}
              setDefRound5={setDefRound5}
            />
          </Box>
        </Grid>
        {stage === 0 && noPlayersSelected && (
          <Grid item xs={12}>
            <Box
              sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                borderRadius: 1,
                bgcolor: 'rgb(255,255,255, 0.8)',
              }}
            >
              <Alert sx={{ width: '100%' }} severity="warning">
                You must select the players and their armies to continue
              </Alert>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box
            sx={{
              p: 1,
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              borderRadius: 1,
              bgcolor: 'rgb(255,255,255, 0.8)',
            }}
          >
            {stage > 0 && (
              <Button fullWidth variant="contained" onClick={() => setStage(stage - 1)}>
                Back
              </Button>
            )}
            {stage < 5 && (
              <Button
                disabled={stage === 0 && noPlayersSelected}
                fullWidth
                variant="contained"
                onClick={() => setStage(stage + 1)}
              >
                Next
              </Button>
            )}
            {stage === 5 && (
              <Button fullWidth variant="contained" onClick={() => setStage(stage + 1)}>
                End Battle
              </Button>
            )}
          </Box>
        </Grid>
        {stage !== 0 && stage < 5 && (
          <Grid item xs={12}>
            <Box
              sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                borderRadius: 1,
                bgcolor: 'rgb(255,255,255, 0.8)',
              }}
            >
              <Button fullWidth variant="contained" onClick={() => setStage(6)}>
                End Battle Early
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};
