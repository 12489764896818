import React from 'react';

import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CompareIcon from '@mui/icons-material/Compare';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SettingsIcon from '@mui/icons-material/Settings';
import ShieldIcon from '@mui/icons-material/Shield';
import StarsIcon from '@mui/icons-material/Stars';
import { Box, Button, Icon, ImageList, ImageListItem, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { RoutePath } from '../../constants/routes';
import { useAppCookies } from '../../utils/use-cookies';

/**
 * This page will be the first thing the user sees when they load up the website.
 */
export const HubPage: React.FC = () => {
  const { cookies } = useAppCookies();
  const navigation = useNavigate();
  return (
    <Stack
      sx={{
        pb: 6,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        alignItems: 'center',
      }}
    >
      <ImageList
        variant="quilted"
        cols={3}
        gap={25}
        rowHeight={100}
        sx={{
          maxWidth: 350,
        }}
      >
        <ImageListItem cols={2} rows={2}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigation(RoutePath.GAMES)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              p: 1,
              borderRadius: 1,
              height: '100%',
              bgcolor: 'rgb(49, 0, 74, 0.8)',
              cursor: 'pointer',
              border: '2px solid white',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Icon sx={{ height: '30px' }}>
                <ShieldIcon />
              </Icon>
              <Typography variant="caption" sx={{ fontSize: 10 }}>
                Games
              </Typography>
            </Box>
          </Button>
        </ImageListItem>
        <ImageListItem cols={1} rows={1}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigation(RoutePath.LEAGUES)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              p: 1,
              borderRadius: 1,
              height: '100%',
              bgcolor: 'rgb(49, 0, 74, 0.8)',
              cursor: 'pointer',
              border: '2px solid white',
              width: 100,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Icon sx={{ height: '34px' }}>
                <GroupsIcon />
              </Icon>
              <Typography variant="caption" sx={{ fontSize: 10 }}>
                Leagues
              </Typography>
            </Box>
          </Button>
        </ImageListItem>

        <ImageListItem cols={1} rows={1}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigation(RoutePath.COMMUNITIES)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              p: 1,
              borderRadius: 1,
              height: '100%',
              bgcolor: 'rgb(49, 0, 74, 0.8)',
              cursor: 'pointer',
              border: '2px solid white',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Icon sx={{ height: '34px' }}>
                <AccessibilityNewIcon />
              </Icon>
              <Typography variant="caption" sx={{ fontSize: 10 }}>
                Communities
              </Typography>
            </Box>
          </Button>
        </ImageListItem>

        <ImageListItem cols={1} rows={1}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigation(`${RoutePath.PROFILE}/${cookies.signedInUser}`)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              p: 1,
              borderRadius: 1,
              height: '100%',
              bgcolor: 'rgb(49, 0, 74, 0.8)',
              cursor: 'pointer',
              border: '2px solid white',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Icon sx={{ height: '34px' }}>
                <PersonOutlineIcon />
              </Icon>
              <Typography variant="caption" sx={{ fontSize: 10 }}>
                My Profile
              </Typography>
            </Box>
          </Button>
        </ImageListItem>

        <ImageListItem cols={1} rows={1}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigation(RoutePath.RANKINGS)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              p: 1,
              borderRadius: 1,
              height: '100%',
              bgcolor: 'rgb(49, 0, 74, 0.8)',
              cursor: 'pointer',
              border: '2px solid white',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Icon sx={{ height: '34px' }}>
                <BarChartIcon />
              </Icon>
              <Typography variant="caption" sx={{ fontSize: 10 }}>
                Rankings
              </Typography>
            </Box>
          </Button>
        </ImageListItem>

        <ImageListItem cols={1} rows={1}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigation(RoutePath.SEASONS)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              p: 1,
              borderRadius: 1,
              height: '100%',
              bgcolor: 'rgb(49, 0, 74, 0.8)',
              cursor: 'pointer',
              border: '2px solid white',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Icon sx={{ height: '34px' }}>
                <StarsIcon />
              </Icon>
              <Typography variant="caption" sx={{ fontSize: 10 }}>
                Seasons
              </Typography>
            </Box>
          </Button>
        </ImageListItem>

        <ImageListItem cols={1} rows={1}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigation(RoutePath.NEW_GAME)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              p: 1,
              borderRadius: 1,
              height: '100%',
              bgcolor: 'rgb(49, 0, 74, 0.8)',
              cursor: 'pointer',
              border: '2px solid white',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Icon sx={{ height: '34px' }}>
                <PlayArrowIcon />
              </Icon>
              <Typography variant="caption" sx={{ fontSize: 10 }}>
                New game
              </Typography>
            </Box>
          </Button>
        </ImageListItem>

        <ImageListItem cols={1} rows={1}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigation(`${RoutePath.COMPARISON}/${cookies.signedInUser}`)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              p: 1,
              borderRadius: 1,
              height: '100%',
              bgcolor: 'rgb(49, 0, 74, 0.8)',
              cursor: 'pointer',
              border: '2px solid white',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Icon sx={{ height: '34px' }}>
                <CompareIcon />
              </Icon>
              <Typography variant="caption" sx={{ fontSize: 10 }}>
                Compare Players
              </Typography>
            </Box>
          </Button>
        </ImageListItem>

        <ImageListItem cols={1} rows={1}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigation(RoutePath.FRIENDS)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              p: 1,
              borderRadius: 1,
              height: '100%',
              bgcolor: 'rgb(49, 0, 74, 0.8)',
              cursor: 'pointer',
              border: '2px solid white',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Icon sx={{ height: '34px' }}>
                <GroupIcon />
              </Icon>
              <Typography variant="caption" sx={{ fontSize: 10 }}>
                Friends
              </Typography>
            </Box>
          </Button>
        </ImageListItem>

        <ImageListItem cols={1} rows={1}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigation(`${RoutePath.NEW_GAME}?importGame=true`)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              p: 1,
              borderRadius: 1,
              height: '100%',
              bgcolor: 'rgb(49, 0, 74, 0.8)',
              cursor: 'pointer',
              border: '2px solid white',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Icon sx={{ height: '34px' }}>
                <CloudUploadIcon />
              </Icon>
              <Typography variant="caption" sx={{ fontSize: 10 }}>
                Import game
              </Typography>
            </Box>
          </Button>
        </ImageListItem>

        <ImageListItem cols={2} rows={1}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigation(RoutePath.SETTINGS)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              p: 1,
              borderRadius: 1,
              height: '100%',
              bgcolor: 'rgb(49, 0, 74, 0.8)',
              cursor: 'pointer',
              border: '2px solid white',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Icon sx={{ height: '34px' }}>
                <SettingsIcon />
              </Icon>
              <Typography variant="caption" sx={{ fontSize: 10 }}>
                Settings
              </Typography>
            </Box>
          </Button>
        </ImageListItem>
      </ImageList>
    </Stack>
  );
};
