import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import PlayIcon from '@mui/icons-material/PlayCircle';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';

import { GET_LEAGUE } from '../../api/query/getLeague';
import { GET_LEAGUE_MEMBERS } from '../../api/query/getLeagueMembers';
import { IAPILeagueMember } from '../../api/types/league-member';
import { ErrorAlert } from '../../component-library/error-alert';
import { LeageOptionsModal } from '../../component-library/league-options-modal';
import { LoadingSpinner } from '../../component-library/loading-spinner';
import { ProfilePicture } from '../../component-library/profile-picture';
import { RouterLink } from '../../component-library/router-link';
import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { RoutePath } from '../../constants/routes';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useAppCookies } from '../../utils/use-cookies';
import { LeagueGamesList } from './league-games-list';

/**
 * This page will be the first thing the user sees when they load up the website.
 */
export const LeaguePage: React.FC = () => {
  const { id } = useParams();
  const { cookies } = useAppCookies();
  const navigate = useNavigate();

  const [isLeagueOptionsOpen, setIsLeagueOptionsOpen] = useState(false);

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const {
    data: leagueData,
    loading: leagueLoading,
    error: leagueError,
  } = useQuery(GET_LEAGUE, {
    variables: {
      userId: cookies.signedInUser,
      leagueId: id,
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: membersData,
    loading: membersLoading,
    error: membersError,
  } = useQuery(GET_LEAGUE_MEMBERS, {
    variables: {
      leagueId: id,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Stack
      sx={{
        p: 2,
        pb: 6,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        alignItems: 'center',
      }}
    >
      <LeageOptionsModal
        isOpen={isLeagueOptionsOpen}
        onClose={() => setIsLeagueOptionsOpen(false)}
      />
      <Grid container spacing={2} sx={{ maxWidth: 700, pb: 3 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              p: 2,
              mt: 2,
              borderRadius: 1,
              fontWeight: 600,
              color: 'white',
              bgcolor: '#31004a',
              border: '2px solid white',
            }}
          >
            {leagueLoading && (
              <LoadingSpinner
                sx={{
                  color: 'white',
                  margin: 'auto',
                  justifyContent: 'center',
                  width: '100%',
                  p: 2,
                  pb: 0,
                }}
                label={'Loading league info...'}
              />
            )}
            <ErrorAlert showError={Boolean(leagueError?.message)} />
            {leagueData?.getLeague && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <Typography variant="caption">League</Typography>
                    <Typography variant="h6">{leagueData.getLeague.league.name}</Typography>
                  </Box>
                  {cookies.signedInUser === leagueData.getLeague.league.owner.id && (
                    <Box>
                      <IconButton
                        sx={{ minWidth: 'auto' }}
                        onClick={() => setIsLeagueOptionsOpen(true)}
                      >
                        <SettingsApplicationsIcon sx={{ fill: 'white' }} color="action" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
                <Box>
                  <Typography variant="caption">
                    est - {format(parseInt(leagueData.getLeague.date), 'dd/MM/yyyy')}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              borderRadius: 1,
            }}
          >
            <Box>
              <LeagueGamesList />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              borderRadius: 1,
            }}
          >
            {membersLoading && (
              <LoadingSpinner
                sx={{
                  margin: 'auto',
                  justifyContent: 'center',
                  width: '100%',
                  p: 2,
                  pb: 0,
                }}
                label={'Loading league info...'}
              />
            )}
            <ErrorAlert showError={Boolean(membersError?.message)} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 2,
                p: 1,
                pl: 2,
                border: `2px solid white`,
                color: 'white',
                fontWeight: 600,
                cursor: 'pointer',
                bgcolor: '#31004a',
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: 'white' }}
              >{`Members (${membersData?.getLeagueMembers?.count ?? 0})`}</Typography>
            </Box>
            {membersData?.getLeagueMembers?.data?.map((member: IAPILeagueMember, idx: number) => (
              <Box
                key={member.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: 2,
                  p: 1,
                  border: `2px solid white`,
                  color: 'white',
                  fontWeight: 600,
                  cursor: 'pointer',
                  bgcolor: '#31004a',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ height: 25, width: 25, mr: 1, flexShrink: 0 }}>
                    <ProfilePicture userId={member.user.id} navigable />
                  </Box>
                  <Box sx={{ overflow: 'hidden' }}>
                    <Typography variant="caption">
                      {`Rank ${idx + 1} - ${member.leagueRanking}`}
                    </Typography>
                    <RouterLink href={`${RoutePath.PROFILE}/${member.user.id}`}>
                      <Typography sx={cardTitleFontStyles}>
                        {getNameFromUser(member.user)}
                      </Typography>
                    </RouterLink>
                    <Typography
                      variant="caption"
                      sx={{ color: 'white' }}
                    >{`Since ${format(parseInt(member.date), 'dd/MM/yyyy')}`}</Typography>
                  </Box>
                </Box>
                {member.user.id !== cookies.signedInUser && (
                  <IconButton
                    onClick={() =>
                      navigate(
                        `${RoutePath.NEW_GAME}?leagueId=${id}&playerId=${member.user.id}&playerName=${getNameFromUser(member.user)}`,
                      )
                    }
                  >
                    <PlayIcon sx={{ fill: 'white' }} />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
