import { gql } from '@apollo/client';

export const GET_COMMUNITY_MEMBERS = gql`
  query GetCommunityMembers($communityId: String!, $limit: Int!, $offset: Int!) {
    getCommunityMembers(communityId: $communityId, limit: $limit, offset: $offset) {
      data {
        id
        date
        user {
          id
          firstName
          lastName
          username
          globalRanking
        }
      }
      count
    }
  }
`;
