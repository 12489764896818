import * as Yup from 'yup';

import {
  yupEmailValidation,
  yupFirstNameValidation,
  yupLastNameValidation,
  yupPasswordRegisterValidation,
} from '../../yup';

export const SignUpFormSchema = Yup.object({
  firstName: yupFirstNameValidation,
  lastName: yupLastNameValidation,
  email: yupEmailValidation,
  username: Yup.string()
    .required('Username is required')
    .matches(/^[A-Za-z]+$/, 'Username must only contain letters')
    .max(16, 'Username can not be longer than 16 characters'),
  password: yupPasswordRegisterValidation,
});
