import React, { useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { GET_COMPARISON } from '../../api/query/getComparison';
import { GET_FRIEND_LIST } from '../../api/query/getFriendList';
import { GET_USER } from '../../api/query/getUser';
import { IAPIFriend } from '../../api/types/friend';
import { ErrorAlert } from '../../component-library/error-alert';
import { LoadingSpinner } from '../../component-library/loading-spinner';
import { SubmitButton } from '../../component-library/submit-button';
import { armies } from '../../constants/armies';
import { colours } from '../../constants/colours';
import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useAppCookies } from '../../utils/use-cookies';
import { useUrlParams } from '../../utils/useUrlParams';

/**
 * This page will be the first thing the user sees when they load up the website.
 */
export const ComparisonPage: React.FC = () => {
  const { id } = useParams();

  const playerId = useUrlParams('playerId');
  const playerName = useUrlParams('playerName');

  const { cookies } = useAppCookies();

  const [user1, setUser1] = useState<{ id: string; army: string }>({ id: '', army: '' });
  const [user2, setUser2] = useState<{ id: string; army: string }>({ id: '', army: '' });

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const { data: userData } = useQuery(GET_USER, {
    variables: {
      id: cookies.signedInUser,
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: friendsListData,
    loading: friendsListLoading,
    error: friendsListError,
    fetchMore: friendsListFetchMore,
  } = useQuery(GET_FRIEND_LIST, {
    variables: {
      userId: cookies.signedInUser,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    nextFetchPolicy: 'network-only',
  });

  const [getComparison, { loading, error, data }] = useLazyQuery(GET_COMPARISON, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const getWinRateNumber = (partial: number, total: number) => {
    return (partial / total) * 100;
  };

  const getPercentage = (partial: number, total: number) => {
    const result = getWinRateNumber(partial, total);
    return isNaN(result) ? '0%' : `${result.toFixed(1).replace(/[.,]0$/, '')}%`;
  };

  const getToFixedValue = (value: number) => {
    return `${value.toFixed(1).replace(/[.,]0$/, '')}`;
  };

  const getFavouriteArmyOfPlayer = (id?: string) => {
    if (id === cookies.signedInUser) {
      return userData?.getUser?.favouriteArmy ?? '';
    }

    const player = friendsListData?.getFriendList?.data.find((f: IAPIFriend) => f.friend.id === id);

    if (player) {
      return player.friend.favouriteArmy;
    }

    return '';
  };

  const handleSubmit = async () => {
    if (!user1.id || !user1.army || !user2.id || !user2.army) {
      return;
    }

    await getComparison({
      variables: {
        user1Id: user1.id,
        user1Army: user1.army,
        user2Id: user2.id,
        user2Army: user2.army,
      },
    });
  };

  const user1WinRate =
    getWinRateNumber(
      data?.getComparison?.games.user1.totalWins,
      data?.getComparison?.games.user1.totalGames,
    ) ?? 0;

  const user2WinRate =
    getWinRateNumber(
      data?.getComparison?.games.user2.totalWins,
      data?.getComparison?.games.user2.totalGames,
    ) ?? 0;

  return (
    <Stack
      sx={{
        p: 2,

        pb: 6,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        alignItems: 'center',
      }}
    >
      <Grid
        container
        spacing={2}
        pl={2}
        sx={{
          maxWidth: 600,
          pr: 2,
          ml: 0,
          bgcolor: 'rgb(255,255,255, 0.9)',
          borderRadius: 3,
          mt: 4,
        }}
      >
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="user1">User 1</InputLabel>
                <Select
                  labelId="user1"
                  value={user1.id}
                  onChange={(e) =>
                    setUser1({
                      id: e.target.value,
                      army: getFavouriteArmyOfPlayer(e.target.value),
                    })
                  }
                  autoWidth
                  label="User 1"
                >
                  <MenuItem value={cookies.signedInUser}>Me</MenuItem>
                  {playerId && <MenuItem value={playerId}>{playerName}</MenuItem>}
                  {friendsListLoading && (
                    <LoadingSpinner
                      sx={{
                        margin: 'auto',
                        justifyContent: 'center',
                        width: '100%',
                        p: 2,
                        pb: 0,
                      }}
                      label={'Loading friends...'}
                    />
                  )}
                  {friendsListData?.getFriendList?.data
                    ?.filter(
                      (f: IAPIFriend) =>
                        f.friend.id !== cookies.signedInUser && playerId !== f.friend.id,
                    )
                    .map((friend: IAPIFriend) => (
                      <MenuItem value={friend.friend.id}>{getNameFromUser(friend.friend)}</MenuItem>
                    ))}
                  <ErrorAlert showError={Boolean(friendsListError?.message)} />
                  {Boolean(
                    friendsListData?.getFriendList?.data?.length > 0 &&
                      friendsListData?.getFriendList?.data?.length <
                        friendsListData?.getFriendList?.count,
                  ) && (
                    <SubmitButton
                      variant="contained"
                      sx={{ mr: 2 }}
                      label={'Load more'}
                      loading={friendsListLoading}
                      onClick={() =>
                        friendsListFetchMore({
                          variables: {
                            offset: friendsListData?.getFriendList?.data.length,
                          },
                          updateQuery: (previousResult, { fetchMoreResult }) => {
                            return {
                              getFriendList: {
                                data: [
                                  ...previousResult.getFriendList.data,
                                  ...fetchMoreResult.getFriendList.data,
                                ],
                                count: fetchMoreResult.getFriendList.count,
                              },
                            };
                          },
                        })
                      }
                    />
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="user1">User 1 Army</InputLabel>
                <Select
                  labelId="user1"
                  id="user1s-army"
                  value={user1.army}
                  onChange={(e) =>
                    setUser1({
                      ...user1,
                      army: e.target.value,
                    })
                  }
                  autoWidth
                  label="Attacker's Army"
                >
                  {armies.map((army) => (
                    <MenuItem key={`user1-${army}`} value={army}>
                      {army}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="user1">User 2</InputLabel>
                <Select
                  labelId="user2"
                  value={user2.id}
                  onChange={(e) =>
                    setUser2({
                      id: e.target.value,
                      army: getFavouriteArmyOfPlayer(e.target.value),
                    })
                  }
                  autoWidth
                  label="User 2"
                >
                  <MenuItem value={cookies.signedInUser}>Me</MenuItem>
                  {playerId && <MenuItem value={playerId}>{playerName}</MenuItem>}
                  {friendsListLoading && (
                    <LoadingSpinner
                      sx={{
                        margin: 'auto',
                        justifyContent: 'center',
                        width: '100%',
                        p: 2,
                        pb: 0,
                      }}
                      label={'Loading friends...'}
                    />
                  )}
                  {friendsListData?.getFriendList?.data
                    ?.filter(
                      (f: IAPIFriend) =>
                        f.friend.id !== cookies.signedInUser && playerId !== f.friend.id,
                    )
                    .map((friend: IAPIFriend) => (
                      <MenuItem value={friend.friend.id}>{getNameFromUser(friend.friend)}</MenuItem>
                    ))}
                  <ErrorAlert showError={Boolean(friendsListError?.message)} />
                  {Boolean(
                    friendsListData?.getFriendList?.data?.length > 0 &&
                      friendsListData?.getFriendList?.data?.length <
                        friendsListData?.getFriendList?.count,
                  ) && (
                    <SubmitButton
                      variant="contained"
                      sx={{ mr: 2 }}
                      label={'Load more'}
                      loading={friendsListLoading}
                      onClick={() =>
                        friendsListFetchMore({
                          variables: {
                            offset: friendsListData?.getFriendList?.data.length,
                          },
                          updateQuery: (previousResult, { fetchMoreResult }) => {
                            return {
                              getFriendList: {
                                data: [
                                  ...previousResult.getFriendList.data,
                                  ...fetchMoreResult.getFriendList.data,
                                ],
                                count: fetchMoreResult.getFriendList.count,
                              },
                            };
                          },
                        })
                      }
                    />
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="user2">User 2 Army</InputLabel>
                <Select
                  labelId="user2"
                  id="user2s-army"
                  value={user2.army}
                  onChange={(e) =>
                    setUser2({
                      ...user2,
                      army: e.target.value,
                    })
                  }
                  autoWidth
                  label="Attacker's Army"
                >
                  {armies.map((army) => (
                    <MenuItem key={`user2-${army}`} value={army}>
                      {army}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} pb={2}>
          <SubmitButton label={'Compare!'} loading={loading} onClick={handleSubmit} />
        </Grid>
      </Grid>

      {/* END OF THAT BIT */}

      {loading && (
        <LoadingSpinner
          sx={{
            color: 'white',
            margin: 'auto',
            justifyContent: 'center',
            width: '100%',
            p: 2,
            pb: 0,
            mt: 2,
          }}
          label={'Loading comparison...'}
        />
      )}

      <ErrorAlert sx={{ mt: 2, mb: 0 }} showError={Boolean(error?.message)} />

      {data?.getComparison && (
        <Grid
          container
          spacing={2}
          sx={{
            maxWidth: 600,
            mb: 3,
            pr: 2,
            pt: 2,
            ml: 0,
            mt: 2,
            border: '2px solid white',
            bgcolor: colours.darkBackground,
            borderRadius: 3,
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          <Grid
            container
            pl={2}
            pb={2}
            rowGap={2}
            sx={{ textAlign: 'center', alignItems: 'stretch' }}
          >
            <Grid item xs={5} p={1}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 3,
                  height: '100%',
                  bgcolor: 'rgb(49, 0, 74, 0.9)',
                  border: '2px solid white',
                  color: 'white',
                  p: 1,
                  fontSize: 14,
                }}
              >
                <Box sx={{ fontWeight: 'bold' }}>{getNameFromUser(data?.getComparison?.user1)}</Box>
                <Box>{user1.army}</Box>
                <Box>
                  <Typography variant="caption">
                    Rank - {data?.getComparison?.user1.globalRanking}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5} p={1}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 3,
                  height: '100%',
                  bgcolor: 'rgb(49, 0, 74, 0.9)',
                  border: '2px solid white',
                  color: 'white',
                  p: 1,
                  fontSize: 14,
                }}
              >
                <Box sx={{ fontWeight: 'bold' }}>{getNameFromUser(data?.getComparison?.user2)}</Box>
                <Box>{user2.army}</Box>
                <Box>
                  <Typography variant="caption">
                    Rank - {data?.getComparison?.user2.globalRanking}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Row 2 */}

            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    user1WinRate === user2WinRate
                      ? colours.drawText
                      : user1WinRate > user2WinRate
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {getPercentage(
                  data?.getComparison?.games.user1.totalWins,
                  data?.getComparison?.games.user1.totalGames,
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 14,
              }}
            >
              Win Rate
            </Grid>
            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    user1WinRate === user2WinRate
                      ? colours.drawText
                      : user2WinRate > user1WinRate
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {getPercentage(
                  data?.getComparison?.games.user2.totalWins,
                  data?.getComparison?.games.user2.totalGames,
                )}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider color="white" variant="fullWidth" />
            </Grid>

            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.vp.user1.averageVP ===
                    data?.getComparison?.vp.user2.averageVP
                      ? colours.drawText
                      : data?.getComparison?.vp.user2.averageVP <
                          data?.getComparison?.vp.user1.averageVP
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {getToFixedValue(data?.getComparison?.vp.user1.averageVP)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 14,
              }}
            >
              Avg VP
            </Grid>
            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.vp.user1.averageVP ===
                    data?.getComparison?.vp.user2.averageVP
                      ? colours.drawText
                      : data?.getComparison?.vp.user2.averageVP >
                          data?.getComparison?.vp.user1.averageVP
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {getToFixedValue(data?.getComparison?.vp.user2.averageVP)}
              </Typography>
            </Grid>

            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.vp.user1.averagePrimary ===
                    data?.getComparison?.vp.user2.averagePrimary
                      ? colours.drawText
                      : data?.getComparison?.vp.user2.averagePrimary <
                          data?.getComparison?.vp.user1.averagePrimary
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {getToFixedValue(data?.getComparison?.vp.user1.averagePrimary)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 14,
              }}
            >
              Avg Primary
            </Grid>
            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.vp.user1.averagePrimary ===
                    data?.getComparison?.vp.user2.averagePrimary
                      ? colours.drawText
                      : data?.getComparison?.vp.user2.averagePrimary >
                          data?.getComparison?.vp.user1.averagePrimary
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {getToFixedValue(data?.getComparison?.vp.user2.averagePrimary)}
              </Typography>
            </Grid>

            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.vp.user1.averagetTactical ===
                    data?.getComparison?.vp.user2.averagetTactical
                      ? colours.drawText
                      : data?.getComparison?.vp.user2.averagetTactical <
                          data?.getComparison?.vp.user1.averagetTactical
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {getToFixedValue(data?.getComparison?.vp.user1.averagetTactical)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 14,
              }}
            >
              Avg Tactical
            </Grid>
            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.vp.user1.averagetTactical ===
                    data?.getComparison?.vp.user2.averagetTactical
                      ? colours.drawText
                      : data?.getComparison?.vp.user2.averagetTactical >
                          data?.getComparison?.vp.user1.averagetTactical
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {getToFixedValue(data?.getComparison?.vp.user2.averagetTactical)}
              </Typography>
            </Grid>

            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.vp.user1.averagetFixed ===
                    data?.getComparison?.vp.user2.averagetFixed
                      ? colours.drawText
                      : data?.getComparison?.vp.user2.averagetFixed <
                          data?.getComparison?.vp.user1.averagetFixed
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {getToFixedValue(data?.getComparison?.vp.user1.averagetFixed)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 14,
              }}
            >
              Avg Fixed
            </Grid>
            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.vp.user1.averagetFixed ===
                    data?.getComparison?.vp.user2.averagetFixed
                      ? colours.drawText
                      : data?.getComparison?.vp.user2.averagetFixed >
                          data?.getComparison?.vp.user1.averagetFixed
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {getToFixedValue(data?.getComparison?.vp.user2.averagetFixed)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider color="white" variant="fullWidth" />
            </Grid>

            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.games.user1.totalGames ===
                    data?.getComparison?.games.user2.totalGames
                      ? colours.drawText
                      : data?.getComparison?.games.user2.totalGames <
                          data?.getComparison?.games.user1.totalGames
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {data?.getComparison?.games.user1.totalGames}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 14,
              }}
            >
              Games
            </Grid>
            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.games.user1.totalGames ===
                    data?.getComparison?.games.user2.totalGames
                      ? colours.drawText
                      : data?.getComparison?.games.user2.totalGames >
                          data?.getComparison?.games.user1.totalGames
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {data?.getComparison?.games.user2.totalGames}
              </Typography>
            </Grid>

            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.games.user1.totalWins ===
                    data?.getComparison?.games.user2.totalWins
                      ? colours.drawText
                      : data?.getComparison?.games.user2.totalWins <
                          data?.getComparison?.games.user1.totalWins
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {data?.getComparison?.games.user1.totalWins}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 14,
              }}
            >
              Wins
            </Grid>
            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.games.user1.totalWins ===
                    data?.getComparison?.games.user2.totalWins
                      ? colours.drawText
                      : data?.getComparison?.games.user2.totalWins >
                          data?.getComparison?.games.user1.totalWins
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {data?.getComparison?.games.user2.totalWins}
              </Typography>
            </Grid>

            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.games.user1.totalLosses ===
                    data?.getComparison?.games.user2.totalLosses
                      ? colours.drawText
                      : data?.getComparison?.games.user2.totalLosses >
                          data?.getComparison?.games.user1.totalLosses
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {data?.getComparison?.games.user1.totalLosses}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 14,
              }}
            >
              Losses
            </Grid>
            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color:
                    data?.getComparison?.games.user1.totalLosses ===
                    data?.getComparison?.games.user2.totalLosses
                      ? colours.drawText
                      : data?.getComparison?.games.user2.totalLosses <
                          data?.getComparison?.games.user1.totalLosses
                        ? colours.winText
                        : colours.lossText,
                }}
              >
                {data?.getComparison?.games.user2.totalLosses}
              </Typography>
            </Grid>

            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: colours.drawText,
                }}
              >
                {data?.getComparison?.games.user1.totalDraws}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 14,
              }}
            >
              Draws
            </Grid>
            <Grid item xs={5} p={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: colours.drawText,
                }}
              >
                {data?.getComparison?.games.user2.totalDraws}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};
