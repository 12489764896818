import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: String!
    $newFirstName: String
    $newLastName: String
    $currentPassword: String
    $newPassword: String
    $newFavouriteArmy: String
  ) {
    updateUser(
      id: $id
      newFirstName: $newFirstName
      newLastName: $newLastName
      currentPassword: $currentPassword
      newPassword: $newPassword
      newFavouriteArmy: $newFavouriteArmy
    ) {
      id
      firstName
      lastName
      email
      username
      favouriteArmy
    }
  }
`;
