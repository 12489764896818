import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CREATE_USER } from '../../api/mutation/createUser';
import { SIGN_IN } from '../../api/mutation/signIn';
import { ErrorAlert } from '../../component-library/error-alert';
import { ResetPasswordModal } from '../../component-library/reset-password-modal';
import { SubmitButton } from '../../component-library/submit-button';
import { armies } from '../../constants/armies';
import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { RoutePath } from '../../constants/routes';
import { getDateInFuture } from '../../utils/get-date-in-future';
import { CookieNames, useAppCookies } from '../../utils/use-cookies';
import { LoginFormSchema } from '../../validation/schemas/login-form';
import { LoginFormInputs } from '../../validation/schemas/login-form/types';
import { SignUpFormSchema } from '../../validation/schemas/register-form';
import { SignUpFormInputs } from '../../validation/schemas/register-form/types';

/**
 * This page will be the first thing the user sees when they load up the website.
 */
export const HomePage: React.FC = () => {
  const [view, setView] = useState<'sign-in' | 'register'>('sign-in');
  const [favouriteArmy, setFavouriteArmy] = useState(
    armies[Math.floor(Math.random() * armies.length - 1)],
  );

  const {
    register: registerRegister,
    handleSubmit: handleRegisterSubmit,
    formState: { errors: registerErrors },
  } = useForm<SignUpFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(SignUpFormSchema),
  });

  const {
    register: loginRegister,
    handleSubmit: handleLoginSubmit,
    formState: { errors: loginErrors },
  } = useForm<LoginFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(LoginFormSchema),
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);

  const navigation = useNavigate();
  const { cookies, setCookie } = useAppCookies();

  const [createUser, { loading: registerLoading, error: registerError, reset: resetRegister }] =
    useMutation(CREATE_USER);
  const [signIn, { loading: signInLoading, error: signInError, reset: resetSignIn }] =
    useMutation(SIGN_IN);

  useEffect(() => {
    if (cookies.signedInUser) {
      navigation('/hub');
    }
  }, [cookies.signedInUser, navigation]);

  const submitLogin: SubmitHandler<LoginFormInputs> = (formDetails) => {
    if (formDetails?.username && formDetails?.password) {
      signIn({
        variables: {
          email: formDetails?.username,
          password: formDetails?.password,
        },
      }).then((result: any) => {
        setCookie(CookieNames.SIGNED_IN_USER, result.data.signIn?.id, {
          expires: getDateInFuture({ daysInFuture: 60 }),
        });
      });
    }
  };

  const submitRegister: SubmitHandler<SignUpFormInputs> = (formDetails) => {
    if (
      formDetails?.username?.trim() &&
      formDetails?.email &&
      formDetails?.firstName &&
      formDetails?.lastName &&
      formDetails?.password
    ) {
      createUser({
        variables: {
          username: formDetails?.username,
          email: formDetails?.email,
          firstName: formDetails?.firstName,
          lastName: formDetails?.lastName,
          password: formDetails?.password,
          favouriteArmy,
        },
      }).then((result: any) => {
        setCookie(CookieNames.SIGNED_IN_USER, result.data?.createUser?.id, {
          expires: getDateInFuture({ daysInFuture: 60 }),
        });
      });
    }
  };

  return (
    <Stack
      sx={{
        p: 2,
        pt: 2,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        alignItems: 'center',
      }}
    >
      <ResetPasswordModal
        isOpen={resetPasswordModal}
        onClose={() => setResetPasswordModal(false)}
      />
      <Grid
        container
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 500,
          borderRadius: 1,
          bgcolor: 'rgb(255,255,255, 0.9)',
        }}
      >
        <Grid item m={4} gap={2} xs={12}>
          {view === 'sign-in' ? (
            <form
              onSubmit={handleLoginSubmit(submitLogin)}
              style={{
                gap: 16,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TextField
                sx={{ borderRadius: 1 }}
                variant="outlined"
                label={'Username'}
                fullWidth
                id={'username'}
                error={Boolean(loginErrors.username?.message)}
                helperText={loginErrors.username?.message}
                {...loginRegister('username')}
              />
              <TextField
                sx={{ borderRadius: 1 }}
                variant="outlined"
                label={'Password'}
                type="password"
                fullWidth
                id={'password'}
                error={Boolean(loginErrors.password?.message)}
                helperText={loginErrors.password?.message}
                {...loginRegister('password')}
              />
              <ErrorAlert
                showError={Boolean(signInError?.message)}
                message={
                  signInError?.message === 'Incorrect password' ||
                  signInError?.message === 'No user with that email or username exists'
                    ? signInError.message
                    : undefined
                }
              />
              <Button size="small" onClick={() => setResetPasswordModal(true)}>
                Forgot password
              </Button>
              <SubmitButton
                fullWidth
                disabled={registerLoading}
                loading={signInLoading}
                variant="contained"
                onClick={() => {}}
                label={'Sign In'}
              />
              <Divider></Divider>
              <Button
                fullWidth
                disabled={registerLoading}
                variant="contained"
                onClick={() => {
                  setView('register');
                  resetRegister();
                  resetSignIn();
                }}
              >
                Register
              </Button>
              <Divider>or</Divider>
              <Button
                fullWidth
                disabled={registerLoading}
                variant="contained"
                onClick={() => {
                  navigation(RoutePath.NEW_GUEST_GAME);
                }}
              >
                Play as guest
              </Button>
            </form>
          ) : (
            <form
              onSubmit={handleRegisterSubmit(submitRegister)}
              style={{
                gap: 16,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TextField
                sx={{ borderRadius: 1 }}
                variant="outlined"
                label={'First Name'}
                fullWidth
                id={'firstName'}
                error={Boolean(registerErrors.firstName?.message)}
                helperText={registerErrors.firstName?.message}
                {...registerRegister('firstName')}
              />
              <TextField
                id={'lastName'}
                variant="outlined"
                sx={{ borderRadius: 1 }}
                label={'Last Name'}
                fullWidth
                error={Boolean(registerErrors.lastName?.message)}
                helperText={registerErrors.lastName?.message}
                {...registerRegister('lastName')}
              />
              <TextField
                id={'username'}
                variant="outlined"
                type="username"
                sx={{ borderRadius: 1 }}
                label={'Username'}
                fullWidth
                error={Boolean(registerErrors.username?.message)}
                helperText={registerErrors.username?.message}
                {...registerRegister('username')}
              />
              <TextField
                id={'email'}
                variant="outlined"
                type="email"
                sx={{ borderRadius: 1 }}
                label={'Email'}
                fullWidth
                error={Boolean(registerErrors.email?.message)}
                helperText={registerErrors.email?.message}
                {...registerRegister('email')}
              />
              <TextField
                id={'password'}
                variant="outlined"
                sx={{ borderRadius: 1 }}
                label={'Password'}
                fullWidth
                type={passwordShown ? 'text' : 'password'}
                error={Boolean(registerErrors.password?.message)}
                helperText={registerErrors.password?.message}
                {...registerRegister('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <VisibilityIcon
                        onClick={() => setPasswordShown(!passwordShown)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl>
                <InputLabel id="favourite">Favourite Army</InputLabel>
                <Select
                  labelId="favourite"
                  sx={{ bgcolor: '#d9ead3' }}
                  id="favourites-army"
                  value={favouriteArmy}
                  onChange={(e) => setFavouriteArmy(e.target.value)}
                  autoWidth
                  label="Favourite Army"
                >
                  {armies.map((army) => (
                    <MenuItem key={`favourite-${army}`} value={army}>
                      {army}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <ErrorAlert
                showError={Boolean(registerError?.message)}
                message={registerError?.message}
              />
              <SubmitButton
                fullWidth
                disabled={signInLoading}
                loading={registerLoading}
                variant="contained"
                label={'Register'}
              />
              <Divider>or</Divider>
              <Button
                fullWidth
                disabled={registerLoading}
                variant="contained"
                onClick={() => {
                  setView('sign-in');
                  resetRegister();
                  resetSignIn();
                }}
              >
                Back to sign In
              </Button>
            </form>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};
