import { gql } from '@apollo/client';

export const GET_COMMUNITY = gql`
  query GetCommunity($userId: String!, $communityId: String!) {
    getCommunity(userId: $userId, communityId: $communityId) {
      id
      date
      isMember
      community {
        id
        date
        name
        owner {
          id
          firstName
          lastName
          username
        }
        private
      }
    }
  }
`;
