export function calculateAverageSuccessfulRolls({
  A,
  WS,
  S,
  T,
  Spp,
  AP,
  SV,
  Sppp,
  D,
}: {
  A: number;
  WS: number;
  S: number;
  T: number;
  Spp: number;
  AP: number;
  SV: number;
  Sppp: number;
  D: number;
}) {
  if (
    A === undefined ||
    WS === undefined ||
    S === undefined ||
    T === undefined ||
    AP === undefined ||
    D === undefined
  ) {
    return { averageSuccessfulRolls: 0, averageSuccessfulDamage: 0 };
  }

  const invuln = Spp;
  const feelNoPain = Sppp;
  const sv = Math.abs(SV) || 7;
  const ap = Math.abs(AP);
  const ws = !WS ?? WS < 1 ? 1 : WS;

  // Probability of rolling WS or higher
  const probWS = (7 - ws) / 6;

  // Probability of S vs T success
  let probST: number;
  if (S >= 2 * T) probST = 5 / 6;
  else if (S > T) probST = 4 / 6;
  else if (S === T) probST = 3 / 6;
  else if (S < T && S >= 0.5 * T) probST = 2 / 6;
  else probST = 1 / 6;

  // Probability of AP vs SV success
  let probAPSV;

  const saveThrowReq = ap + sv;
  if (!sv && !invuln) {
    probAPSV = 1;
  } else if (invuln) {
    if (invuln < saveThrowReq) {
      probAPSV = (invuln - 1) / 6;
    } else if (saveThrowReq > 6) {
      probAPSV = 1;
    } else {
      probAPSV = (saveThrowReq - 1) / 6;
    }
  } else if (saveThrowReq > 6) {
    probAPSV = 1;
  } else {
    probAPSV = (saveThrowReq - 1) / 6;
  }

  //   probAPSV = ((invuln && saveThrowReq > invuln ? invuln : saveThrowReq) - 1) / 6;

  // Probability of Sppp success
  const probSppp = feelNoPain ? Math.max((feelNoPain - 1) / 6, 0) : 1;

  // Average number of successful rolls
  const averageSuccessfulRolls = A * probWS * probST * probAPSV * probSppp;

  // Average number of successful damage
  const averageSuccessfulDamage = averageSuccessfulRolls * D;

  return { averageSuccessfulRolls, averageSuccessfulDamage };
}
