import React from 'react';

import { Grid, TextField, Typography } from '@mui/material';

import { IGameRoundScoring } from '.';
import { colours } from '../../constants/colours';

export const GameRound: React.FC<{
  round: number;
  attRound: IGameRoundScoring;
  defRound: IGameRoundScoring;
  setAttRound: React.Dispatch<React.SetStateAction<IGameRoundScoring>>;
  setDefRound: React.Dispatch<React.SetStateAction<IGameRoundScoring>>;
  attackerTotal: number;
  defenderTotal: number;
  roundNotes: string;
  setRoundNotes: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  round,
  attRound,
  defRound,
  attackerTotal,
  defenderTotal,
  setAttRound,
  setDefRound,
  roundNotes,
  setRoundNotes,
}) => {
  const getDifferenceInTotals = () => {
    if (attackerTotal < defenderTotal) {
      return `Defender leading by ${defenderTotal - attackerTotal} points`;
    } else if (attackerTotal > defenderTotal) {
      return `Attacker leading by ${attackerTotal - defenderTotal} points`;
    } else {
      return '';
    }
  };

  return (
    <Grid
      container
      sx={{
        borderRadius: 1,
        p: 2,
      }}
    >
      <Grid item xs={12} p={1}>
        <Typography variant="h6">Round {round}</Typography>
      </Grid>
      <Grid item xs={4} sm={2} p={1}></Grid>
      <Grid item xs={4} sm={5} p={1}>
        Attacker
      </Grid>
      <Grid item xs={4} sm={5} p={1}>
        Defender
      </Grid>
      <Grid item xs={4} sm={2} p={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="caption">Attackers round</Typography>
      </Grid>
      <Grid item xs={4} sm={5} p={1}>
        <TextField
          fullWidth
          value={attRound.attackersTurn}
          type="number"
          onChange={(e) => setAttRound({ ...attRound, attackersTurn: e.target.value })}
          sx={{ mb: 1, borderRadius: 1, backgroundColor: 'rgb(255,255,255, 0.5)' }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4} sm={5} p={1}>
        <TextField
          fullWidth
          value={defRound.attackersTurn}
          type="number"
          onChange={(e) => setDefRound({ ...defRound, attackersTurn: e.target.value })}
          sx={{ mb: 1, borderRadius: 1, backgroundColor: 'rgb(255,255,255, 0.5)' }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4} sm={2} p={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="caption">Defenders round</Typography>
      </Grid>
      <Grid item xs={4} sm={5} p={1}>
        <TextField
          fullWidth
          value={attRound.defendersTurn}
          type="number"
          onChange={(e) => setAttRound({ ...attRound, defendersTurn: e.target.value })}
          sx={{ mb: 1, borderRadius: 1, backgroundColor: 'rgb(255,255,255, 0.5)' }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4} sm={5} p={1}>
        <TextField
          fullWidth
          value={defRound.defendersTurn}
          type="number"
          onChange={(e) => setDefRound({ ...defRound, defendersTurn: e.target.value })}
          sx={{ mb: 1, borderRadius: 1, backgroundColor: 'rgb(255,255,255, 0.5)' }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4} sm={2} p={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="caption">Secondaries</Typography>
      </Grid>
      <Grid item xs={4} sm={5} p={1}>
        <TextField
          fullWidth
          value={attRound.secondary}
          type="number"
          onChange={(e) => setAttRound({ ...attRound, secondary: e.target.value })}
          sx={{ mb: 1, borderRadius: 1, backgroundColor: 'rgb(255,255,255, 0.5)' }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4} sm={5} p={1}>
        <TextField
          fullWidth
          value={defRound.secondary}
          type="number"
          onChange={(e) => setDefRound({ ...defRound, secondary: e.target.value })}
          sx={{ mb: 1, borderRadius: 1, backgroundColor: 'rgb(255,255,255, 0.5)' }}
          variant="outlined"
        />
      </Grid>

      {round > 1 && (
        <Grid container>
          <Grid item xs={4} sm={2} p={1} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption">Total</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sm={5}
            p={1}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Typography sx={{ color: colours.primaryThemeColour, fontWeight: 'bold' }}>
              {attackerTotal}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sm={5}
            p={1}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Typography sx={{ color: colours.primaryThemeColour, fontWeight: 'bold' }}>
              {defenderTotal}
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={4} sm={2} p={1} sx={{ display: 'flex', alignItems: 'center' }}></Grid>
            <Grid
              item
              xs={8}
              sm={10}
              p={1}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Typography variant="caption">{getDifferenceInTotals()}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={roundNotes}
          multiline
          minRows={2}
          maxRows={4}
          onChange={(e) => setRoundNotes(e.target.value)}
          sx={{ mb: 1, mt: 1, borderRadius: 1, bgcolor: 'rgb(255,255,255, 0.5)' }}
          label={'Round notes'}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};
