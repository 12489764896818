import { gql } from '@apollo/client';

export const GET_LEAGUE = gql`
  query GetLeague($userId: String!, $leagueId: String!) {
    getLeague(userId: $userId, leagueId: $leagueId) {
      id
      date
      league {
        id
        date
        name
        owner {
          id
          firstName
          lastName
          username
        }
        private
      }
    }
  }
`;
