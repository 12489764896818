import { gql } from '@apollo/client';

export const GET_FRIEND_REQUEST_LIST = gql`
  query GetFriendRequestList($userId: String!, $limit: Int!, $offset: Int!) {
    getFriendRequestList(userId: $userId, limit: $limit, offset: $offset) {
      data {
        id
        date
        sender {
          id
          email
          firstName
          username
          lastName
        }
      }
      count
    }
  }
`;
