import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
} from '@mui/material';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';

import { CREATE_GAME } from '../../api/mutation/createGame';
import { GET_LEAGUE_LIST } from '../../api/query/getLeagueList';
import { IAPILeagueMember } from '../../api/types/league-member';
import { ErrorAlert } from '../../component-library/error-alert';
import { LoadingSpinner } from '../../component-library/loading-spinner';
import { ModalHeader } from '../../component-library/modal-header';
import { SubmitButton } from '../../component-library/submit-button';
import { COMPONENT_HEIGHTS, COMPONENT_WIDTHS } from '../../constants/component-dimentions';
import { RoutePath } from '../../constants/routes';
import { IGamePlayerInfo } from '../../types/player';
import { useAppCookies } from '../../utils/use-cookies';
import { useUrlParams } from '../../utils/useUrlParams';
import { GameSwtich } from './switch';
import { validateGameFile } from './utils/validate-game-file';

/**
 * This page will be the first thing the user sees when they load up the website.
 */

export interface IGameRoundScoring {
  attackersTurn: string;
  defendersTurn: string;
  secondary: string;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const NewGamePage: React.FC = () => {
  const navigation = useNavigate();
  const { cookies } = useAppCookies();

  const leagueId = useUrlParams('leagueId');
  const importGame = useUrlParams('importGame');

  const [createGame, { loading: createLoading, error: createError }] = useMutation(CREATE_GAME);

  const [isImportGameOpen, setIsImportGameOpen] = useState(importGame === 'true');
  const [importError, setImportError] = useState('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [winCondition, setWinCondition] = useState<
    'points' | 'attacker-concedes' | 'defender-concedes'
  >('points');

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const {
    data: leaguesData,
    loading: loadingLeagues,
    error: leaguesError,
    fetchMore,
  } = useQuery(GET_LEAGUE_LIST, {
    variables: {
      userId: cookies.signedInUser,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    fetchPolicy: 'network-only',
  });

  const [selectedLeagueId, setSelectedLeagueId] = useState(leagueId || '');

  const [attackerArmyDetail, setAttackerArmyDetail] = useState('');
  const [defenderArmyDetail, setDefenderArmyDetail] = useState('');

  const [round1Notes, setRound1Notes] = useState('');
  const [round2Notes, setRound2Notes] = useState('');
  const [round3Notes, setRound3Notes] = useState('');
  const [round4Notes, setRound4Notes] = useState('');
  const [round5Notes, setRound5Notes] = useState('');

  const [primaryPoints, setPrimaryPoints] = useState('');
  const [tacticalPoints, setTacticalPoints] = useState('');
  const [fixedPoints, setFixedPoints] = useState('');

  const [attacker, setAttacker] = useState<IGamePlayerInfo>({
    id: '',
    name: '',
    army: '',
    secondaryScoring: 'Tactical',
  });
  const [defender, setDefender] = useState<IGamePlayerInfo>({
    id: '',
    name: '',
    army: '',
    secondaryScoring: 'Tactical',
  });

  const [attRound1, setAttRound1] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [defRound1, setDefRound1] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [attRound2, setAttRound2] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [defRound2, setDefRound2] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [attRound3, setAttRound3] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [defRound3, setDefRound3] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [attRound4, setAttRound4] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [defRound4, setDefRound4] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [attRound5, setAttRound5] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  const [defRound5, setDefRound5] = useState<IGameRoundScoring>({
    attackersTurn: '',
    defendersTurn: '',
    secondary: '',
  });

  useEffect(() => {
    if (!cookies.signedInUser) {
      navigation('/');
    }
  }, [cookies.signedInUser, navigation]);

  const [stage, setStage] = useState(0);

  const noPlayersSelected =
    !attacker.id ||
    !defender.id ||
    !attacker.army ||
    !defender.army ||
    // Same person (guest can be same person... why not)
    (attacker.id !== 'guest' && attacker.id === defender.id);

  const getAttackerTotalPoints = () => {
    return (
      (!isNaN(parseInt(attRound1.attackersTurn)) ? parseInt(attRound1.attackersTurn) : 0) +
      (!isNaN(parseInt(attRound1.defendersTurn)) ? parseInt(attRound1.defendersTurn) : 0) +
      (!isNaN(parseInt(attRound1.secondary)) ? parseInt(attRound1.secondary) : 0) +
      (!isNaN(parseInt(attRound2.attackersTurn)) ? parseInt(attRound2.attackersTurn) : 0) +
      (!isNaN(parseInt(attRound2.defendersTurn)) ? parseInt(attRound2.defendersTurn) : 0) +
      (!isNaN(parseInt(attRound2.secondary)) ? parseInt(attRound2.secondary) : 0) +
      (!isNaN(parseInt(attRound3.attackersTurn)) ? parseInt(attRound3.attackersTurn) : 0) +
      (!isNaN(parseInt(attRound3.defendersTurn)) ? parseInt(attRound3.defendersTurn) : 0) +
      (!isNaN(parseInt(attRound3.secondary)) ? parseInt(attRound3.secondary) : 0) +
      (!isNaN(parseInt(attRound4.attackersTurn)) ? parseInt(attRound4.attackersTurn) : 0) +
      (!isNaN(parseInt(attRound4.defendersTurn)) ? parseInt(attRound4.defendersTurn) : 0) +
      (!isNaN(parseInt(attRound4.secondary)) ? parseInt(attRound4.secondary) : 0) +
      (!isNaN(parseInt(attRound5.attackersTurn)) ? parseInt(attRound5.attackersTurn) : 0) +
      (!isNaN(parseInt(attRound5.defendersTurn)) ? parseInt(attRound5.defendersTurn) : 0) +
      (!isNaN(parseInt(attRound5.secondary)) ? parseInt(attRound5.secondary) : 0)
    );
  };

  const getDefenderTotalPoints = () => {
    return (
      (!isNaN(parseInt(defRound1.attackersTurn)) ? parseInt(defRound1.attackersTurn) : 0) +
      (!isNaN(parseInt(defRound1.defendersTurn)) ? parseInt(defRound1.defendersTurn) : 0) +
      (!isNaN(parseInt(defRound1.secondary)) ? parseInt(defRound1.secondary) : 0) +
      (!isNaN(parseInt(defRound2.attackersTurn)) ? parseInt(defRound2.attackersTurn) : 0) +
      (!isNaN(parseInt(defRound2.defendersTurn)) ? parseInt(defRound2.defendersTurn) : 0) +
      (!isNaN(parseInt(defRound2.secondary)) ? parseInt(defRound2.secondary) : 0) +
      (!isNaN(parseInt(defRound3.attackersTurn)) ? parseInt(defRound3.attackersTurn) : 0) +
      (!isNaN(parseInt(defRound3.defendersTurn)) ? parseInt(defRound3.defendersTurn) : 0) +
      (!isNaN(parseInt(defRound3.secondary)) ? parseInt(defRound3.secondary) : 0) +
      (!isNaN(parseInt(defRound4.attackersTurn)) ? parseInt(defRound4.attackersTurn) : 0) +
      (!isNaN(parseInt(defRound4.defendersTurn)) ? parseInt(defRound4.defendersTurn) : 0) +
      (!isNaN(parseInt(defRound4.secondary)) ? parseInt(defRound4.secondary) : 0) +
      (!isNaN(parseInt(defRound5.attackersTurn)) ? parseInt(defRound5.attackersTurn) : 0) +
      (!isNaN(parseInt(defRound5.defendersTurn)) ? parseInt(defRound5.defendersTurn) : 0) +
      (!isNaN(parseInt(defRound5.secondary)) ? parseInt(defRound5.secondary) : 0)
    );
  };

  const getAttackerTotalSecondaryPoints = () => {
    return (
      (!isNaN(parseInt(attRound1.secondary)) ? parseInt(attRound1.secondary) : 0) +
      (!isNaN(parseInt(attRound2.secondary)) ? parseInt(attRound2.secondary) : 0) +
      (!isNaN(parseInt(attRound3.secondary)) ? parseInt(attRound3.secondary) : 0) +
      (!isNaN(parseInt(attRound4.secondary)) ? parseInt(attRound4.secondary) : 0) +
      (!isNaN(parseInt(attRound5.secondary)) ? parseInt(attRound5.secondary) : 0)
    );
  };

  const getDefenderTotalSecondaryPoints = () => {
    return (
      (!isNaN(parseInt(defRound1.secondary)) ? parseInt(defRound1.secondary) : 0) +
      (!isNaN(parseInt(defRound2.secondary)) ? parseInt(defRound2.secondary) : 0) +
      (!isNaN(parseInt(defRound3.secondary)) ? parseInt(defRound3.secondary) : 0) +
      (!isNaN(parseInt(defRound4.secondary)) ? parseInt(defRound4.secondary) : 0) +
      (!isNaN(parseInt(defRound5.secondary)) ? parseInt(defRound5.secondary) : 0)
    );
  };

  const attackersPoints = () => {
    const attackersTotal = getAttackerTotalPoints();
    switch (winCondition) {
      case 'attacker-concedes':
        return 0;
      case 'defender-concedes':
        return attackersTotal === 0 ? 1 : attackersTotal;
      default:
        return attackersTotal;
    }
  };

  const defendersPoints = () => {
    const defendersTotal = getDefenderTotalPoints();
    switch (winCondition) {
      case 'defender-concedes':
        return 0;
      case 'attacker-concedes':
        return defendersTotal === 0 ? 1 : defendersTotal;
      default:
        return defendersTotal;
    }
  };

  const buildScore = () => {
    return JSON.stringify({
      attRound1,
      attRound2,
      attRound3,
      attRound4,
      attRound5,
      defRound1,
      defRound2,
      defRound3,
      defRound4,
      defRound5,
    });
  };

  const submitGame = async () => {
    await createGame({
      variables: {
        gameOwner: cookies.signedInUser,
        attackerId: attacker.id === 'guest' ? '' : attacker.id,
        defenderId: defender.id === 'guest' ? '' : defender.id,
        leagueId: selectedLeagueId,
        attackerPoints: attackersPoints(),
        defenderPoints: defendersPoints(),
        attackerAveragePoints: getAttackerTotalPoints(),
        defenderAveragePoints: getDefenderTotalPoints(),
        attackerArmy: attacker.army,
        defenderArmy: defender.army,
        score: buildScore(),
        attackerArmyDetail,
        defenderArmyDetail,
        round1Notes,
        round2Notes,
        round3Notes,
        round4Notes,
        round5Notes,
        attackerTacticalPoints:
          attacker.secondaryScoring === 'Tactical' ? getAttackerTotalSecondaryPoints() : null,
        attackerFixedPoints:
          attacker.secondaryScoring === 'Fixed' ? getAttackerTotalSecondaryPoints() : null,
        defenderTacticalPoints:
          defender.secondaryScoring === 'Tactical' ? getDefenderTotalSecondaryPoints() : null,
        defenderFixedPoints:
          defender.secondaryScoring === 'Fixed' ? getDefenderTotalSecondaryPoints() : null,
      },
    }).then(() => navigation(`${RoutePath.GAMES}?handshake=true`));
  };

  const saveForLater = async () => {
    const jsonBlob = new Blob(
      [
        JSON.stringify({
          leagueId: selectedLeagueId,
          attacker,
          defender,
          attRound1,
          attRound2,
          attRound3,
          attRound4,
          attRound5,
          defRound1,
          defRound2,
          defRound3,
          defRound4,
          defRound5,
          attackerArmyDetail,
          defenderArmyDetail,
          round1Notes,
          round2Notes,
          round3Notes,
          round4Notes,
          round5Notes,
          primaryPoints,
          tacticalPoints,
          fixedPoints,
        }),
      ],
      { type: 'application/json' },
    );
    saveAs(
      jsonBlob,
      `tallyman__${attacker.army.replace(' ', '_')}-vs-${defender.army.replace(' ', '_')}.json`,
    );
    setIsConfirmationModalOpen(false);
  };

  return (
    <Stack
      sx={{
        p: 2,
        pb: 6,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2} pt={2} pb={3} maxWidth={600}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
              bgcolor: stage < 6 ? 'rgb(255,255,255, 0.8)' : undefined,
            }}
          >
            <GameSwtich
              stage={stage}
              guestUser={false}
              attackerArmyDetail={attackerArmyDetail}
              defenderArmyDetail={defenderArmyDetail}
              tacticalPoints={tacticalPoints}
              setTacticalPoints={setTacticalPoints}
              fixedPoints={fixedPoints}
              setFixedPoints={setFixedPoints}
              setAttackerArmyDetail={setAttackerArmyDetail}
              setDefenderArmyDetail={setDefenderArmyDetail}
              round1Notes={round1Notes}
              round2Notes={round2Notes}
              round3Notes={round3Notes}
              round4Notes={round4Notes}
              round5Notes={round5Notes}
              setRound1Notes={setRound1Notes}
              setRound2Notes={setRound2Notes}
              setRound3Notes={setRound3Notes}
              setRound4Notes={setRound4Notes}
              setRound5Notes={setRound5Notes}
              attackerTotal={getAttackerTotalPoints()}
              defenderTotal={getDefenderTotalPoints()}
              attacker={attacker}
              defender={defender}
              setAttacker={setAttacker}
              setDefender={setDefender}
              attRound1={attRound1}
              defRound1={defRound1}
              setAttRound1={setAttRound1}
              setDefRound1={setDefRound1}
              attRound2={attRound2}
              defRound2={defRound2}
              setAttRound2={setAttRound2}
              setDefRound2={setDefRound2}
              attRound3={attRound3}
              defRound3={defRound3}
              setAttRound3={setAttRound3}
              setDefRound3={setDefRound3}
              attRound4={attRound4}
              defRound4={defRound4}
              setAttRound4={setAttRound4}
              setDefRound4={setDefRound4}
              attRound5={attRound5}
              defRound5={defRound5}
              setAttRound5={setAttRound5}
              setDefRound5={setDefRound5}
            />
          </Box>
        </Grid>
        {stage === 0 && noPlayersSelected && (
          <Grid item xs={12}>
            <Box
              sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                borderRadius: 1,
                bgcolor: 'rgb(255,255,255, 0.8)',
              }}
            >
              <Alert sx={{ width: '100%' }} severity="warning">
                You must select the players and their armies to continue
              </Alert>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box
            sx={{
              p: 1,
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              borderRadius: 1,
              bgcolor: 'rgb(255,255,255, 0.8)',
            }}
          >
            {stage === 0 && (
              <Button fullWidth variant="contained" onClick={() => setIsImportGameOpen(true)}>
                Import Game
              </Button>
            )}
            {stage > 0 && (
              <Button fullWidth variant="contained" onClick={() => setStage(stage - 1)}>
                Back
              </Button>
            )}
            {stage < 5 && (
              <Button
                disabled={stage === 0 && noPlayersSelected}
                fullWidth
                variant="contained"
                onClick={() => setStage(stage + 1)}
              >
                Next
              </Button>
            )}
            {stage === 5 && (
              <Button fullWidth variant="contained" onClick={() => setStage(stage + 1)}>
                End Battle
              </Button>
            )}
            {stage === 6 && (
              <Button
                fullWidth
                variant="contained"
                onClick={() => setIsConfirmationModalOpen(true)}
              >
                Next
              </Button>
            )}
          </Box>
        </Grid>
        {stage !== 0 && stage < 5 && (
          <Grid item xs={12}>
            <Box
              sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                borderRadius: 1,
                bgcolor: 'rgb(255,255,255, 0.8)',
              }}
            >
              <Button fullWidth variant="contained" onClick={() => setStage(6)}>
                Go to battle report
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      <Dialog open={isConfirmationModalOpen} onClose={() => setIsConfirmationModalOpen(false)}>
        <ModalHeader onClose={() => setIsConfirmationModalOpen(false)} title={'End Battle'} />
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            padding: 2,
            maxWidth: '100%',
            width: COMPONENT_WIDTHS.BODY,
          }}
          dividers
        >
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="win-condition">Win Condition</InputLabel>
            <Select
              labelId="win-condition"
              value={winCondition}
              onChange={(e) =>
                setWinCondition(
                  e.target.value as 'points' | 'attacker-concedes' | 'defender-concedes',
                )
              }
              fullWidth
              label="Win Condition"
            >
              <MenuItem value="points">Points</MenuItem>
              <MenuItem value="attacker-concedes">Attacker Concedes</MenuItem>
              <MenuItem value="defender-concedes">Defender Concedes</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="league">League</InputLabel>
            <Select
              labelId="win-condition"
              value={selectedLeagueId}
              onChange={(e) => setSelectedLeagueId(e.target.value ?? '')}
              fullWidth
              label="League"
            >
              <MenuItem value="">
                <br />
              </MenuItem>
              {leaguesData?.getLeagueList?.data?.map((league: IAPILeagueMember) => (
                <MenuItem key={league.league.id} value={league.league.id}>
                  {league.league.name}
                </MenuItem>
              ))}

              {loadingLeagues && (
                <LoadingSpinner
                  sx={{
                    margin: 'auto',
                    justifyContent: 'center',
                    width: '100%',
                    p: 2,
                    pb: 0,
                  }}
                  label={'Loading leagues...'}
                />
              )}
              <ErrorAlert showError={Boolean(leaguesError?.message)} />
              {Boolean(leaguesData?.getLeagueList?.data?.length === 0) && (
                <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
                  You aren't in any leagues.
                </Alert>
              )}
              {Boolean(
                leaguesData?.getLeagueList?.data?.length > 0 &&
                  leaguesData?.getLeagueList?.data?.length < leaguesData?.getLeagueList?.count,
              ) && (
                <SubmitButton
                  variant="outlined"
                  sx={{ mr: 2 }}
                  label={'Load more'}
                  loading={loadingLeagues}
                  onClick={() =>
                    fetchMore({
                      variables: {
                        offset: leaguesData?.getLeagueList?.data.length,
                      },
                      updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                          getLeagueList: {
                            data: [
                              ...previousResult.getLeagueList.data,
                              ...fetchMoreResult.getLeagueList.data,
                            ],
                            count: fetchMoreResult.getLeagueList.count,
                          },
                        };
                      },
                    })
                  }
                />
              )}
            </Select>
          </FormControl>
          <ErrorAlert
            showError={Boolean(createError?.message)}
            message={
              createError?.message === 'One of the players is not in that league!' ||
              createError?.message === `You can't play a league game with a guest!`
                ? createError.message
                : undefined
            }
          />
          <Button
            size="large"
            fullWidth
            variant="contained"
            onClick={() => saveForLater()}
            disabled={createLoading}
          >
            Save for later
          </Button>
          <Button
            size="large"
            fullWidth
            variant="contained"
            onClick={() => submitGame()}
            disabled={createLoading}
          >
            Confirm End Battle
          </Button>
        </DialogContent>
      </Dialog>

      {/* ------------ */}

      <Dialog
        open={isImportGameOpen}
        onClose={() => {
          setImportError('');
          setIsImportGameOpen(false);
        }}
      >
        <ModalHeader
          onClose={() => {
            setImportError('');
            setIsImportGameOpen(false);
          }}
          title={'Import Game'}
        />
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            padding: 2,
            maxWidth: '100%',
            width: COMPONENT_WIDTHS.BODY,
          }}
          dividers
        >
          <Alert severity="info">Remember to confirm the players are correct after importing</Alert>
          <FormControl sx={{ width: '100%' }}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput
                type="file"
                onChange={(event) => {
                  const file = (event?.target as any)?.files?.[0];
                  const reader = new FileReader();

                  setImportError('');

                  reader.onload = (e) => {
                    const content = e?.target?.result;
                    const data =
                      content && typeof content === 'string' ? JSON.parse(content) : undefined;
                    if (data && validateGameFile(data)) {
                      setSelectedLeagueId(data.leagueId);
                      setAttacker(data.attacker);
                      setDefender(data.defender);
                      setAttRound1(data.attRound1);
                      setAttRound2(data.attRound2);
                      setAttRound3(data.attRound3);
                      setAttRound4(data.attRound4);
                      setAttRound5(data.attRound5);
                      setDefRound1(data.defRound1);
                      setDefRound2(data.defRound2);
                      setDefRound3(data.defRound3);
                      setDefRound4(data.defRound4);
                      setDefRound5(data.defRound5);
                      setAttackerArmyDetail(data.attackerArmyDetail);
                      setDefenderArmyDetail(data.defenderArmyDetail);
                      setRound1Notes(data.round1Notes);
                      setRound2Notes(data.round2Notes);
                      setRound3Notes(data.round3Notes);
                      setRound4Notes(data.round4Notes);
                      setRound5Notes(data.round5Notes);
                      setPrimaryPoints(data.primaryPoints);
                      setTacticalPoints(data.tacticalPoints);
                      setFixedPoints(data.fixedPoints);
                      setIsImportGameOpen(false);
                    } else {
                      setImportError(`The selected file doesn't seem to be correct...`);
                    }
                  };

                  reader.readAsText(file);
                }}
              />
            </Button>
          </FormControl>
          <ErrorAlert showError={Boolean(importError)} message={importError} />
        </DialogContent>
      </Dialog>
    </Stack>
  );
};
