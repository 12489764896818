import { gql } from '@apollo/client';

export const CONFIRM_GAME = gql`
  mutation ConfirmGame($userId: String!, $gameId: String!) {
    confirmGame(userId: $userId, gameId: $gameId) {
      id
      attackerHandshake
      defenderHandshake
    }
  }
`;
