import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CompareIcon from '@mui/icons-material/Compare';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import PercentIcon from '@mui/icons-material/Percent';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PlayIcon from '@mui/icons-material/PlayCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ShieldIcon from '@mui/icons-material/Shield';
import StarsIcon from '@mui/icons-material/Stars';

import { RoutePath } from '../../constants/routes';

// Extra links to show non logged in users
export const NotSignedInLinks = [
  {
    href: RoutePath.HOMEPAGE,
    label: 'Sign in',
    icon: <AccountCircleOutlinedIcon />,
  },
  { href: RoutePath.NEW_GUEST_GAME, label: 'Play as Guest', icon: <PlayIcon /> },
  { href: RoutePath.DAMAGE_PROBABILITY, label: 'Probability calculator', icon: <PercentIcon /> },
];

// Extra links to show users that are logged in
// TODO: Optionally display this only if the user is logged in
export const AccountLinks = (id: string) => [
  { href: RoutePath.HUB, label: 'Hub', icon: <AutoAwesomeMosaicIcon /> },
  { href: RoutePath.GAMES, label: 'Games', icon: <ShieldIcon /> },
  { href: RoutePath.LEAGUES, label: 'Leagues', icon: <GroupsIcon /> },
  { href: RoutePath.COMMUNITIES, label: 'Communities', icon: <AccessibilityNewIcon /> },
  { href: `${RoutePath.PROFILE}/${id}`, label: 'My Profile', icon: <PersonOutlineIcon /> },
  { href: `${RoutePath.COMPARISON}/${id}`, label: 'Comparison', icon: <CompareIcon /> },
  { href: RoutePath.RANKINGS, label: 'Rankings', icon: <BarChartIcon /> },
  { href: RoutePath.SEASONS, label: 'Seasons', icon: <StarsIcon /> },
  { href: RoutePath.FRIENDS, label: 'Friends', icon: <GroupIcon /> },
  { href: RoutePath.NEW_GAME, label: 'New Game', icon: <PlayIcon /> },
  {
    href: `${RoutePath.NEW_GAME}?importGame=true`,
    label: 'Import Game',
    icon: <CloudUploadIcon />,
  },
  { href: RoutePath.SETTINGS, label: 'Settings', icon: <SettingsIcon /> },
  { href: RoutePath.DAMAGE_PROBABILITY, label: 'Probability calculator', icon: <PercentIcon /> },
];
