import * as Yup from 'yup';

export const PasswordUpdateUserFormSchema = Yup.object({
  currentPassword: Yup.string(),
  newPassword: Yup.string()
    .min(6, 'Password must be at least 6 characters long')
    .max(255)
    .test('oneOfRequired', 'You must enter your current password to update it', function (value) {
      return !!this.parent.currentPassword && !!value;
    }),
});

export const NameUpdateUserFormSchema = Yup.object({
  newFirstName: Yup.string().matches(
    /^[a-z ,.'-]+$/i,
    'First name must not contain special characters',
  ),
  newLastName: Yup.string().matches(
    /^[a-z ,.'-]+$/i,
    'Last name must not contain special characters',
  ),
});

export const FavArmyUpdateUserFormSchema = Yup.object({
  newFavouriteArmy: Yup.string(),
});
