import { gql } from '@apollo/client';

export const GET_GAMES_BY_COMMUNITY_LIST = gql`
  query GetGamesByCommunityList($communityId: String!, $limit: Int!, $offset: Int!) {
    getGamesByCommunityList(communityId: $communityId, limit: $limit, offset: $offset) {
      data {
        id
        date
        league {
          id
          name
        }
        attacker {
          id
          firstName
          lastName
          username
        }
        defender {
          id
          firstName
          lastName
          username
        }
        attackerPoints
        defenderPoints
        attackerArmy
        defenderArmy
      }
      count
    }
  }
`;
