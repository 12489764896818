import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/ReplayRounded';
import { Alert, Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { GET_LEAGUE_LIST } from '../../api/query/getLeagueList';
import { IAPILeagueMember } from '../../api/types/league-member';
import { CreateLeageModal } from '../../component-library/create-league-modal';
import { ErrorAlert } from '../../component-library/error-alert';
import { JoinLeagueModal } from '../../component-library/join-league-modal';
import { LoadingSpinner } from '../../component-library/loading-spinner';
import { SubmitButton } from '../../component-library/submit-button';
import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { RoutePath } from '../../constants/routes';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useAppCookies } from '../../utils/use-cookies';

/**
 * This page will be the first thing the user sees when they load up the website.
 */
export const LeaguesPage: React.FC = () => {
  const { cookies } = useAppCookies();
  const navigate = useNavigate();

  const [isCreateLeagueModalOpen, setIsCreateLeagueModalOpen] = useState(false);
  const [isJoinLeagueModalOpen, setIsJoinLeagueModalOpen] = useState(false);

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_LEAGUE_LIST, {
    variables: {
      userId: cookies.signedInUser,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Stack
      sx={{
        p: 2,
        pb: 6,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        alignItems: 'center',
      }}
    >
      <CreateLeageModal
        refetch={refetch}
        isOpen={isCreateLeagueModalOpen}
        onClose={() => setIsCreateLeagueModalOpen(false)}
      />
      <JoinLeagueModal
        refetch={refetch}
        isOpen={isJoinLeagueModalOpen}
        onClose={() => setIsJoinLeagueModalOpen(false)}
      />
      <Grid container spacing={2} sx={{ maxWidth: 600, pb: 3 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              p: 2,
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Button
                sx={{ minWidth: 'auto' }}
                size="small"
                variant="contained"
                onClick={() => setIsJoinLeagueModalOpen(true)}
              >
                Join a League
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 2,
                }}
              >
                <Button
                  sx={{ minWidth: 'auto' }}
                  size="small"
                  variant="contained"
                  onClick={() =>
                    refetch({
                      userId: cookies.signedInUser,
                      limit: paginationValues.limit,
                      offset: paginationValues.offset,
                    })
                  }
                >
                  <ReplayIcon />
                </Button>
                <Button
                  sx={{ minWidth: 'auto' }}
                  size="small"
                  variant="contained"
                  onClick={() => setIsCreateLeagueModalOpen(true)}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Box>
            {loading && (
              <LoadingSpinner
                sx={{
                  color: 'white',
                  margin: 'auto',
                  justifyContent: 'center',
                  width: '100%',
                  p: 2,
                  pb: 0,
                }}
                label={'Loading leagues...'}
              />
            )}
            <ErrorAlert showError={Boolean(error?.message)} />
            {Boolean(data?.getLeagueList?.data?.length === 0) && (
              <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
                You aren't in any leagues.
                <br />
                Try searching for one you know and joining it!
              </Alert>
            )}
            {data?.getLeagueList?.data?.map((league: IAPILeagueMember) => (
              <Box
                key={league.id}
                onClick={() => navigate(`${RoutePath.LEAGUES}/${league.league.id}`)}
                sx={{
                  display: 'flex',
                  borderRadius: 1,
                  p: 2,
                  fontWeight: 600,
                  cursor: 'pointer',
                  color: 'white',
                  bgcolor: 'rgb(49, 0, 74, 0.95)',
                  border: '2px solid white',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>{league.league.name}</Typography>
                  <Typography variant="caption">
                    Owner - {getNameFromUser(league.league.owner)}
                  </Typography>
                </Box>
              </Box>
            ))}

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              {Boolean(
                data?.getLeagueList?.data?.length > 0 &&
                  data?.getLeagueList?.data?.length < data?.getLeagueList?.count,
              ) && (
                <SubmitButton
                  variant="contained"
                  label={'Load more'}
                  loading={loading}
                  onClick={() =>
                    fetchMore({
                      variables: {
                        offset: data?.getLeagueList?.data.length,
                      },
                      updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                          getLeagueList: {
                            data: [
                              ...previousResult.getLeagueList.data,
                              ...fetchMoreResult.getLeagueList.data,
                            ],
                            count: fetchMoreResult.getLeagueList.count,
                          },
                        };
                      },
                    })
                  }
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
