import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $username: String!
    $password: String!
    $favouriteArmy: String!
  ) {
    createUser(
      email: $email
      username: $username
      password: $password
      firstName: $firstName
      lastName: $lastName
      favouriteArmy: $favouriteArmy
    ) {
      id
      firstName
      lastName
      email
      username
    }
  }
`;
