import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';

import { GET_USER_RANKINGS } from '../../api/query/getUserRankings';
import { IAPIUser } from '../../api/types/user';
import { RoutePath } from '../../constants/routes';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { ErrorAlert } from '../error-alert';
import { LoadingSpinner } from '../loading-spinner';
import { ProfilePicture } from '../profile-picture';
import { RouterLink } from '../router-link';
import { SubmitButton } from '../submit-button';

export const GlobalRankings = () => {
  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const {
    data: globalRankingsData,
    loading: globalRankingsLoading,
    error: globalRankingsError,
    fetchMore,
  } = useQuery(GET_USER_RANKINGS, {
    variables: {
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    fetchPolicy: 'network-only',
  });

  if (globalRankingsLoading) {
    return (
      <LoadingSpinner
        sx={{
          color: 'white',
          margin: 'auto',
          justifyContent: 'center',
          width: '100%',
          p: 2,
          pb: 0,
        }}
        label={'Loading rankings...'}
      />
    );
  }

  if (globalRankingsError?.message) {
    return <ErrorAlert showError={Boolean(globalRankingsError?.message)} />;
  }

  return (
    <>
      {(globalRankingsData?.getUserRankings?.data || []).map((user: IAPIUser, idx: number) => (
        <Box
          key={user.id}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: 2,
            p: 2,
            border: `2px solid white`,
            color: 'white',
            fontWeight: 600,
            cursor: 'pointer',
            bgcolor: 'rgb(49, 0, 74, 0.8)',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ height: 25, width: 25, mr: 1, flexShrink: 0 }}>
              <ProfilePicture userId={user.id} navigable />
            </Box>
            <Box sx={{ overflow: 'hidden' }}>
              <Typography variant="caption">{`Rank ${idx + 1} - ${user.globalRanking}`}</Typography>
              <RouterLink href={`${RoutePath.PROFILE}/${user.id}`}>
                <Typography sx={cardTitleFontStyles}>{getNameFromUser(user)}</Typography>
              </RouterLink>
              <Typography
                variant="caption"
                sx={{ color: 'white' }}
              >{`Since ${format(parseInt(user.date), 'dd/MM/yyyy')}`}</Typography>
            </Box>
          </Box>
        </Box>
      ))}

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {Boolean(
          globalRankingsData?.getUserRankings?.data?.length > 0 &&
            globalRankingsData?.getUserRankings?.data?.length <
              globalRankingsData?.getUserRankings?.count,
        ) && (
          <SubmitButton
            variant="contained"
            sx={{ mr: 2 }}
            label={'Load more'}
            loading={globalRankingsLoading}
            onClick={() =>
              fetchMore({
                variables: {
                  offset: globalRankingsData?.getUserRankings?.data.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return {
                    getUserRankings: {
                      data: [
                        ...previousResult.getUserRankings.data,
                        ...fetchMoreResult.getUserRankings.data,
                      ],
                      count: fetchMoreResult.getUserRankings.count,
                    },
                  };
                },
              })
            }
          />
        )}
      </Box>
    </>
  );
};
