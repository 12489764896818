import React from 'react';

import { Alert, Button, Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { RoutePath } from '../../constants/routes';

export const SeasonsPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        p: 2,
        pb: 6,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2} sx={{ maxWidth: 600, pb: 3 }}>
        <Grid item xs={12}>
          <Alert severity="info" sx={{ mt: 2 }}>
            Coming soon!
            <br />
            Seasons are tournaments, similar to leagues, but with a limited timeframe.
            <br />
            Unlike leagues, where the player rank change is dependent on the opponent's rank, in
            seasons, each win will be worth X points (with the addition of bonus points regardless
            of the game result).
            <br />
            The season will be limited to a number of rounds, where each player must play every
            other player X times, where X is determined by the season creator.
          </Alert>
          <Button
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() => navigate(RoutePath.HOMEPAGE)}
          >
            Back to Hub
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};
