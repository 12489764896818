import { IAPIUser } from '../../api/types/user';

export const getNameFromUser = (user?: IAPIUser, humanName?: boolean) => {
  if (user) {
    if (humanName) {
      if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName}`;
      }
    } else {
      return user.username;
    }
  }
  return '';
};
