import { gql } from '@apollo/client';

export const GET_FRIENDS_GAMES_LIST = gql`
  query GetFriendsGamesList($userId: String!, $limit: Int!, $offset: Int!) {
    getFriendsGamesList(userId: $userId, limit: $limit, offset: $offset) {
      data {
        id
        date
        league {
          name
        }
        attacker {
          id
          firstName
          lastName
          username
        }
        defender {
          id
          firstName
          lastName
          username
        }
        attackerPoints
        defenderPoints
        attackerArmy
        defenderArmy
        attackerHandshake
        defenderHandshake
      }
      count
    }
  }
`;
