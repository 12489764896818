import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { DELETE_LEAGUE } from '../../api/mutation/deleteLeague';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';
import { RoutePath } from '../../constants/routes';
import { useAppCookies } from '../../utils/use-cookies';
import { ErrorAlert } from '../error-alert';
import { ModalHeader } from '../modal-header';
import { SubmitButton } from '../submit-button';

interface ILeageOptionsModal {
  isOpen: boolean;
  onClose: () => void;
}

export const LeageOptionsModal: React.FC<ILeageOptionsModal> = ({ isOpen, onClose }) => {
  const { cookies } = useAppCookies();
  const { id } = useParams();
  const navigate = useNavigate();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteLeague, { loading: deleteLoading, error: deleteError, reset }] =
    useMutation(DELETE_LEAGUE);

  const handleClose = () => {
    onClose();
    reset();
    setConfirmDelete(false);
  };

  const onSubmitonConfirmDelete = () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }
    deleteLeague({
      variables: { userId: cookies.signedInUser, leagueId: id },
    }).then(() => {
      handleClose();
      navigate(RoutePath.LEAGUES);
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <ModalHeader onClose={handleClose} title={'League settings'} />
      <DialogContent sx={{ padding: 2, maxWidth: '100%', width: COMPONENT_WIDTHS.BODY }} dividers>
        {confirmDelete && (
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography>Wait...</Typography>
            <Typography variant="body1">Hold up!</Typography>
            <Typography variant="body2">Are you absolutely sure?</Typography>
            <Typography variant="body2">
              Players won't lose any games or stats from those games.
            </Typography>
            <Typography variant="body2">
              Just wanted to make sure you didn't click that button by accident...
            </Typography>
          </Box>
        )}
        <SubmitButton
          onClick={onSubmitonConfirmDelete}
          sx={{ mb: 1 }}
          loading={deleteLoading}
          label={confirmDelete ? 'Confirm delete league' : 'Delete League'}
        />
        <ErrorAlert
          showError={Boolean(deleteError?.message)}
          message={
            deleteError?.message === 'Only the owner of that league can delete it'
              ? deleteError.message
              : undefined
          }
        />
      </DialogContent>
    </Dialog>
  );
};
