import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import PlayIcon from '@mui/icons-material/PlayCircle';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';

import { JOIN_COMMUNITY } from '../../api/mutation/joinCommunity';
import { GET_COMMUNITY } from '../../api/query/getCommunity';
import { GET_COMMUNITY_MEMBERS } from '../../api/query/getCommunityMembers';
import { IAPICommunityMember } from '../../api/types/community-member';
import { CommunityOptionsModal } from '../../component-library/community-options-modal';
import { ErrorAlert } from '../../component-library/error-alert';
import { LoadingSpinner } from '../../component-library/loading-spinner';
import { ProfilePicture } from '../../component-library/profile-picture';
import { RouterLink } from '../../component-library/router-link';
import { SubmitButton } from '../../component-library/submit-button';
import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { RoutePath } from '../../constants/routes';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useAppCookies } from '../../utils/use-cookies';
import { CommunityGamesList } from './community-games-list';

/**
 * This page will be the first thing the user sees when they load up the website.
 */
export const CommunityPage: React.FC = () => {
  const { id } = useParams();
  const { cookies } = useAppCookies();
  const navigate = useNavigate();

  const [isCommunityOptionsOpen, setIsCommunityOptionsOpen] = useState(false);

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const {
    data: communityData,
    loading: communityLoading,
    error: communityError,
    refetch: communityRefetch,
  } = useQuery(GET_COMMUNITY, {
    variables: {
      userId: cookies.signedInUser,
      communityId: id,
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: membersData,
    loading: membersLoading,
    error: membersError,
    refetch: refetchMember,
  } = useQuery(GET_COMMUNITY_MEMBERS, {
    variables: {
      communityId: id,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    fetchPolicy: 'network-only',
  });

  const [joinCommunity, { loading: joinLoading, error: joinError }] = useMutation(JOIN_COMMUNITY);

  const handleJoinCommunity = () => {
    joinCommunity({
      variables: { userId: cookies.signedInUser, communityId: id },
    }).then(() => {
      refetchMember();
      communityRefetch();
    });
  };

  return (
    <Stack
      sx={{
        p: 2,
        pb: 6,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        alignItems: 'center',
      }}
    >
      <CommunityOptionsModal
        isOpen={isCommunityOptionsOpen}
        onClose={() => setIsCommunityOptionsOpen(false)}
      />
      <Grid container spacing={2} sx={{ maxWidth: 700, pb: 3 }}>
        <Grid item xs={12}>
          {communityData?.getCommunity?.isMember === false && (
            <Box sx={{ mt: 2 }}>
              <SubmitButton
                variant="contained"
                fullWidth
                label={'Join Community'}
                loading={joinLoading}
                onClick={() => handleJoinCommunity()}
              />
              <ErrorAlert showError={Boolean(joinError?.message)} />
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              p: 2,
              borderRadius: 1,
              fontWeight: 600,
              color: 'white',
              bgcolor: '#31004a',
              border: '2px solid white',
            }}
          >
            {communityLoading && (
              <LoadingSpinner
                sx={{
                  color: 'white',
                  margin: 'auto',
                  justifyContent: 'center',
                  width: '100%',
                  p: 2,
                  pb: 0,
                }}
                label={'Loading community info...'}
              />
            )}
            <ErrorAlert showError={Boolean(communityError?.message)} />
            {communityData?.getCommunity && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <Typography variant="caption">Community</Typography>
                    <Typography variant="h6">
                      {communityData.getCommunity.community.name}
                    </Typography>
                  </Box>
                  {cookies.signedInUser === communityData.getCommunity.community.owner.id && (
                    <Box>
                      <IconButton
                        sx={{ minWidth: 'auto' }}
                        onClick={() => setIsCommunityOptionsOpen(true)}
                      >
                        <SettingsApplicationsIcon sx={{ fill: 'white' }} color="action" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
                <Box>
                  <Typography variant="caption">
                    est - {format(parseInt(communityData.getCommunity.date), 'dd/MM/yyyy')}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              borderRadius: 1,
            }}
          >
            <Box>
              <CommunityGamesList />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              borderRadius: 1,
            }}
          >
            {membersLoading && (
              <LoadingSpinner
                sx={{
                  margin: 'auto',
                  justifyContent: 'center',
                  width: '100%',
                  p: 2,
                  pb: 0,
                }}
                label={'Loading community info...'}
              />
            )}
            <ErrorAlert showError={Boolean(membersError?.message)} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 2,
                p: 1,
                border: `2px solid white`,
                color: 'white',
                fontWeight: 600,
                cursor: 'pointer',
                bgcolor: '#31004a',
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: 'white' }}
              >{`Members (${membersData?.getCommunityMembers?.count ?? 0})`}</Typography>
            </Box>
            {membersData?.getCommunityMembers?.data?.map(
              (member: IAPICommunityMember, idx: number) => (
                <Box
                  key={member.id}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderRadius: 2,
                    p: 1,
                    border: `2px solid white`,
                    color: 'white',
                    fontWeight: 600,
                    cursor: 'pointer',
                    bgcolor: '#31004a',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ height: 25, width: 25, mr: 1, flexShrink: 0 }}>
                      <ProfilePicture userId={member.user.id} navigable />
                    </Box>
                    <Box sx={{ overflow: 'hidden' }}>
                      <Typography variant="caption">
                        {`Rank ${idx + 1} - ${member.user.globalRanking}`}
                      </Typography>
                      <RouterLink href={`${RoutePath.PROFILE}/${member.user.id}`}>
                        <Typography sx={cardTitleFontStyles}>
                          {getNameFromUser(member.user)}
                        </Typography>
                      </RouterLink>
                      <Typography
                        variant="caption"
                        sx={{ color: 'white' }}
                      >{`Since ${format(parseInt(member.date), 'dd/MM/yyyy')}`}</Typography>
                    </Box>
                  </Box>
                  {member.user.id !== cookies.signedInUser && (
                    <IconButton
                      onClick={() =>
                        navigate(
                          `${RoutePath.NEW_GAME}?communityId=${id}&playerId=${member.user.id}&playerName=${getNameFromUser(member.user)}`,
                        )
                      }
                    >
                      <PlayIcon sx={{ fill: 'white' }} />
                    </IconButton>
                  )}
                </Box>
              ),
            )}
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
