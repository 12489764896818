import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert, Box, Chip, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { GET_GAMES_LIST } from '../../api/query/getGamesList';
import { IAPIGame } from '../../api/types/game';
import { ErrorAlert } from '../../component-library/error-alert';
import { LoadingSpinner } from '../../component-library/loading-spinner';
import { SubmitButton } from '../../component-library/submit-button';
import { colours } from '../../constants/colours';
import { RoutePath } from '../../constants/routes';
import { getNameFromUser } from '../../utils/get-name-from-user';

export const PlayerGames: React.FC<{ id?: string }> = ({ id }) => {
  const navigation = useNavigate();

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const { data, loading, error, fetchMore } = useQuery(GET_GAMES_LIST, {
    variables: {
      userId: id,
      handshakes: false,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Grid container spacing={2} sx={{ pb: 4 }}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            pt: 2,
            borderRadius: 1,
          }}
        >
          {loading && (
            <LoadingSpinner
              sx={{
                color: 'white',
                margin: 'auto',
                justifyContent: 'center',
                width: '100%',
                p: 2,
                pb: 0,
              }}
              label={'Loading games...'}
            />
          )}
          <ErrorAlert showError={Boolean(error?.message)} />
          {Boolean(data?.getGamesList?.data?.length === 0) && (
            <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
              This player doesn't have any games recorded.
            </Alert>
          )}
          {data?.getGamesList?.data?.map((game: IAPIGame) => (
            <Grid
              key={game.id}
              container
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: 2,
                p: 2,
                border: `2px solid white`,
                color: 'white',
                fontWeight: 600,
                cursor: 'pointer',
                bgcolor: colours.darkBackground,
              }}
              onClick={() => navigation(`${RoutePath.GAMES}/${game.id}`)}
            >
              {(!game.attackerHandshake || !game.defenderHandshake) && (
                <>
                  <Grid
                    xs={4}
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Chip
                      color={game.attackerHandshake ? 'success' : 'error'}
                      size="small"
                      icon={game.attackerHandshake ? <CheckCircleIcon /> : <CancelIcon />}
                      label="Handshake"
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Chip
                      color={game.defenderHandshake ? 'success' : 'error'}
                      size="small"
                      icon={game.defenderHandshake ? <CheckCircleIcon /> : <CancelIcon />}
                      label="Handshake"
                    />
                  </Grid>
                </>
              )}
              <Grid
                xs={4}
                container
                sx={{
                  wordBreak: 'break-word',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'space-around',
                  color: `${game.attackerPoints === game.defenderPoints ? '#e5c777' : game.attackerPoints < game.defenderPoints ? '#df6060' : '#479147'}`,
                }}
              >
                <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {getNameFromUser(game.attacker)}
                  </Typography>
                  <Typography variant="caption">{game.attackerArmy}</Typography>
                </Grid>
              </Grid>
              <Grid
                xs={4}
                container
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid
                    container
                    gap={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="caption">
                        {format(parseInt(game.date), 'dd/MM/yyyy')}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          color: `${game.attackerPoints === game.defenderPoints ? '#e5c777' : game.attackerPoints < game.defenderPoints ? '#df6060' : '#479147'}`,
                        }}
                      >
                        {game.attackerPoints}
                      </Typography>
                      <Typography>{' - '}</Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          color: `${game.attackerPoints === game.defenderPoints ? '#e5c777' : game.attackerPoints > game.defenderPoints ? '#df6060' : '#479147'}`,
                        }}
                      >
                        {game.defenderPoints}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                xs={4}
                container
                sx={{
                  wordBreak: 'break-word',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: `${game.attackerPoints === game.defenderPoints ? '#e5c777' : game.attackerPoints > game.defenderPoints ? '#df6060' : '#479147'}`,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {getNameFromUser(game.defender) || 'Guest'}
                  </Typography>
                  <Typography variant="caption">{game.defenderArmy}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            {Boolean(
              data?.getGamesList?.data?.length > 0 &&
                data?.getGamesList?.data?.length < data?.getGamesList?.count,
            ) && (
              <SubmitButton
                variant="contained"
                sx={{ mr: 2 }}
                label={'Load more'}
                loading={loading}
                onClick={() =>
                  fetchMore({
                    variables: {
                      offset: data?.getGamesList?.data.length,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                      return {
                        getGamesList: {
                          data: [
                            ...previousResult.getGamesList.data,
                            ...fetchMoreResult.getGamesList.data,
                          ],
                          count: fetchMoreResult.getGamesList.count,
                        },
                      };
                    },
                  })
                }
              />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
