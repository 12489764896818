import React from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router';

import { colours } from '../../constants/colours';
import { RoutePath } from '../../constants/routes';
import { CookieNames, useAppCookies } from '../../utils/use-cookies';

interface ILogoutNavItemProps {}

export const LogoutNavItem: React.FC<ILogoutNavItemProps> = ({}) => {
  const navigate = useNavigate();

  const { cookies, removeCookie } = useAppCookies();

  const handleOnClick = () => {
    removeCookie(CookieNames.SIGNED_IN_USER);
    navigate(RoutePath.HOMEPAGE);
  };

  // We don't want to give the ability to logout if they're not logged in.
  if (!cookies?.signedInUser) {
    return null;
  }

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleOnClick} data-testid="logout-nav-item-button">
        <ListItemIcon sx={{ color: colours.primaryInverseColour }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary={'Logout'} sx={{ color: colours.primaryInverseColour }} />
      </ListItemButton>
    </ListItem>
  );
};
