import { Stack } from '@mui/material';

import { RankingsTabs } from '../../component-library/rankings-tabs';
import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';

export const RankingsPage = () => {
  return (
    <Stack
      sx={{
        pb: 6,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        alignItems: 'center',
      }}
    >
      <RankingsTabs />
    </Stack>
  );
};
