import React, { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

import { colours } from '../../constants/colours';
import { RoutePath } from '../../constants/routes';
import { SideNavigation } from '../side-navigation';

/**
 * The header will show on every page in the website.
 * This should be used to help the user easily navigate around the site.
 */
export const Header: React.FC = () => {
  // Determines whether or not to display the nav menu
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

  // Handlers for opening and closing the nav menu
  const openNavMenu = () => setIsNavMenuOpen(true);
  const closeNavMenu = () => setIsNavMenuOpen(false);

  const navigate = useNavigate();

  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <SideNavigation isNavMenuOpen={isNavMenuOpen} closeNavMenu={closeNavMenu} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft={2}
        paddingRight={2}
        sx={{
          backgroundColor: colours.primaryThemeColour,
          height: '65px',
          width: '100vw',
          position: 'fixed',
          zIndex: 2,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <MenuIcon
            onClick={openNavMenu}
            sx={{ color: colours.primaryInverseColour, cursor: 'pointer' }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            onClick={() => {
              navigate(RoutePath.HOMEPAGE);
            }}
            sx={{
              height: '50px',
              width: '150px',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url('/images/${'logo-lg'}.png')`,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          />
          <Typography
            onClick={() => {
              navigate(RoutePath.HOMEPAGE);
            }}
            sx={{
              cursor: 'pointer',
              color: 'white',
              ml: 1,
              fontFamily: 'monospace',
            }}
          >
            (Beta)
          </Typography>
        </Box>
        <Box />
      </Stack>
    </>
  );
};
