import { gql } from '@apollo/client';

export const GET_GAME = gql`
  query GetGame($gameId: String!) {
    getGame(gameId: $gameId) {
      id
      date
      league {
        id
        name
      }
      attacker {
        id
        firstName
        lastName
        username
      }
      defender {
        id
        firstName
        lastName
        username
      }
      attackerPoints
      defenderPoints
      attackerArmy
      defenderArmy
      attackerHandshake
      defenderHandshake
      score
      attackerArmyDetail
      defenderArmyDetail
      round1Notes
      round2Notes
      round3Notes
      round4Notes
      round5Notes
    }
  }
`;
