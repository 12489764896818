import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CREATE_COMMUNITY } from '../../api/mutation/createCommunity';
import { colours } from '../../constants/colours';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';
import { useAppCookies } from '../../utils/use-cookies';
import { CreateCommunityFormSchema } from '../../validation/schemas/create-community';
import { CreateCommunityFormInputs } from '../../validation/schemas/create-community/types';
import { ModalHeader } from '../modal-header';
import { SubmitButton } from '../submit-button';

interface ICreateCommunityModal {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

export const CreateCommunityModal: React.FC<ICreateCommunityModal> = ({
  isOpen,
  onClose,
  refetch,
}) => {
  const { cookies } = useAppCookies();
  const [isPrivateChecked, setIsPrivateChecked] = useState(false);

  const [createCommunity, { loading: createLoading, error: createError }] =
    useMutation(CREATE_COMMUNITY);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CreateCommunityFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(CreateCommunityFormSchema),
  });

  // Reset form values on toggle
  useEffect(() => {
    setValue('name', '');
  }, [setValue]);

  const onSubmit: SubmitHandler<CreateCommunityFormInputs> = (data) => {
    if (Boolean(!data.name)) {
      return;
    }

    createCommunity({
      variables: { userId: cookies.signedInUser, name: data.name, isPrivate: isPrivateChecked },
    }).then(() => {
      refetch();
      onClose();
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose} title={'Create a community'} />
      <DialogContent sx={{ padding: 2, maxWidth: '100%', width: COMPONENT_WIDTHS.BODY }} dividers>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <TextField
            fullWidth
            sx={{ mb: 1, backgroundColor: colours.primaryInverseColour }}
            error={Boolean(errors.name?.message)}
            helperText={errors.name?.message}
            id="name"
            label="Name"
            variant="outlined"
            {...register('name')}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPrivateChecked}
                  id="isPrivate"
                  name="isPrivate"
                  onClick={() => setIsPrivateChecked(!isPrivateChecked)}
                />
              }
              label="Private Community"
            />
          </FormGroup>
          <SubmitButton sx={{ mb: 1 }} loading={createLoading} label={'Create Community'} />
        </form>
        {Boolean(createError) && (
          <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
            Oops! Something went wrong!
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};
