import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Grid, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { CONFIRM_GAME } from '../../api/mutation/confirmGame';
import { GET_GAME } from '../../api/query/getGame';
import { ErrorAlert } from '../../component-library/error-alert';
import { LoadingSpinner } from '../../component-library/loading-spinner';
import { SubmitButton } from '../../component-library/submit-button';
import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { RoutePath } from '../../constants/routes';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useAppCookies } from '../../utils/use-cookies';
import { GameOverviewRound } from '../new-game/GameOverviewRound';
import { GameOverviewTotal } from '../new-game/GameOverviewTotals';

/**
 * This page will be the first thing the user sees when they load up the website.
 */
export const GamePage: React.FC = () => {
  const { id } = useParams();
  const { cookies } = useAppCookies();
  const navigation = useNavigate();

  const { data, loading, error, refetch } = useQuery(GET_GAME, {
    variables: {
      gameId: id,
    },
    fetchPolicy: 'network-only',
  });

  const parsedData = data?.getGame ? JSON.parse(data.getGame.score) : undefined;

  const attackerTotal = () => {
    return (
      parseInt(parsedData.attRound1.attackersTurn || 0) +
      parseInt(parsedData.attRound1.defendersTurn || 0) +
      parseInt(parsedData.attRound1.secondary || 0) +
      parseInt(parsedData.attRound2.attackersTurn || 0) +
      parseInt(parsedData.attRound2.defendersTurn || 0) +
      parseInt(parsedData.attRound2.secondary || 0) +
      parseInt(parsedData.attRound3.attackersTurn || 0) +
      parseInt(parsedData.attRound3.defendersTurn || 0) +
      parseInt(parsedData.attRound3.secondary || 0) +
      parseInt(parsedData.attRound4.attackersTurn || 0) +
      parseInt(parsedData.attRound4.defendersTurn || 0) +
      parseInt(parsedData.attRound4.secondary || 0) +
      parseInt(parsedData.attRound5.attackersTurn || 0) +
      parseInt(parsedData.attRound5.defendersTurn || 0) +
      parseInt(parsedData.attRound5.secondary || 0)
    );
  };

  const defenderTotal = () => {
    return (
      parseInt(parsedData.defRound1.attackersTurn || 0) +
      parseInt(parsedData.defRound1.defendersTurn || 0) +
      parseInt(parsedData.defRound1.secondary || 0) +
      parseInt(parsedData.defRound2.attackersTurn || 0) +
      parseInt(parsedData.defRound2.defendersTurn || 0) +
      parseInt(parsedData.defRound2.secondary || 0) +
      parseInt(parsedData.defRound3.attackersTurn || 0) +
      parseInt(parsedData.defRound3.defendersTurn || 0) +
      parseInt(parsedData.defRound3.secondary || 0) +
      parseInt(parsedData.defRound4.attackersTurn || 0) +
      parseInt(parsedData.defRound4.defendersTurn || 0) +
      parseInt(parsedData.defRound4.secondary || 0) +
      parseInt(parsedData.defRound5.attackersTurn || 0) +
      parseInt(parsedData.defRound5.defendersTurn || 0) +
      parseInt(parsedData.defRound5.secondary || 0)
    );
  };

  const getWinCondition = () => {
    if (data.getGame.attackerPoints === 0 && attackerTotal() > 0) {
      return 'Attacker Conceeded';
    }

    if (data.getGame.defenderPoints === 0 && defenderTotal() > 0) {
      return 'Defender Conceeded';
    }

    return 'Points';
  };

  const [confirmGame, { loading: createLoading, error: createError }] = useMutation(CONFIRM_GAME);

  const handleConfirmGame = async () => {
    await confirmGame({
      variables: {
        userId: cookies.signedInUser,
        gameId: id,
      },
    }).then((data) => {
      refetch();
      if (
        data?.data?.confirmGame?.attackerHandshake &&
        data?.data?.confirmGame?.attackerHandshake
      ) {
        navigation(`${RoutePath.GAMES}?handshake=true`);
      }
    });
  };

  return (
    <Stack
      sx={{
        p: 2,
        pb: 6,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2} sx={{ maxWidth: 600, pt: 2, pb: 3 }}>
        {(!data?.getGame?.attackerHandshake || !data?.getGame?.defenderHandshake) && (
          <Grid item xs={12}>
            <ErrorAlert showError={Boolean(createError?.message)} />
            {(!data?.getGame?.attackerHandshake &&
              data?.getGame?.attacker.id === cookies.signedInUser) ||
            (!data?.getGame?.defenderHandshake &&
              data?.getGame?.defender.id === cookies.signedInUser) ? (
              <SubmitButton
                loading={createLoading || loading}
                variant="contained"
                fullWidth
                onClick={() => handleConfirmGame()}
                label={'Handshake'}
              />
            ) : (
              <Alert security="warning">Waiting on your opponent to handshake this game.</Alert>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
            }}
          >
            {loading && (
              <LoadingSpinner
                sx={{
                  color: 'white',
                  margin: 'auto',
                  justifyContent: 'center',
                  width: '100%',
                  p: 2,
                  pb: 0,
                }}
                label={'Loading game...'}
              />
            )}
            <ErrorAlert showError={Boolean(error?.message)} />
            {data?.getGame && (
              <>
                <GameOverviewTotal
                  winCondition={`Win condition - ${getWinCondition()}`}
                  attackerArmy={data.getGame.attackerArmy}
                  defenderArmy={data.getGame.defenderArmy}
                  attacker={getNameFromUser(data.getGame.attacker) || 'Guest'}
                  defender={getNameFromUser(data.getGame.defender) || 'Guest'}
                  attackerTotal={attackerTotal()}
                  defenderTotal={defenderTotal()}
                  attackerArmyDetails={data?.getGame?.attackerArmyDetail}
                  defenderArmyDetails={data?.getGame?.defenderArmyDetail}
                />
                <GameOverviewRound
                  bgcolor={'#fbf3ff'}
                  round={1}
                  attRound={parsedData.attRound1}
                  defRound={parsedData.defRound1}
                  roundNotes={data?.getGame?.round1Notes ?? ''}
                />
                <GameOverviewRound
                  bgcolor={'#fff'}
                  round={2}
                  attRound={parsedData.attRound2}
                  defRound={parsedData.defRound2}
                  roundNotes={data?.getGame?.round2Notes ?? ''}
                />
                <GameOverviewRound
                  bgcolor={'#fbf3ff'}
                  round={3}
                  attRound={parsedData.attRound3}
                  defRound={parsedData.defRound3}
                  roundNotes={data?.getGame?.round3Notes ?? ''}
                />
                <GameOverviewRound
                  bgcolor={'#fff'}
                  round={4}
                  attRound={parsedData.attRound4}
                  defRound={parsedData.defRound4}
                  roundNotes={data?.getGame?.round4Notes ?? ''}
                />
                <GameOverviewRound
                  bgcolor={'#fbf3ff'}
                  round={5}
                  attRound={parsedData.attRound5}
                  defRound={parsedData.defRound5}
                  roundNotes={data?.getGame?.round5Notes ?? ''}
                />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
