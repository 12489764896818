import React, { useState } from 'react';

import { Alert, Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';

import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { calculateAverageSuccessfulRolls } from '../../utils/probability-of-hitting';

export const DamageProbabilityPage: React.FC = () => {
  const [result, setResult] = useState<{
    averageSuccessfulRolls: number;
    averageSuccessfulDamage: number;
  }>({
    averageSuccessfulDamage: 0,
    averageSuccessfulRolls: 0,
  });

  const [args, setArgs] = useState<{
    A: number;
    WS: number;
    S: number;
    T: number;
    AP: number;
    SV: number;
    Spp: number;
    Sppp: number;
    D: number;
  }>({
    A: 0,
    WS: 0,
    S: 0,
    T: 0,
    AP: 0,
    SV: 0,
    Spp: 0,
    Sppp: 0,
    D: 0,
  });

  return (
    <Stack
      sx={{
        p: 2,
        pb: 6,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        alignItems: 'center',
      }}
    >
      <Box m={2} mt={3}>
        <Grid
          container
          spacing={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 1,
            pb: 2,
            mb: 2,
            borderRadius: 1,
            fontWeight: 600,
            bgcolor: 'rgb(49, 0, 74, 0.8)',
            border: '2px solid white',
            fontSize: 12,
            textAlign: 'center',
          }}
        >
          <Grid item xs={12}>
            <Typography sx={{ color: 'white' }}>Damage probability</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          sx={{
            maxWidth: 600,
            pr: 1,
            borderRadius: 1,
            fontWeight: 600,
            color: 'white',
            bgcolor: 'rgb(255, 255, 255, 0.93)',
            border: '2px solid white',
          }}
        >
          <Grid item xs={4} sm={3}>
            <TextField
              sx={{ mb: 1 }}
              type="number"
              label={'A'}
              onChange={(e) =>
                setArgs({
                  ...args,
                  A: parseInt(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              sx={{ mb: 1 }}
              type="number"
              label={'WS'}
              onChange={(e) =>
                setArgs({
                  ...args,
                  WS: parseInt(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              sx={{ mb: 1 }}
              type="number"
              label={'S'}
              onChange={(e) =>
                setArgs({
                  ...args,
                  S: parseInt(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              sx={{ mb: 1 }}
              type="number"
              label={'T'}
              onChange={(e) =>
                setArgs({
                  ...args,
                  T: parseInt(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              sx={{ mb: 1 }}
              type="number"
              label={'Invulnerable save'}
              onChange={(e) =>
                setArgs({
                  ...args,
                  Spp: parseInt(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              sx={{ mb: 1 }}
              type="number"
              label={'AP'}
              onChange={(e) =>
                setArgs({
                  ...args,
                  AP: parseInt(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              sx={{ mb: 1 }}
              type="number"
              label={'SV'}
              onChange={(e) =>
                setArgs({
                  ...args,
                  SV: parseInt(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              sx={{ mb: 1 }}
              type="number"
              label={'Feel no pain'}
              onChange={(e) =>
                setArgs({
                  ...args,
                  Sppp: parseInt(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              sx={{ mb: 1 }}
              type="number"
              label={'D'}
              onChange={(e) =>
                setArgs({
                  ...args,
                  D: parseInt(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              Invulnerable save and Feel no pain should be set to 0 if they do not exist
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                p: 2,
                borderRadius: 1,
                fontWeight: 600,
                bgcolor: 'rgb(49, 0, 74, 0.8)',
                border: '2px solid white',
                fontSize: 12,
                textAlign: 'center',
              }}
            >
              <Grid
                container
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Grid item xs={4}>
                  Average Successful Attacks
                </Grid>
                <Grid item xs={4}>
                  {' - '}
                </Grid>
                <Grid item xs={4}>
                  {isNaN(result.averageSuccessfulRolls)
                    ? 'N/A'
                    : result.averageSuccessfulRolls.toFixed(2)}
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Grid item xs={4}>
                  Average Damage
                </Grid>
                <Grid item xs={4}>
                  {' - '}
                </Grid>
                <Grid item xs={4}>
                  {isNaN(result.averageSuccessfulDamage)
                    ? 'N/A'
                    : result.averageSuccessfulDamage.toFixed(2)}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              sx={{ mt: 1, mb: 2 }}
              variant="contained"
              onClick={() => setResult(calculateAverageSuccessfulRolls(args))}
            >
              Calculate
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};
