import { gql } from '@apollo/client';

export const CREATE_GAME = gql`
  mutation CreateGame(
    $attackerId: String!
    $defenderId: String!
    $leagueId: String
    $attackerPoints: Int!
    $defenderPoints: Int!
    $attackerAveragePoints: Int!
    $defenderAveragePoints: Int!
    $attackerArmy: String!
    $defenderArmy: String!
    $score: String!
    $attackerArmyDetail: String!
    $defenderArmyDetail: String!
    $round1Notes: String!
    $round2Notes: String!
    $round3Notes: String!
    $round4Notes: String!
    $round5Notes: String!
    $attackerTacticalPoints: Int
    $defenderTacticalPoints: Int
    $attackerFixedPoints: Int
    $defenderFixedPoints: Int
  ) {
    createGame(
      attackerId: $attackerId
      defenderId: $defenderId
      leagueId: $leagueId
      attackerPoints: $attackerPoints
      defenderPoints: $defenderPoints
      attackerAveragePoints: $attackerAveragePoints
      defenderAveragePoints: $defenderAveragePoints
      attackerArmy: $attackerArmy
      defenderArmy: $defenderArmy
      score: $score
      attackerArmyDetail: $attackerArmyDetail
      defenderArmyDetail: $defenderArmyDetail
      round1Notes: $round1Notes
      round2Notes: $round2Notes
      round3Notes: $round3Notes
      round4Notes: $round4Notes
      round5Notes: $round5Notes
      attackerTacticalPoints: $attackerTacticalPoints
      defenderTacticalPoints: $defenderTacticalPoints
      attackerFixedPoints: $attackerFixedPoints
      defenderFixedPoints: $defenderFixedPoints
    ) {
      id
    }
  }
`;
