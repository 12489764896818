import { IGamePlayerInfo } from '../../types/player';
import { GameOverviewRound } from './GameOverviewRound';
import { GameOverviewTotal } from './GameOverviewTotals';
import { GameRound } from './GameRound';
import { IGameRoundScoring } from './index';
import { Stage0 } from './stage0';

export const GameSwtich = ({
  stage,

  guestUser,

  attackerArmyDetail,
  defenderArmyDetail,

  fixedPoints,
  setFixedPoints,
  setTacticalPoints,
  tacticalPoints,

  round1Notes,
  round2Notes,
  round3Notes,
  round4Notes,
  round5Notes,
  setRound1Notes,
  setRound2Notes,
  setRound3Notes,
  setRound4Notes,
  setRound5Notes,

  setAttackerArmyDetail,
  setDefenderArmyDetail,

  attackerTotal,
  defenderTotal,

  // 0
  attacker,
  defender,
  setAttacker,
  setDefender,

  // 1
  attRound1,
  defRound1,
  setAttRound1,
  setDefRound1,

  // 2
  attRound2,
  defRound2,
  setAttRound2,
  setDefRound2,

  // 3
  attRound3,
  defRound3,
  setAttRound3,
  setDefRound3,

  // 4
  attRound4,
  defRound4,
  setAttRound4,
  setDefRound4,

  // 5
  attRound5,
  defRound5,
  setAttRound5,
  setDefRound5,
}: {
  stage: number;

  guestUser: boolean;

  attackerArmyDetail: string;
  defenderArmyDetail: string;
  setAttackerArmyDetail: React.Dispatch<React.SetStateAction<string>>;
  setDefenderArmyDetail: React.Dispatch<React.SetStateAction<string>>;

  tacticalPoints: string;
  setTacticalPoints: React.Dispatch<React.SetStateAction<string>>;
  fixedPoints: string;
  setFixedPoints: React.Dispatch<React.SetStateAction<string>>;

  attackerTotal: number;
  defenderTotal: number;

  round1Notes: string;
  round2Notes: string;
  round3Notes: string;
  round4Notes: string;
  round5Notes: string;

  setRound1Notes: React.Dispatch<React.SetStateAction<string>>;
  setRound2Notes: React.Dispatch<React.SetStateAction<string>>;
  setRound3Notes: React.Dispatch<React.SetStateAction<string>>;
  setRound4Notes: React.Dispatch<React.SetStateAction<string>>;
  setRound5Notes: React.Dispatch<React.SetStateAction<string>>;

  // 0
  attacker: IGamePlayerInfo;
  defender: IGamePlayerInfo;
  setAttacker: React.Dispatch<React.SetStateAction<IGamePlayerInfo>>;
  setDefender: React.Dispatch<React.SetStateAction<IGamePlayerInfo>>;

  // 1
  attRound1: IGameRoundScoring;
  defRound1: IGameRoundScoring;
  setAttRound1: React.Dispatch<React.SetStateAction<IGameRoundScoring>>;
  setDefRound1: React.Dispatch<React.SetStateAction<IGameRoundScoring>>;

  // 2
  attRound2: IGameRoundScoring;
  defRound2: IGameRoundScoring;
  setAttRound2: React.Dispatch<React.SetStateAction<IGameRoundScoring>>;
  setDefRound2: React.Dispatch<React.SetStateAction<IGameRoundScoring>>;

  // 3
  attRound3: IGameRoundScoring;
  defRound3: IGameRoundScoring;
  setAttRound3: React.Dispatch<React.SetStateAction<IGameRoundScoring>>;
  setDefRound3: React.Dispatch<React.SetStateAction<IGameRoundScoring>>;

  // 4
  attRound4: IGameRoundScoring;
  defRound4: IGameRoundScoring;
  setAttRound4: React.Dispatch<React.SetStateAction<IGameRoundScoring>>;
  setDefRound4: React.Dispatch<React.SetStateAction<IGameRoundScoring>>;

  // 5
  attRound5: IGameRoundScoring;
  defRound5: IGameRoundScoring;
  setAttRound5: React.Dispatch<React.SetStateAction<IGameRoundScoring>>;
  setDefRound5: React.Dispatch<React.SetStateAction<IGameRoundScoring>>;
}) => {
  switch (stage) {
    case 0:
      return (
        <Stage0
          guestUser={guestUser}
          attacker={attacker}
          defender={defender}
          setAttacker={setAttacker}
          setDefender={setDefender}
          setAttackerArmyDetail={setAttackerArmyDetail}
          setDefenderArmyDetail={setDefenderArmyDetail}
          attackerArmyDetail={attackerArmyDetail}
          defenderArmyDetail={defenderArmyDetail}
          tacticalPoints={tacticalPoints}
          setTacticalPoints={setTacticalPoints}
          fixedPoints={fixedPoints}
          setFixedPoints={setFixedPoints}
        />
      );

    case 1:
      return (
        <GameRound
          round={1}
          attRound={attRound1}
          defRound={defRound1}
          setAttRound={setAttRound1}
          setDefRound={setDefRound1}
          attackerTotal={attackerTotal}
          defenderTotal={defenderTotal}
          roundNotes={round1Notes}
          setRoundNotes={setRound1Notes}
        />
      );

    case 2:
      return (
        <GameRound
          round={2}
          attRound={attRound2}
          defRound={defRound2}
          setAttRound={setAttRound2}
          setDefRound={setDefRound2}
          attackerTotal={attackerTotal}
          defenderTotal={defenderTotal}
          roundNotes={round2Notes}
          setRoundNotes={setRound2Notes}
        />
      );

    case 3:
      return (
        <GameRound
          round={3}
          attRound={attRound3}
          defRound={defRound3}
          setAttRound={setAttRound3}
          setDefRound={setDefRound3}
          attackerTotal={attackerTotal}
          defenderTotal={defenderTotal}
          roundNotes={round3Notes}
          setRoundNotes={setRound3Notes}
        />
      );

    case 4:
      return (
        <GameRound
          round={4}
          attRound={attRound4}
          defRound={defRound4}
          setAttRound={setAttRound4}
          setDefRound={setDefRound4}
          attackerTotal={attackerTotal}
          defenderTotal={defenderTotal}
          roundNotes={round4Notes}
          setRoundNotes={setRound4Notes}
        />
      );

    case 5:
      return (
        <GameRound
          round={5}
          attRound={attRound5}
          defRound={defRound5}
          setAttRound={setAttRound5}
          setDefRound={setDefRound5}
          attackerTotal={attackerTotal}
          defenderTotal={defenderTotal}
          roundNotes={round5Notes}
          setRoundNotes={setRound5Notes}
        />
      );

    case 6:
      return (
        <>
          <GameOverviewTotal
            attacker={attacker.name}
            defender={defender.name}
            attackerArmy={attacker.army}
            defenderArmy={defender.army}
            attackerTotal={attackerTotal}
            defenderTotal={defenderTotal}
            attackerArmyDetails={attackerArmyDetail}
            defenderArmyDetails={defenderArmyDetail}
          />
          <GameOverviewRound
            bgcolor={'#fbf3ff'}
            round={1}
            attRound={attRound1}
            defRound={defRound1}
            roundNotes={round1Notes}
          />
          <GameOverviewRound
            bgcolor={'#fff'}
            round={2}
            attRound={attRound2}
            defRound={defRound2}
            roundNotes={round2Notes}
          />
          <GameOverviewRound
            bgcolor={'#fbf3ff'}
            round={3}
            attRound={attRound3}
            defRound={defRound3}
            roundNotes={round3Notes}
          />
          <GameOverviewRound
            bgcolor={'#fff'}
            round={4}
            attRound={attRound4}
            defRound={defRound4}
            roundNotes={round4Notes}
          />
          <GameOverviewRound
            bgcolor={'#fbf3ff'}
            round={5}
            attRound={attRound5}
            defRound={defRound5}
            roundNotes={round5Notes}
          />
        </>
      );

    default:
      return null;
  }
};
