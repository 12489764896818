import { gql } from '@apollo/client';

export const GET_USER_RANKINGS = gql`
  query GetUserRankings($limit: Int!, $offset: Int!) {
    getUserRankings(limit: $limit, offset: $offset) {
      data {
        id
        firstName
        lastName
        email
        globalRanking
        winStreak
        maxWinStreak
        username
        date
      }
      count
    }
  }
`;
