import React from 'react';

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { colours } from '../../constants/colours';
import { useAppCookies } from '../../utils/use-cookies';
import { LogoutNavItem } from '../logout-nav-item';
import { RouterLink } from '../router-link';
import { AccountLinks, NotSignedInLinks } from './constants';

export interface SideNavigationProps {
  isNavMenuOpen: boolean;
  closeNavMenu: () => void;
}

/**
 * The side navigation helps the user navigate through the website.
 * There should be a link to each page in the site that every user can visit.
 * There should also be a section for the logged in users additional pages.
 */
export const SideNavigation: React.FC<SideNavigationProps> = ({ isNavMenuOpen, closeNavMenu }) => {
  const { cookies } = useAppCookies();

  return (
    <Drawer
      anchor={'left'}
      open={isNavMenuOpen}
      onClose={closeNavMenu}
      SlideProps={{ style: { backgroundColor: colours.primaryThemeColour } }}
    >
      <Box sx={{ width: 250 }} role="presentation" onClick={closeNavMenu} onKeyDown={closeNavMenu}>
        <List>
          {(cookies.signedInUser ? AccountLinks(cookies.signedInUser) : NotSignedInLinks).map(
            ({ href, label, icon }) => {
              return (
                <RouterLink key={href} href={href}>
                  <ListItem key={label} disablePadding>
                    <ListItemButton>
                      <ListItemIcon sx={{ color: colours.primaryInverseColour }}>
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={label} sx={{ color: colours.primaryInverseColour }} />
                    </ListItemButton>
                  </ListItem>
                </RouterLink>
              );
            },
          )}
          {cookies.signedInUser && <LogoutNavItem />}
        </List>
      </Box>
    </Drawer>
  );
};
