import { gql } from '@apollo/client';

export const GET_COMPARISON = gql`
  query GetComparison(
    $user1Id: String!
    $user1Army: String!
    $user2Id: String!
    $user2Army: String!
  ) {
    getComparison(
      user1Id: $user1Id
      user1Army: $user1Army
      user2Id: $user2Id
      user2Army: $user2Army
    ) {
      user1 {
        firstName
        lastName
        globalRanking
        username
        winStreak
        maxWinStreak
      }
      user2 {
        firstName
        lastName
        globalRanking
        username
        winStreak
        maxWinStreak
      }
      games {
        user1 {
          totalGames
          totalWins
          totalLosses
          totalDraws
        }
        user2 {
          totalGames
          totalWins
          totalLosses
          totalDraws
        }
      }
      vp {
        user1 {
          averageVP
          averagePrimary
          averagetTactical
          averagetFixed
        }
        user2 {
          averageVP
          averagePrimary
          averagetTactical
          averagetFixed
        }
      }
    }
  }
`;
