import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';

import { colours } from '../../constants/colours';

export const GameOverviewTotal = ({
  winCondition,
  attacker,
  defender,
  attackerArmy,
  defenderArmy,
  attackerTotal,
  defenderTotal,
  attackerArmyDetails,
  defenderArmyDetails,
}: {
  winCondition?: string;
  attacker: string;
  defender: string;
  attackerArmy: string;
  defenderArmy: string;
  attackerTotal: number;
  defenderTotal: number;
  attackerArmyDetails: string;
  defenderArmyDetails: string;
}) => {
  return (
    <>
      {winCondition && (
        <Grid
          container
          sx={{
            bgcolor: colours.primaryThemeColour,
            opacity: 0.9,
            borderRadius: 2,
            p: 2,
            mb: 2,
            color: 'white',
            border: '2px solid white',
          }}
        >
          <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6">{winCondition}</Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        sx={{
          bgcolor: colours.primaryThemeColour,
          opacity: 0.9,
          borderRadius: 2,
          p: 2,
          color: 'white',
          border: '2px solid white',
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4} sm={2} p={1}></Grid>
        <Grid
          item
          xs={4}
          sm={5}
          p={1}
          sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {attacker || 'Guest'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sm={5}
          p={1}
          sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {defender || 'Guest'}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={2} p={1}></Grid>
        <Grid
          item
          xs={4}
          sm={5}
          p={1}
          sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
        >
          <Typography variant="body2">{attackerArmy}</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sm={5}
          p={1}
          sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
        >
          <Typography variant="body2">{defenderArmy}</Typography>
        </Grid>
      </Grid>

      <Grid
        container
        p={2}
        sx={{
          bgcolor: 'rgb(255,255,255, 0.9)',
          mt: 2,
          borderRadius: 2,
        }}
      >
        <Grid
          item
          xs={4}
          sm={2}
          p={1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6">Total</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sm={5}
          p={1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontWeight: attackerTotal > defenderTotal ? 'bold' : 'normal',
              color: attackerTotal > defenderTotal ? colours.primaryThemeColour : 'inherit',
            }}
          >
            {attackerTotal}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sm={5}
          p={1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontWeight: defenderTotal > attackerTotal ? 'bold' : 'normal',
              color: defenderTotal > attackerTotal ? colours.primaryThemeColour : 'inherit',
            }}
          >
            {defenderTotal}
          </Typography>
        </Grid>
      </Grid>
      <Accordion sx={{ mt: 2, borderRadius: 3, bgcolor: 'rgb(255,255,255, 0.9)' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ fontSize: 14 }}
        >
          Attacker army details
        </AccordionSummary>
        <AccordionDetails sx={{ fontSize: 14, whiteSpace: 'pre-line' }}>
          {attackerArmyDetails || 'N/A'}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mt: 2, borderRadius: 3, bgcolor: 'rgb(255,255,255, 0.9)' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{ fontSize: 14 }}
        >
          Defender army details
        </AccordionSummary>
        <AccordionDetails sx={{ fontSize: 14, whiteSpace: 'pre-line' }}>
          {defenderArmyDetails || 'N/A'}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
