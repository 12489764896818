import { Box, Divider, Grid, Typography } from '@mui/material';

import { IGameRoundScoring } from '.';

export const GameOverviewRound = ({
  round,
  attRound,
  defRound,
  bgcolor,
  roundNotes,
}: {
  round: number;
  roundNotes: string;
  attRound: IGameRoundScoring;
  defRound: IGameRoundScoring;
  bgcolor: string;
}) => {
  return (
    <Grid container sx={{ bgcolor, opacity: 0.9, borderRadius: 2, mt: 2, p: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h6">Round {round}</Typography>
      </Grid>
      <Grid item xs={4} sm={2} p={1}></Grid>
      <Grid
        item
        xs={4}
        sm={5}
        p={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        Attacker
      </Grid>
      <Grid
        item
        xs={4}
        sm={5}
        p={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        Defender
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item xs={4} sm={2} p={1}>
        <Typography variant="caption">Attackers round</Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={5}
        p={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {attRound.attackersTurn || 0}
      </Grid>
      <Grid
        item
        xs={4}
        sm={5}
        p={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {defRound.attackersTurn || 0}
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item xs={4} sm={2} p={1}>
        <Typography variant="caption">Defenders round</Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={5}
        p={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {attRound.defendersTurn || 0}
      </Grid>
      <Grid
        item
        xs={4}
        sm={5}
        p={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {defRound.defendersTurn || 0}
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item xs={4} sm={2} p={1} sx={{ pt: 2, pb: 2 }}>
        <Typography variant="caption">Secondaries</Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={5}
        p={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {attRound.secondary || 0}
      </Grid>
      <Grid
        item
        xs={4}
        sm={5}
        p={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {defRound.secondary || 0}
      </Grid>

      <Grid item xs={12}>
        <Divider />
        <Box sx={{ borderRadius: 3, p: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1, mt: 1 }}>
            Notes
          </Typography>
          <Typography variant="caption" sx={{ whiteSpace: 'pre-line' }}>
            {roundNotes || 'N/A'}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
