import { Button, CircularProgress } from '@mui/material';

interface ISubmitButtonProps {
  label: JSX.Element | string;
  loading?: boolean;
  disabled?: boolean;
  type?: 'submit' | 'button';
  variant?: 'text' | 'contained' | 'outlined';
  sx?: any;
  fullWidth?: boolean;
  colour?: 'primary' | 'inherit' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}
/**
 * Use this on every submit button.
 * It shows a spinning icon when loading is set to true.
 * This allows the user to see something is happening.
 */
export const SubmitButton: React.FC<ISubmitButtonProps> = ({
  loading,
  label,
  disabled,
  type = 'submit',
  variant = 'contained',
  onClick,
  sx,
  fullWidth = true,
  colour = 'primary',
}) => {
  return (
    <Button
      type={type}
      color={colour}
      variant={variant}
      fullWidth={fullWidth}
      disabled={loading || disabled}
      sx={{ ...sx, height: 36.5 }}
      onClick={onClick}
      data-testid="submit-button"
    >
      {loading ? (
        <CircularProgress size={20} thickness={5} data-testid="submit-button-loading-splinner" />
      ) : (
        label
      )}
    </Button>
  );
};
