import { IGamePlayerInfo } from '../../../types/player';
import { IGameRoundScoring } from '../guest-game';

export interface ILocalGameFile {
  leagueId: string;
  attacker: IGamePlayerInfo;
  defender: IGamePlayerInfo;
  attRound1: IGameRoundScoring;
  attRound2: IGameRoundScoring;
  attRound3: IGameRoundScoring;
  attRound4: IGameRoundScoring;
  attRound5: IGameRoundScoring;
  defRound1: IGameRoundScoring;
  defRound2: IGameRoundScoring;
  defRound3: IGameRoundScoring;
  defRound4: IGameRoundScoring;
  defRound5: IGameRoundScoring;
  attackerArmyDetail: string;
  defenderArmyDetail: string;
  round1Notes: string;
  round2Notes: string;
  round3Notes: string;
  round4Notes: string;
  round5Notes: string;
  primaryPoints: string;
  tacticalPoints: string;
  fixedPoints: string;
}

export const validateGameFile = (data: any): data is ILocalGameFile => {
  if (
    'leagueId' in data &&
    'attacker' in data &&
    'defender' in data &&
    'attRound1' in data &&
    'attRound2' in data &&
    'attRound3' in data &&
    'attRound4' in data &&
    'attRound5' in data &&
    'defRound1' in data &&
    'defRound2' in data &&
    'defRound3' in data &&
    'defRound4' in data &&
    'defRound5' in data &&
    'attackerArmyDetail' in data &&
    'defenderArmyDetail' in data &&
    'round1Notes' in data &&
    'round2Notes' in data &&
    'round3Notes' in data &&
    'round4Notes' in data &&
    'round5Notes' in data &&
    'primaryPoints' in data &&
    'tacticalPoints' in data &&
    'fixedPoints' in data
  ) {
    return true;
  } else {
    return false;
  }
};
