import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { UPDATE_USER } from '../../api/mutation/updateUser';
import { ErrorAlert } from '../../component-library/error-alert';
import { SubmitButton } from '../../component-library/submit-button';
import { armies } from '../../constants/armies';
import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { useAppCookies } from '../../utils/use-cookies';
import {
  FavArmyUpdateUserFormSchema,
  NameUpdateUserFormSchema,
  PasswordUpdateUserFormSchema,
} from '../../validation/schemas/update-user-form';
import { UpdateUserFormInputs } from '../../validation/schemas/update-user-form/types';

export const SettingsPage: React.FC = () => {
  const { cookies } = useAppCookies();

  const [toastOpen, setToastOpen] = React.useState(false);

  const [passwordShown, setPasswordShown] = useState(false);

  const [updateUser, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_USER);

  const {
    register: passwordRegister,
    handleSubmit: passwordHandleSubmit,
    formState: { errors: passwordErrors },
  } = useForm<UpdateUserFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(PasswordUpdateUserFormSchema),
  });

  const {
    register: nameRegister,
    handleSubmit: nameHandleSubmit,
    formState: { errors: nameErrors },
  } = useForm<UpdateUserFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(NameUpdateUserFormSchema),
  });

  const {
    register: favArmyRegister,
    handleSubmit: favArmyHandleSubmit,
    formState: { errors: favArmyErrors },
  } = useForm<UpdateUserFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(FavArmyUpdateUserFormSchema),
  });

  const onSubmit: SubmitHandler<UpdateUserFormInputs> = (data) => {
    if (
      !data.currentPassword &&
      !data.newPassword &&
      !data.newFavouriteArmy &&
      !data.newFirstName &&
      !data.newLastName
    ) {
      return;
    }
    updateUser({
      variables: {
        id: cookies.signedInUser,
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
        newFirstName: data.newFirstName,
        newLastName: data.newLastName,
        newFavouriteArmy: data.newFavouriteArmy,
      },
    }).then(() => {
      setToastOpen(true);
    });
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastOpen(false);
  };

  return (
    <Stack
      sx={{
        p: 2,
        pb: 6,
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2} sx={{ maxWidth: 600, pb: 3 }}>
        <Grid item xs={12}>
          <ErrorAlert
            sx={{ mt: 2 }}
            showError={Boolean(updateError?.message)}
            message={
              updateError?.message === 'The password you entered is incorrect'
                ? updateError.message
                : undefined
            }
          />
          <Box sx={{ p: 2, mt: 2, bgcolor: 'rgb(255,255,255, 0.8)' }}>
            <form onSubmit={passwordHandleSubmit(onSubmit)}>
              <TextField
                fullWidth
                type={passwordShown ? 'text' : 'password'}
                sx={{ mb: 1 }}
                error={Boolean(passwordErrors.currentPassword?.message)}
                helperText={passwordErrors.currentPassword?.message}
                id="currentPassword"
                label="Current password"
                variant="outlined"
                {...passwordRegister('currentPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <VisibilityIcon
                        onClick={() => setPasswordShown(!passwordShown)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                type={passwordShown ? 'text' : 'password'}
                sx={{ mb: 1 }}
                error={Boolean(passwordErrors.newPassword?.message)}
                helperText={passwordErrors.newPassword?.message}
                id="newPassword"
                label="Current password"
                variant="outlined"
                {...passwordRegister('newPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <VisibilityIcon
                        onClick={() => setPasswordShown(!passwordShown)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <SubmitButton loading={updateLoading} label={'Update'} />
            </form>
          </Box>
          <Box sx={{ p: 2, mt: 2, bgcolor: 'rgb(255,255,255, 0.8)' }}>
            <form onSubmit={nameHandleSubmit(onSubmit)}>
              <TextField
                fullWidth
                sx={{ mb: 1 }}
                error={Boolean(nameErrors.newFirstName?.message)}
                helperText={nameErrors.newFirstName?.message}
                id="newFirstName"
                label="First Name"
                variant="outlined"
                {...nameRegister('newFirstName')}
              />
              <TextField
                fullWidth
                sx={{ mb: 1 }}
                error={Boolean(nameErrors.newLastName?.message)}
                helperText={nameErrors.newLastName?.message}
                id="newLastName"
                label="Last Name"
                variant="outlined"
                {...nameRegister('newLastName')}
              />
              <SubmitButton loading={updateLoading} label={'Update'} />
            </form>
          </Box>

          <Box sx={{ p: 2, mt: 2, bgcolor: 'rgb(255,255,255, 0.8)' }}>
            <form onSubmit={favArmyHandleSubmit(onSubmit)}>
              <TextField
                select
                fullWidth
                sx={{ mb: 1 }}
                id="favourite-army"
                label="Favourite Army"
                error={Boolean(favArmyErrors.newFavouriteArmy?.message)}
                helperText={favArmyErrors.newFavouriteArmy?.message}
                {...favArmyRegister('newFavouriteArmy')}
              >
                {armies.map((army) => (
                  <MenuItem key={army} value={army}>
                    {army}
                  </MenuItem>
                ))}
              </TextField>
              <SubmitButton loading={updateLoading} label={'Update'} />
            </form>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="User updated successfully!"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Stack>
  );
};
