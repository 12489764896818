import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import DoneIcon from '@mui/icons-material/Done';
import LoginIcon from '@mui/icons-material/Login';
import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { JOIN_COMMUNITY } from '../../api/mutation/joinCommunity';
import { SEARCH_COMMUNITIES } from '../../api/query/searchCommunities';
import { IAPICommunity } from '../../api/types/community';
import { colours } from '../../constants/colours';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useAppCookies } from '../../utils/use-cookies';
import { SearchCommunitiesFormSchema } from '../../validation/schemas/search-communities-form';
import { SearchCommunitiesFormInputs } from '../../validation/schemas/search-communities-form/types';
import { ErrorAlert } from '../error-alert';
import { ModalHeader } from '../modal-header';
import { SubmitButton } from '../submit-button';

interface IAddFriendModal {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

export const JoinCommunityModal: React.FC<IAddFriendModal> = ({ isOpen, onClose, refetch }) => {
  const { cookies } = useAppCookies();

  const [showForm, setShowForm] = useState<boolean>(true);
  const [sentRequests, setSentRequests] = useState<string[]>([]);

  const [joinCommunity, { loading: joinLoading, error: joinError, reset }] =
    useMutation(JOIN_COMMUNITY);

  const [getSearch, { loading, error, data }] = useLazyQuery(SEARCH_COMMUNITIES, {
    variables: { userId: cookies.signedInUser, name: '' },
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SearchCommunitiesFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(SearchCommunitiesFormSchema),
  });

  // Reset form values on toggle
  useEffect(() => {
    setValue('name', '');
  }, [setValue, showForm]);

  const handleJoinCommunity = (id: string) => {
    joinCommunity({
      variables: { userId: cookies.signedInUser, communityId: id },
    }).then(() => {
      refetch();
      setSentRequests([...sentRequests, id]);
      handleClose();
    });
  };

  const onSubmit: SubmitHandler<SearchCommunitiesFormInputs> = (data) => {
    if (Boolean(!data.name)) {
      return;
    }

    getSearch({
      variables: {
        name: data.name ?? '',
      },
    }).then(() => {
      reset();
      setShowForm(false);
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <ModalHeader onClose={handleClose} title={'Find a community'} />
      <DialogContent sx={{ padding: 2, maxWidth: '100%', width: COMPONENT_WIDTHS.BODY }} dividers>
        {Boolean(!showForm) && (
          <Button
            fullWidth
            sx={{ mb: 2 }}
            endIcon={<SearchIcon />}
            variant="contained"
            onClick={() => setShowForm(true)}
          >
            Change search
          </Button>
        )}
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          {showForm && (
            <>
              <Typography sx={cardSubtitleFontStyles()}>
                Search for a community by it's name.
              </Typography>
              <TextField
                fullWidth
                sx={{ mb: 1, backgroundColor: colours.primaryInverseColour }}
                error={Boolean(errors.name?.message)}
                helperText={errors.name?.message}
                id="name"
                label="Name"
                variant="outlined"
                {...register('name')}
              />
              <SubmitButton sx={{ mb: 1 }} loading={loading} label={'Search'} />
            </>
          )}
        </form>
        {Boolean(!showForm && data?.searchCommunities?.length === 0) && (
          <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
            No communities match the search
          </Alert>
        )}
        {!showForm && (
          <ErrorAlert
            showError={Boolean(error?.message || joinError?.message)}
            message={
              joinError?.message === `You're already in that community`
                ? joinError.message
                : undefined
            }
          />
        )}
        {!showForm &&
          data?.searchCommunities?.map((community: IAPICommunity) => {
            const requestSent = Boolean(sentRequests.find((f) => f === community.id));
            return (
              <React.Fragment key={community.id}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, pt: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ overflow: 'hidden' }}>
                      <Typography sx={cardTitleFontStyles}>{`${community.name}`}</Typography>
                      <Typography sx={cardSubtitleFontStyles()}>
                        Owner - {getNameFromUser(community.owner)}
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton
                    disabled={joinLoading || requestSent}
                    onClick={() => handleJoinCommunity(community.id)}
                  >
                    {requestSent ? (
                      <DoneIcon />
                    ) : joinLoading ? (
                      <CircularProgress size={20} thickness={3} />
                    ) : (
                      <LoginIcon />
                    )}
                  </IconButton>
                </Box>
                <Divider />
              </React.Fragment>
            );
          })}
      </DialogContent>
    </Dialog>
  );
};
