import { gql } from '@apollo/client';

export const SEARCH_LEAGUES = gql`
  query SearchLeagues($userId: String!, $name: String!) {
    searchLeagues(userId: $userId, name: $name) {
      id
      date
      name
      owner {
        id
        firstName
        lastName
        username
      }
      private
    }
  }
`;
