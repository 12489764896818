import React from 'react';

import { FriendsTab } from '../../pages/friends';
import { FriendsList } from '../friends-list';
import { ManageFriendRequests } from '../manage-friend-requests';

// import { SuggestedFriends } from '../suggested-friends';

interface friendsContent {
  tab: FriendsTab;
}

export const ManageFriendContent: React.FC<friendsContent> = ({ tab }) => {
  if (tab === FriendsTab.friends) {
    return <FriendsList />;
  }
  // if (tab === FriendsTab.suggested) {
  //   return <SuggestedFriends />;
  // }

  return <ManageFriendRequests />;
};
