import React, { useState } from 'react';

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Box, Button, Card, Grid, Stack, Tab, Tabs } from '@mui/material';

import { AddFriendModal } from '../../component-library/add-friend-modal';
import { ManageFriendContent } from '../../component-library/manage-friend-content';
import { colours } from '../../constants/colours';
import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';

export enum FriendsTab {
  friends = 'friends',
  suggested = 'suggested',
  requests = 'requests',
}

export const FriendsPage: React.FC = () => {
  const [tab, setTab] = useState<FriendsTab>(FriendsTab.friends);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, value: FriendsTab) =>
    setTab(value);

  return (
    <Stack
      sx={{
        p: 2,
        display: 'flex',
        alignItems: 'center',
        paddingTop: `calc(${COMPONENT_HEIGHTS.HEADER} + 1px)`,
      }}
    >
      <Grid container spacing={2} sx={{ maxWidth: 600, pb: 4 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 2,
              borderRadius: 1,
              overflow: 'auto',
            }}
          >
            <AddFriendModal onClose={() => setIsOpen(false)} isOpen={isOpen} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button
                fullWidth
                size="small"
                sx={{ mb: 2, minWidth: 0 }}
                variant="contained"
                endIcon={<GroupAddIcon />}
                onClick={() => setIsOpen(true)}
              >
                Add friend
              </Button>
            </Box>
            <Card
              sx={{
                width: '100%',
                borderBottom: 1,
                borderColor: 'divider',
                marginBottom: 1,
                bgcolor: colours.lightBackground,
              }}
            >
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="Climbing type tabs"
                variant="fullWidth"
                sx={{
                  bgcolor: colours.lightBackground,
                }}
              >
                <Tab sx={{ p: 1 }} value={FriendsTab.friends} label="Friends" />
                {/* <Tab sx={{ p: 1 }} value={FriendsTab.suggested} label="Suggested" /> */}
                <Tab sx={{ p: 1 }} value={FriendsTab.requests} label="Requests" />
              </Tabs>
              <ManageFriendContent tab={tab} />
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
